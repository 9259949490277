import React from "react";
import "./styles.scss";
import { CA_STATES, US_STATES } from "../../lib/constants";
import ProposalProSwitch from "../ProposalProSwitch";

function TitleBar({ proposalInputs, setInfoAssistOn, infoAssistOn }) {
  return (
    <div className="titleBar flex justify-between">
      <div className="mt-4 flex items-center">
        <table className="title inline-block">
          <tbody>
            <tr>
              <td>{proposalInputs.projectName}</td>
              <td>
                {(proposalInputs.countryCode === "CA"
                  ? CA_STATES
                  : US_STATES
                ).find(state => proposalInputs.state === state.value)?.label +
                  ", " +
                  proposalInputs.countryCode}
              </td>
              {proposalInputs.customerCompanyName && (
                <td>{proposalInputs.customerCompanyName}</td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <ProposalProSwitch
        className={"shouldNotBlur"}
        setInfoAssistOn={setInfoAssistOn}
        infoAssistOn={infoAssistOn}
      />
    </div>
  );
}

export default TitleBar;

import React from "react";
import PropTypes from "prop-types";
import DownIcon from "assets/down.svg";
import UpIcon from "assets/up.svg";
import "./styles.scss";
import { Button } from "@enpowered/ui";

export const ExpandableTable = ({
  className,
  children,
  rowsLimit,
  isExpanded = false,
  toggleExpand,
  headers = []
}) => {
  return (
    <div className={"expandableTable w-full " + className}>
      <table className={"w-full " + className}>
        {headers?.length && (
          <thead>
            <tr>
              {headers.map((header, i) => (
                <th key={"header" + i}>{header}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {children
            ?.flat(1)
            ?.map((child, i) => (isExpanded || i < rowsLimit) && child)}
        </tbody>
      </table>
      {children.flat(1).length > rowsLimit &&
        (!isExpanded ? (
          <Button
            type="button"
            onClick={toggleExpand}
            className="loadMore m-0 m-auto"
          >
            <div className="text-center">
              <div className="text-center">Show more rows</div>
              <img src={DownIcon} />
            </div>
          </Button>
        ) : (
          <Button
            type="button"
            onClick={toggleExpand}
            className="loadMore m-0 m-auto"
          >
            <div className="text-center">
              <div className="text-center">Show less</div>
              <img src={UpIcon} />
            </div>
          </Button>
        ))}
    </div>
  );
};

ExpandableTable.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  rowsLimit: PropTypes.number,
  isExpanded: PropTypes.bool,
  toggleExpand: PropTypes.func,
  header: PropTypes.array
};

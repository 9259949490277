import { Label } from "@enpowered/ui";
import React, { useState, useEffect, useRef } from "react";
import NumberInput from "common/NumberInput";
import InfoTooltipLabel from "common/InfoTooltip";
import AdvancedValuesSection from "../AdvancedValuesSection";
import "./styles.scss";

import RadioGroup from "common/RadioGroup";
import mixpanel from "mixpanel-browser";
import IncentivesField from "../../../components/IncentivesField";
import IncentivesModal from "../../../components/IncentivesModal";

const BasicInfoStep = ({
  formProps,
  hasError,
  errors,
  validationOnChanged,
  infoAssistOn,
  fieldFocused,
  setFieldFocused,
  updateProposal,
  calculateProposal,
  proposalInputsForCalculator
}) => {
  const { handleBlur, values: proposal, setFieldValue } = formProps;
  const projectCostRef = useRef();
  useEffect(() => {
    projectCostRef.current.focus();
    infoAssistOn && setFieldFocused("projectCost");
  }, []);
  const fieldProps = fieldName => ({
    name: fieldName,
    id: fieldName,
    value: proposal[fieldName],
    onChange: e => {
      setFieldValue(fieldName, e.target.value);
      validationOnChanged(fieldName, e.target.value);
    },
    onBlur: e => infoAssistOn && setFieldFocused("") && handleBlur(e),
    onFocus: () => infoAssistOn && setFieldFocused(fieldName),
    valid: !hasError(errors, fieldName),
    disabled: proposal.isArchived
  });
  const [isIncentivesModalOpen, setIsIncentivesModalOpen] = useState(false);

  return (
    <div className="step basicInfoStep">
      <IncentivesModal
        isOpen={isIncentivesModalOpen}
        setIsOpen={setIsIncentivesModalOpen}
        disabled={proposal.isArchived}
        formProps={formProps}
        updateProposal={updateProposal}
        calculateProposal={calculateProposal}
        proposalInputsForCalculator={proposalInputsForCalculator}
      />
      <div className="flex mb-1">
        <div className="numberField">
          <InfoTooltipLabel
            tooltipMessage="Cost of project before any incentives"
            infoAssistOn={infoAssistOn}
            focused={fieldFocused === "projectCost"}
          >
            Project Cost
          </InfoTooltipLabel>
          <NumberInput
            inputRef={projectCostRef}
            {...fieldProps("projectCost")}
          />
        </div>
        <div className="numberField savingsField">
          <Label>Annual Savings</Label>
          <NumberInput {...fieldProps("costSavings")} />
        </div>
        <div className="radioField">
          <Label> Match Payments to Savings</Label>
          <RadioGroup
            className="mt-2 pr-2"
            name="useEscalatingPayments"
            id="useEscalatingPayments"
            values={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            currentValue={proposal.useEscalatingPayments ? "true" : "false"}
            direction="horizontal"
            disabled={proposal.isArchived}
            size="small"
            onChange={selected => {
              setFieldValue(
                "useEscalatingPayments",
                selected.target.value == "true"
              );
              infoAssistOn &&
                (selected.target.value == "true"
                  ? setFieldFocused("percentageOfSavings")
                  : setFieldFocused(""));
            }}
            onBlur={e => infoAssistOn && setFieldFocused("") && handleBlur(e)}
          />
        </div>
        {proposal.useEscalatingPayments ? (
          <div className="sliderField">
            <InfoTooltipLabel
              tooltipMessage="How much of the savings should be allocated to the payment?"
              infoAssistOn={infoAssistOn}
              focused={fieldFocused === "percentageOfSavings"}
            >
              Savings to Payments
            </InfoTooltipLabel>
            <NumberInput
              size="sm"
              {...{
                ...fieldProps("percentageOfSavings"),
                prefix: "",
                suffix: "%"
              }}
              max={100}
              decimalPlaces={0}
            ></NumberInput>
          </div>
        ) : (
          <div className="numberField termLengthField">
            <InfoTooltipLabel
              tooltipMessage={
                "Enter the number of years to repay the financed project"
              }
              infoAssistOn={infoAssistOn}
              focused={fieldFocused === "termLength"}
            >
              Term Length
            </InfoTooltipLabel>
            <NumberInput
              {...{
                ...fieldProps("termLength"),
                prefix: "",
                suffix: "years"
              }}
              size="sm"
              max={30}
              decimalPlaces={0}
            ></NumberInput>
          </div>
        )}
        <AdvancedValuesSection />
        <div className="numberField">
          <InfoTooltipLabel
            infoAssistOn={infoAssistOn}
            focused={fieldFocused === "interestRate"}
          >
            Interest Rate
          </InfoTooltipLabel>
          <NumberInput
            size="sm"
            {...{
              ...fieldProps("interestRate"),
              prefix: "",
              suffix: "%"
            }}
            max={100}
            decimalPlaces={2}
            onBlur={e => {
              mixpanel.track("Interest Rate Changed", {
                "Project ID": proposal.financingScenarioId,
                "Interest Rate": e.target.value ? +e.target.value : 0,
                "Organization Type": proposal.organizationType ?? "Undefined"
              });
              infoAssistOn && setFieldFocused("") && handleBlur(e);
            }}
          />
        </div>
        <div className="numberField">
          <Label>Down Payment</Label>
          <NumberInput
            {...{
              ...fieldProps("downPayment")
            }}
            decimalPlaces={2}
            onBlur={e => {
              mixpanel.track("Down Payment Updated", {
                "Project ID": proposal.financingScenarioId,
                partnerCompanyId: proposal.partnerCompanyId,
                partnerCompanyName: proposal.partnerCompanyName,
                "Down Payment": e.target.value ? +e.target.value : 0
              });
              infoAssistOn && setFieldFocused("") && handleBlur(e);
            }}
          />
        </div>
      </div>
      <div className="mt-2">
        <Label>Project Incentives</Label>
        <IncentivesField
          incentives={proposal.incentives}
          onClick={() => !proposal.isArchived && setIsIncentivesModalOpen(true)}
          disabled={proposal.isArchived}
        />
      </div>
    </div>
  );
};

export default BasicInfoStep;

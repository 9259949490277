import PropTypes from "prop-types";
import { createConsoleLog } from "../utils/console";
import {
  calculateAnnualOtherSavings,
  calculateAnnualPayments,
  calculateAnnualSavings,
  calculateOpportunity,
  calculateUtilityCosts,
  calculateTermYears,
  calculateMonthlySchedule,
  getAssetLifetime,
  calculateCostsAndOtherCostsAndSavings
} from "../utils/calculations";
import { calculateInvestmentOptionsValues } from "../InvestmentOptionsChart";
import { calculateROIOverAssetLifetime } from "../utils/metricsCalculations";
const console_log = createConsoleLog("Calculator", { debug: false });
const thresholdTermYears = 30;

export const termSolver = values => {
  let term =
    !values.useEscalatingPayments && values.termLength && values.termLength > 0
      ? { payable: true, years: values.termLength }
      : calculateTermYears(values);

  console_log("term", term);
  return term;
};

export const transformProgressPayments = progressPayments =>
  progressPayments &&
  Object.entries(progressPayments).reduce(
    (ac, [month, percentage]) =>
      month === "final"
        ? ac
        : [
            ...ac,
            {
              month: parseInt(month),
              percentage
            }
          ],
    []
  );

export const Calculator = values => {
  console_log("variables", values);
  const {
    costSavings,
    otherCostSavings,
    incentivesYear,
    projectType,
    utilityCostEscalator,
    downPayment = 0,
    OEMCost = 0,
    interestRate = 8.5 / 100,
    useEscalatingPayments,
    percentageOfSavings,
    systemDegradationRate = 0,
    applyTaxes = true,
    currentUtilityCost
  } = values;

  const term = termSolver(values);
  if (!term?.payable)
    throw new Error("Term cannot be paid with the given values");
  const termMonths = term.years * 12 + (term.months ?? 0);
  const termYears =
    term?.months && term?.months > 0 ? term?.years + 1 : term.years;
  const {
    depreciation,
    costsWithAdditionalCostsAndSavings,
    costsForCashOption,
    constructionPeriodInterest,
    totalDepreciationSavings
  } = calculateCostsAndOtherCostsAndSavings({
    ...values,
    termMonths,
    applyTaxes,
    interestRate
  });

  console_log("depreciation:", { depreciation, totalDepreciationSavings });

  const assetLifetime = getAssetLifetime(projectType);
  let numYears = !useEscalatingPayments
    ? thresholdTermYears + 5
    : assetLifetime - termYears < 5 && assetLifetime - termYears >= 0
    ? assetLifetime + 5 - (assetLifetime - termYears)
    : Math.max(termYears + 5, getAssetLifetime(projectType));

  const annualSavingsArray = calculateAnnualSavings({
    numYears,
    costSavings,
    utilityCostEscalator,
    projectType,
    systemDegradationRate,
    OEMCost
  });
  const annualPaymentsArray = calculateAnnualPayments({
    numYears,
    term,
    annualSavingsArray,
    otherCostSavings,
    percentageOfSavings,
    useEscalatingPayments
  });

  let utilityCostArray = calculateUtilityCosts({
    currentUtilityCost,
    numYears,
    costSavings,
    utilityCostEscalator
  });

  const schedule = {
    annualSavings: annualSavingsArray,
    annualOtherSavings: calculateAnnualOtherSavings({
      numYears,
      otherCostSavings,
      incentivesYear,
      yearlyDepreciationValues: depreciation
    }),
    depreciation
  };

  const {
    monthsUntilBreakEven,
    monthlySchedule,
    monthlyPayment,
    monthlyNetCosts,
    monthlySavings
  } = calculateMonthlySchedule(
    values,
    costsWithAdditionalCostsAndSavings,
    termMonths,
    schedule.depreciation,
    numYears
  );

  schedule.annualPayments =
    annualPaymentsArray ??
    Array(numYears)
      .fill(-monthlyPayment * 12)
      .map((elem, idx) => (idx >= termYears ? 0 : elem));
  schedule.netCashflow = Array(numYears)
    .fill(0)
    .map(
      (elem, idx) =>
        schedule.annualSavings[idx] +
        schedule.annualOtherSavings[idx] +
        schedule.annualPayments[idx]
    );

  let breakEvenYear =
    monthsUntilBreakEven > 1
      ? Math.trunc(monthsUntilBreakEven / 12) +
        (monthsUntilBreakEven % 12 > 0 ? 1 : 0)
      : -1;

  schedule.cumulativeCashflow = Array(numYears).fill(0);
  schedule.cumulativeCashflow.forEach((elem, idx) => {
    schedule.cumulativeCashflow[idx] =
      idx === 0
        ? schedule.netCashflow[0] - downPayment
        : schedule.cumulativeCashflow[idx - 1] + schedule.netCashflow[idx];
  });

  //For fixed term case, reduce the number of years until break even
  if (!useEscalatingPayments) {
    numYears = Math.max(breakEvenYear, termYears);
    numYears =
      assetLifetime - numYears < 5 && assetLifetime - numYears >= 0
        ? assetLifetime + 5 - (assetLifetime - numYears)
        : Math.max(numYears + 5, getAssetLifetime(projectType));

    schedule.annualSavings = schedule.annualSavings.slice(0, numYears + 1);
    schedule.annualOtherSavings = schedule.annualOtherSavings.slice(
      0,
      numYears + 1
    );
    schedule.annualPayments = schedule.annualPayments.slice(0, numYears + 1);
    schedule.netCashflow = schedule.netCashflow.slice(0, numYears + 1);
    schedule.cumulativeCashflow = schedule.cumulativeCashflow.slice(
      0,
      numYears + 1
    );
    utilityCostArray = utilityCostArray?.slice(0, numYears + 1) ?? [];
  }

  // Variables displayed in Opportunity chart
  const opportunity = calculateOpportunity({
    schedule,
    numYears,
    currentUtilityCost,
    utilityCostArray,
    otherCostSavings,
    incentives: incentivesYear
  });

  // Variable used to define if it's cashflow positive or not
  const cashFlowAmount = schedule.cumulativeCashflow[termYears - 1];

  let totalSavingsDuringTerm = getCashFlowEndTerm(schedule);
  let grossSavingsDuringTerm = 0;
  for (let i = 0; i < +(termMonths / 12).toFixed(0); i++) {
    grossSavingsDuringTerm +=
      schedule.annualSavings[i] + schedule.annualOtherSavings[i];
  }

  // Variables displayed in ThreeOptions illustration
  const totalEnergySavingsOverAssetLifetime = schedule.annualSavings
    ?.slice(0, getAssetLifetime(projectType))
    ?.reduce((ac, cur) => ac + cur, 0);

  const investmentOptions = calculateInvestmentOptionsValues({
    costs: costsForCashOption,
    savings: schedule.annualSavings,
    otherSavings: calculateAnnualOtherSavings({
      numYears,
      otherCostSavings,
      incentivesYear,
      yearlyDepreciationValues: depreciation
    }),
    numYears: Math.max(breakEvenYear, termYears, getAssetLifetime(projectType)),
    cumulativeCashflow: schedule.cumulativeCashflow,
    currentUtilityCost,
    utilityCostEscalator
  });

  // Variables displayed in TimeIsMoney chart
  const timeIsMoney = {
    labels: Array.from(Array(12).keys()).map(value => `${value + 1}`),
    data: Array(12)
      .fill(0)
      .map((value, idx) => monthlySavings * (idx + 1))
  };

  console_log("monthlySchedule", monthlySchedule);
  console_log("schedule", schedule);

  return {
    interestRate,

    totalSavingsDuringTerm,
    grossSavingsDuringTerm,
    totalEnergySavingsOverAssetLifetime,
    totalDepreciationSavings,
    constructionPeriodInterest,

    monthlyPayment,
    monthlySavings,
    monthlyNetCosts,

    schedule,
    monthlySchedule,
    monthsUntilBreakEven,
    ROIOverAssetLifetime: calculateROIOverAssetLifetime(
      schedule.cumulativeCashflow,
      downPayment,
      projectType
    ),
    termYears,
    termMonths,
    opportunity,
    investmentOptions,
    timeIsMoney,

    cashFlowAmount
  };
};

/**
 * @param {object} schedule
 */
const getCashFlowEndTerm = schedule =>
  schedule.cumulativeCashflow[
    (schedule.annualPayments?.findIndex(p => !p) ??
      schedule.cumulativeCashflow.length) - 1
  ];

Calculator.propTypes = {
  values: PropTypes.object
};

export default Calculator;

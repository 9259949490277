import React from "react";
import "./styles.scss";
import classNames from "classnames";
import { ReactComponent as InfoIcon } from "assets/info-blue.svg";
import { DateTime } from "luxon";

const DocumentsUploadHelpText = ({ currentYear, documentType }) => (
  <ul>
    <li>Kindly provide the last two years of {documentType}s.</li>
    <li>
      An interim {documentType} for {currentYear} can be provided as well.
    </li>
    <li>
      You can organize the necessary years into a single file or multiple.
    </li>
  </ul>
);

function HelpPanel({ open, setIsOpen }) {
  const currentYear = +DateTime.local().year;
  return (
    <div
      className={classNames("helpPanel", { open, close: !open })}
      tabIndex="-1"
    >
      <div className="my-2 flex gap-2">
        <button
          type="button"
          className="mr-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setIsOpen(false)}
        >
          <span className="x">×</span>
        </button>
        <h3>Help Panel</h3>
      </div>
      <div className="helpRibbon lvl2">
        <InfoIcon width={24} height={24} />
        <span className="s14">
          If you do not have Income Statements and Balance Sheets you may
          provide <span className="font-bold">tax returns</span> instead.
        </span>
      </div>
      <div className="s16">
        <h4 className="font-normal lvl2">
          Key Terms for Submitting Financial Statements:
        </h4>
        <p className="lvl2 font-bold">Income Statement:</p>
        <DocumentsUploadHelpText
          currentYear={currentYear}
          documentType={"income statement"}
        />
        <p className="lvl3 font-semibold">
          Alternate Names for Income Statement:
        </p>
        <ul>
          <li>Profit and Loss (P&L)</li>
          <li>Statement of Income</li>
          <li>Multi-period Income Statement</li>
          <li>
            {`"`}Statement of Operations and Retained Earnings{`"`} (typically
            found in audited financials)
          </li>
          <li>
            {`"`}Statement of Revenues, Expenses, and Changes in Net Position
            {`"`}
          </li>
        </ul>
        <p className="lvl2 font-bold">Balance Sheet:</p>
        <DocumentsUploadHelpText
          currentYear={currentYear}
          documentType={"balance sheet"}
        />
        <p className="lvl3 font-semibold">Alternate Names for Balance Sheet:</p>
        <ul>
          <li>Statement of Net Position </li>
          <li>Statement of Financial Position</li>
        </ul>
        <p className="lvl2 font-bold">Tax Documents:</p>
        <p className="font-normal">
          Needed if your financials {`aren't`} audited (U.S. only). Include
          specific forms like:
        </p>
        <ul>
          <li>Statement of Net Position </li>
          <li>
            {`"US Corporation Income Tax Return"`} (Form 1120, type varies)
          </li>
          <li>{`"US Income Tax Return for an S-Corporation"`} (Form 1120-S)</li>
          <li>
            {`"Return of Organization Exempt From Income Tax"`} (Form 990) for
            non-profits
          </li>
          <li>{`"U.S. Return of Partnership Income"`} (Form 1065)</li>
        </ul>
        <p className="lvl2 font-bold">Utility Bills (optional):</p>
        <p>
          For loans/leases, {`it's`} your call. For Power Purchase Agreements
          (PPAs), {`we'd`} love the last 2-3 months.
        </p>
        <p className="lvl2 font-bold">Other Documents:</p>
        <p>
          Non-profit or unique circumstances? You may provide those different
          documents instead.
        </p>
        <p className="lvl2 font-bold">Not sure what to include?</p>
        <p>Anything you think adds to your financial story is welcome.</p>
      </div>
    </div>
  );
}

export default HelpPanel;

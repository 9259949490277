import React, { useEffect } from "react";
import InfoTooltipLabel from "common/InfoTooltip";
import NumberInput from "common/NumberInput";
import "./styles.scss";
import AdvancedValuesSection from "../AdvancedValuesSection";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DownPayments from "./DownPayments";
import { DateTime } from "luxon";

function ImplementationDetailsStep({
  formProps,
  errors,
  hasError,
  infoAssistOn,
  fieldFocused,
  setFieldFocused
}) {
  const { handleBlur, values: proposal, setFieldValue } = formProps;
  useEffect(() => {
    document
      .querySelector(`input[name='estimatedConstructionStartDate']`)
      .focus();
    infoAssistOn && setFieldFocused("estimatedConstructionStartDate");
  }, []);
  const fieldProps = fieldName => ({
    name: fieldName,
    id: fieldName,
    value: proposal[fieldName],
    onChange: e => {
      setFieldValue(fieldName, e.target.value);
    },
    onBlur: e => infoAssistOn && setFieldFocused("") && handleBlur(e),
    onFocus: () => infoAssistOn && setFieldFocused(fieldName),
    valid: !hasError(errors, fieldName),
    disabled: proposal.isArchived
  });

  return (
    <div className="step implementationDetailsStep">
      <div className="numberField">
        <InfoTooltipLabel
          tooltipMessage="The estimated date that construction of the project may start"
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "estimatedConstructionStartDate"}
        >
          Est. Construction Start
        </InfoTooltipLabel>
        <KeyboardDatePicker
          className="dateInput"
          name="estimatedConstructionStartDate"
          id="estimatedConstructionStartDate"
          autoOk
          format="yyyy/MM/dd"
          variant="inline"
          inputVariant="outlined"
          value={DateTime.fromISO(proposal.estimatedConstructionStartDate)}
          onChange={date => {
            // @ts-ignore
            setFieldValue(
              "estimatedConstructionStartDate",
              date?.toISODate() ?? ""
            );
          }}
          onBlur={e => infoAssistOn && setFieldFocused("") && handleBlur(e)}
          onFocus={() =>
            infoAssistOn && setFieldFocused("estimatedConstructionStartDate")
          }
          disabled={proposal.isArchived}
        />
      </div>
      <div className="numberField constructionPeriodField">
        <InfoTooltipLabel
          tooltipMessage="The time frame, in months, that it takes to install the asset."
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "constructionPeriodLength"}
        >
          Construction Period
        </InfoTooltipLabel>
        <NumberInput
          {...{
            ...fieldProps("constructionPeriodLength"),
            prefix: "",
            suffix: "months"
          }}
          max={999}
          decimalPlaces={0}
        ></NumberInput>
      </div>
      <div className="progressPaymentsField">
        <InfoTooltipLabel
          tooltipMessage="Payments made from the funding partner during the construction period to install the asset"
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "progressPayments"}
        >
          Progress Payments
        </InfoTooltipLabel>
        <DownPayments
          progressPayments={proposal.progressPayments}
          setDownPayments={progressPaymentsObject =>
            setFieldValue("progressPayments", progressPaymentsObject)
          }
          constructionPeriodLength={proposal.constructionPeriodLength}
          disabled={proposal.isArchived}
        />
      </div>
      {proposal.projectType === "Solar" && (
        <>
          <AdvancedValuesSection />
          <div className="numberField">
            <InfoTooltipLabel
              className="whitespace-"
              tooltipMessage="The rate at which the asset loses efficiency over time"
              infoAssistOn={infoAssistOn}
              focused={fieldFocused === "systemDegradationRate"}
            >
              System Degradation Rate
            </InfoTooltipLabel>
            <NumberInput
              size="sm"
              {...{
                ...fieldProps("systemDegradationRate"),
                prefix: "",
                suffix: "%"
              }}
              max={100}
              decimalPlaces={2}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ImplementationDetailsStep;

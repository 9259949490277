import "./index.scss";
import "./font.scss";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RoleContextProvider } from "./auth/contexts/role";
import { UserContextProvider } from "./auth/contexts/user";
import AppRoutes from "./App.routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonAdapter from "@date-io/luxon";
import { DateTime } from "luxon";

/**
 *
 * @returns {JSX.Element}
 */
function App() {
  const queryClient = new QueryClient();
  return (
    <MuiPickersUtilsProvider utils={LuxonAdapter}>
      <UserContextProvider>
        <RoleContextProvider>
          <QueryClientProvider client={queryClient}>
            <div className="financing-portal">
              <AppRoutes />
              <p className="mt-10 pb-8 text-center">
                © Copyright {DateTime.local().year} En-Powered Inc. All rights
                reserved.
              </p>
            </div>
          </QueryClientProvider>
        </RoleContextProvider>
      </UserContextProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;

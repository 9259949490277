import React, { useEffect, useState } from "react";
import "./styles.scss";
import IconTextButton from "common/IconTextButton";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import ProjectsTable from "./ProjectsTable";
import { MainFrame } from "common/TopBar";
import CreateProjectModal from "../CreateProjectModal";
import { useLocation, useParams } from "react-router-dom";

function ProjectsPage({
  projectList,
  refetchProjects,
  partnerCompany,
  setErrorMessageAndOpen,
  partnerCompanyId,
  setPartnerCompanyId
}) {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const location = useLocation();
  const partnerCompanyIdParam = useParams()?.partnerCompanyId;

  useEffect(() => {
    partnerCompanyIdParam &&
      setPartnerCompanyId &&
      partnerCompanyId !== partnerCompanyIdParam &&
      setPartnerCompanyId(partnerCompanyIdParam);
  }, [location]);
  return (
    <MainFrame>
      <div className="projectsPage">
        {showCreateProjectModal && (
          <CreateProjectModal
            isOpen={showCreateProjectModal}
            setIsOpen={setShowCreateProjectModal}
            partnerCompanyName={partnerCompany.partnerCompanyName}
            partnerCompanyId={partnerCompany.partnerCompanyId}
            setErrorMessageAndOpen={setErrorMessageAndOpen}
          />
        )}
        <div className="flex block w-full h-full py-2 ">
          <div className="w-full window">
            <div className="projectsPage-header">
              <h4>Projects</h4>
              <div className="flex gap-6">
                <IconTextButton
                  className="borderYellow1 round"
                  label="New Project"
                  icon={<PlusIcon />}
                  onClick={() => setShowCreateProjectModal(true)}
                />
              </div>
            </div>
            <ProjectsTable
              projectList={projectList}
              refetchProjects={refetchProjects}
            />
          </div>
        </div>
      </div>
    </MainFrame>
  );
}

export default ProjectsPage;

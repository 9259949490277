export const formatNumber = (value, decimalPlaces = 2) =>
  (+(+value).toFixed(decimalPlaces)).toLocaleString("en-US", {
    minimumFractionDigits: 2
  });

export const formatAmountForScreen = (amountIn, decimalPlaces) => {
  const amount = (+(+amountIn).toFixed(decimalPlaces)).toLocaleString("en-US", {
    minimumFractionDigits: 2
  });
  return amountIn < 0 ? `-$${amount.slice(1)}` : `$${amount}`;
};
export const renderDollar = (value, decimalPlaces = 2, showZero) =>
  value !== 0 || showZero
    ? formatAmountForScreen(value ?? 0, decimalPlaces)
    : "-";

export const capitalize = str =>
  str && str.charAt(0).toUpperCase() + str.slice(1);

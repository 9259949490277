import React, { useEffect } from "react";
import "./styles.scss";
import classNames from "classnames";

function Modal({
  blurrableContentRef,
  centered,
  left,
  top,
  right,
  bottom,
  children,
  isOpen,
  className
}) {
  useEffect(() => {
    if (isOpen && !centered) {
      document.getElementById("modal-content").style.left =
        left && (typeof left === "number" ? `${left}px` : left);
      document.getElementById("modal-content").style.top =
        top && (typeof top === "number" ? `${top}px` : top);
      document.getElementById("modal-content").style.bottom =
        bottom && (typeof bottom === "number" ? `${bottom}px` : bottom);
      document.getElementById("modal-content").style.right =
        right && (typeof right === "number" ? `${right}px` : right);
    }
    if (blurrableContentRef?.current && isOpen) {
      blurrableContentRef?.current?.classList?.add("blurred");
    }
    return () => {
      blurrableContentRef?.current?.classList?.remove("blurred");
    };
  }, [isOpen]);

  return isOpen ? (
    <div
      className={classNames("modal", className, {
        "flex justify-center items-center": centered
      })}
    >
      <div id="modal-content" className="modal-content">
        {children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default Modal;

import { authedFetch, noAuthFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @returns {Promise<Recipient[]>}
 */
export const getPartnerCompany = async partnerCompanyId => {
  return (
    noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        method: "getPartnerByCompanyId",
        params: {
          partnerCompanyId
        }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
      .catch(error => {
        throw error;
      })
  );
};

export const getAllPartnerCompanies = async () => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        method: "getPartnerCompanies"
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
      .then(result => {
        return result?.items ?? [];
      })
  );
};

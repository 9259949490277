import React from "react";
import "./styles.scss";
import classNames from "classnames";

/**
 *
 * @param {object} params
 * @param {string} params.className
 * @param {string} params.label
 * @param {React.FC} params.icon
 * @param {function} params.onClick
 * @param {boolean} params.disabled
 * @param {boolean} params.filled
 * @param {import("react").ReactComponentElement} params.icon
 * @returns
 */

function IconTextButton({
  className,
  label,
  onClick,
  icon,
  disabled,
  filled,
  primary,
  outlined,
  ...props
}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames({
        iconTextButton: true,
        [className]: true,
        noIcon: !icon,
        filled,
        primary,
        outlined,
        disabled
      })}
      {...props}
      disabled={disabled}
    >
      {icon}
      {label && (
        <span
          className={classNames({
            "dropdownMenu-label": true,
            disabled: disabled
          })}
        >
          {label}
        </span>
      )}
    </button>
  );
}

export default IconTextButton;

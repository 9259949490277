import React, { useContext, useEffect, useState } from "react";
import { ProposalForm } from "./ProposalForm";
import {
  COOKIE_FA_USER,
  COOKIE_USER_CREDENTIALS,
  UserContext
} from "./auth/contexts/user";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { AdminPage } from "./AdminPage";
import ProjectsPage from "./components/ProjectsPage";
import { useQuery } from "react-query";
import { getFinancingScenarios } from "services/FinancingScenarios";
import { Spinner } from "@enpowered/ui";
import "./index.scss";
// import PageNotFound from "./components/PageNotFound";
import { getPartnerCompany } from "./services/PartnerCompany";
// import LoginPage from "./auth/LoginPage";
import {
  adminManageCompaniesOperation,
  isAuthorizedForOperation
} from "./services/auth/authorize";
import CompaniesPage from "./components/CompaniesPage";
import ProjectsArchive from "./components/ProjectsArchive";
import CreditApp from "./components/CreditApp";
import { getCookie } from "./utils/cookies";
import { emitter } from "utils/emitter";
import SnackBar from "./common/SnackBar";
import PageNotFound from "./common/PageNotFound";

function Redirect({ to, isExternal }) {
  let navigate = useNavigate();
  if (isExternal) window.location.href = to;
  else navigate(to);
  return null;
}

// const IdentifiedSession = ({ children, partnerCompany }) => {
// let user;
// if (!partnerCompanyId) user = useContext(UserContext).user;

// return partnerCompanyId || user ? children : <Redirect to="/login" />;
//   return partnerCompany?.partnerCompanyId ? children : <PageNotFound />;
// };

const LoggedInSession = ({
  children,
  partnerCompanyId,
  setPartnerCompanyId
}) => {
  useContext(UserContext).user?.partnerCompanyId &&
    !partnerCompanyId &&
    setPartnerCompanyId(useContext(UserContext).user.partnerCompanyId);
  return useContext(UserContext)?.user ? (
    children
  ) : (
    <Redirect
      to={`${process.env.REACT_APP_IAM_UI_URL}/logout?redirectTo=${window.location.href}`}
      isExternal={true}
    />
  );
};

export const AdminLoggedInSession = ({ children }) =>
  isAuthorizedForOperation(
    useContext(UserContext).user,
    adminManageCompaniesOperation
  ) ? (
    children
  ) : (
    <Redirect to="/internal-login" />
  );

const isEnpowered = user =>
  user?.email &&
  (user.email.indexOf("@enpowered.com") >= 0 ||
    user.email.indexOf("@getenpowered.com") >= 0);

const EnpoweredLoggedInSession = ({ children }) =>
  isEnpowered(useContext(UserContext).user) ? (
    children
  ) : (
    <Redirect to="/internal-login" />
  );

export const AppRoutes = () => {
  const partnerCompanyIdInMemory = getCookie(COOKIE_FA_USER)?.partnerCompanyId;
  const email = getCookie(COOKIE_USER_CREDENTIALS)?.email;
  const [partnerCompanyId, setPartnerCompanyId] = useState(
    partnerCompanyIdInMemory
  );

  const { data: partnerCompany, isLoading: isLoadingPartnerCompany } = useQuery(
    `partner-company-${partnerCompanyId}`,
    async () => (partnerCompanyId ? getPartnerCompany(partnerCompanyId) : null)
  );
  const {
    data: projectList = [],
    isLoading,
    refetch
  } = useQuery(
    `projects-list-${partnerCompanyId}`,
    async () => partnerCompanyId && getFinancingScenarios(partnerCompanyId)
  );

  const [message, setMessage] = useState(null);
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(false);
  const setErrorMessageAndOpen = ({ text, type = "error" }) => {
    setMessage({ text, type });
    setIsMessageSnackBarOpen(true);
  };
  useEffect(() => {
    /** @param {{ type: "info" | "warning" | "error" | "success", name: string, text: string }} msg */
    emitter.on("MainSnackBar", setErrorMessageAndOpen);
    return () => {
      emitter.off("MainSnackBar");
    };
  }, []);

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <SnackBar
        messages={message?.text ? [message.text] : []}
        type={message?.type}
        onClose={() => setMessage({})}
        duration={5000}
        isOpen={isMessageSnackBarOpen}
        setIsOpen={setIsMessageSnackBarOpen}
      />
      <Routes>
        <Route
          path="/"
          element={
            <LoggedInSession
              partnerCompanyId={partnerCompanyId}
              setPartnerCompanyId={setPartnerCompanyId}
            >
              {isLoading || !partnerCompanyId ? (
                <div className="w-1/6 m-0 m-auto pt-40 flex flex-col items-center">
                  <Spinner className={"ml-2 s16 mt-4 mb-6"} size={40} />
                  <p>Hang tight while systems connect...</p>
                </div>
              ) : isEnpowered({ email }) ? (
                <CompaniesPage setPartnerCompanyId={setPartnerCompanyId} />
              ) : (
                <ProjectsPage
                  projectList={projectList}
                  refetchProjects={refetch}
                  partnerCompany={partnerCompany}
                  setErrorMessageAndOpen={setErrorMessageAndOpen}
                />
              )}
            </LoggedInSession>
          }
        />
        <Route
          path="/company/:partnerCompanyId"
          element={
            <LoggedInSession
              partnerCompanyId={partnerCompanyId}
              setPartnerCompanyId={setPartnerCompanyId}
            >
              {isLoading ? (
                <div className="w-1/6 m-0 m-auto pt-40 flex flex-col items-center">
                  <Spinner className={"ml-2 s16 mt-4 mb-6"} size={40} />
                  <p>Hang tight while systems connect...</p>
                </div>
              ) : !isLoadingPartnerCompany &&
                !partnerCompany?.partnerCompanyId ? (
                <PageNotFound />
              ) : (
                <ProjectsPage
                  projectList={projectList}
                  refetchProjects={refetch}
                  partnerCompany={partnerCompany}
                  setErrorMessageAndOpen={setErrorMessageAndOpen}
                  partnerCompanyId={partnerCompanyId}
                  setPartnerCompanyId={setPartnerCompanyId}
                />
              )}
            </LoggedInSession>
          }
        />
        <Route
          path="/project/:financingScenarioId"
          element={
            <LoggedInSession
              partnerCompanyId={partnerCompanyId}
              setPartnerCompanyId={setPartnerCompanyId}
            >
              <ProposalForm
                noProjectsYet={!projectList?.length}
                partnerCompany={partnerCompany}
                refetchProjects={refetch}
                isLoadingPartnerCompany={isLoadingPartnerCompany}
              />
            </LoggedInSession>
          }
        />
        <Route path="/creditApp/:creditAppId" element={<CreditApp />} />
        <Route
          path="/projects"
          element={
            <LoggedInSession
              partnerCompanyId={partnerCompanyId}
              setPartnerCompanyId={setPartnerCompanyId}
            >
              <ProjectsPage
                projectList={projectList}
                refetchProjects={refetch}
                partnerCompany={partnerCompany}
                setErrorMessageAndOpen={setErrorMessageAndOpen}
              />
            </LoggedInSession>
          }
        />
        <Route
          path="/projects/archive"
          element={
            <LoggedInSession
              partnerCompanyId={partnerCompanyId}
              setPartnerCompanyId={setPartnerCompanyId}
            >
              <ProjectsArchive
                partnerCompanyId={partnerCompanyId}
                onBackClicked={refetch}
              />
            </LoggedInSession>
          }
        />
        {/* <Route path="/internal-login" element={<LoginPage />} /> */}
        {/* <Route path="/oauth" element={<OAuthScreen />} /> */}
        <Route
          path="/companies"
          element={
            <EnpoweredLoggedInSession>
              <CompaniesPage setPartnerCompanyId={setPartnerCompanyId} />
            </EnpoweredLoggedInSession>
          }
        />
        <Route
          path="/admin"
          element={
            <EnpoweredLoggedInSession>
              <AdminPage />
            </EnpoweredLoggedInSession>
          }
        />
        <Route
          path="/accept"
          element={
            <Redirect
              to={`${process.env.REACT_APP_IAM_UI_URL}/accept${location.search}`}
              isExternal={true}
            />
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;

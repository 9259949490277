import React from "react";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Spinner } from "@enpowered/ui";
import classNames from "classnames";

export const MultiStepForm = ({
  currentStep,
  setCurrentStep,
  hasErrors,
  isSubmitting,
  currentStepName,
  onNext = () => {},
  className
}) => {
  const handleNext = async () => {
    if (!(await currentStep.isValid())) return null;
    onNext(currentStepName);
    setCurrentStep(currentStep.next);
  };
  return (
    <div
      className={classNames("step-content", className)}
      onKeyDown={event =>
        currentStep.pressEnterToNextEnabled &&
        !currentStep.submitStep &&
        event.key === "Enter" &&
        handleNext()
      }
    >
      {currentStep.content}
      <div className="nav-buttons">
        {currentStep.showNavButtons && currentStep.prev && (
          <button
            type="button"
            className="icon"
            onClick={() => setCurrentStep(currentStep.prev)}
          >
            <ArrowBackIosNewIcon />
          </button>
        )}
        {currentStep.showNavButtons && currentStep.next && (
          <>
            <button
              hidden={currentStep.submitStep ? true : undefined}
              type="button"
              className="icon"
              onClick={handleNext}
            >
              <ArrowForwardIos disabled={hasErrors ? "disabled" : undefined} />
            </button>
            <button
              hidden={currentStep.submitStep ? undefined : true}
              type="submit"
              disabled={currentStep.submitStep ? undefined : true}
              className="submitButton"
            >
              {isSubmitting ? (
                <div>
                  &nbsp;
                  <Spinner className="spinner" />
                </div>
              ) : (
                "Submit Request"
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

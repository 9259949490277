import React, { useContext } from "react";

import { UserContext } from "auth/contexts/user";
import { useLogout } from "auth/hooks";
import classNames from "classnames";
import "./styles.scss";

import Logo from "assets/Logo_White_Bg.png";
import { ReactComponent as MediaFolderIcon } from "assets/media-folder.svg";
import { ReactComponent as ArchiveListIcon } from "assets/archive-list.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import DropdownMenu from "../DropdownMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { getMixpanel } from "../../services/mixpanel";

export const MainFrame = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <TopBar navigate={navigate} currentPath={location.pathname} />
      <div className="content">{children}</div>
    </div>
  );
};

/**
 *
 * @param {object} props
 * @returns
 */
export const TopBar = ({ className, navigate, currentPath }) => {
  const { user } = useContext(UserContext);
  const mixpanel = getMixpanel();
  const logout = useLogout();
  const isInArchive = currentPath === "/projects/archive";
  const isInProjects = currentPath === "/projects" || currentPath === "/";
  return (
    <div className={classNames("topBar p-3 flex justify-between", className)}>
      <div className="flex items-center">
        <a href="/">
          <img className="h-18" src={Logo} alt="logo" />
        </a>
        <h3>Financing Accelerator</h3>
      </div>
      {user && (
        <div className="loggedInDiv">
          <a className="roundButton" onClick={() => navigate("/projects")}>
            <div
              className={classNames("roundIcon", { selected: isInProjects })}
            >
              <MediaFolderIcon />
            </div>
            <span>Projects</span>
          </a>
          <a
            className="roundButton"
            onClick={() => {
              mixpanel.track("View Archive (Nav)");
              navigate("/projects/archive");
            }}
          >
            <div className={classNames("roundIcon", { selected: isInArchive })}>
              <ArchiveListIcon />
            </div>
            <span>Archive</span>
          </a>
          <div className="inline-block">
            <DropdownMenu
              tooltipTitle="My Profile"
              className="profile"
              menuIcon={
                user.partnerRepName || user.lastName
                  ? `${
                      user.partnerRepName?.toUpperCase()[0] ?? ""
                    }${user.lastName?.toUpperCase()?.[0] ?? ""}`
                  : user.email?.toUpperCase()?.[0] ?? ""
              }
              options={[
                {
                  label: (
                    <div className="mx-2">
                      Hi {user?.partnerRepName ?? user?.email}!
                    </div>
                  )
                },
                {
                  label: (
                    <div className="mx-2 my-1">
                      <LogoutIcon className="inline-block mr-2 mb-1" />
                      <div className="inline-block">Logout</div>
                    </div>
                  ),
                  onClick: () => logout()
                }
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

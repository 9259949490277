import { Radio } from "@enpowered/ui";
import React from "react";
import "./styles.scss";

import classNames from "classnames/dedupe";

export const RadioGroup = ({
  values,
  currentValue,
  name,
  onChange,
  direction = "horizontal",
  size = "regular",
  children,
  disabled,
  valid,
  className
}) => {
  const radioGroupClasses = classNames(["flex", className], {
    "flex-row": direction === "horizontal",
    "flex-col": direction === "vertical"
  });

  return (
    <div className={radioGroupClasses} role="radiogroup">
      {children
        ? children({ current: currentValue, onChange: onChange })
        : values.map((valueObj, index) => {
            // if not id is supplied, use the value with not spaces
            const id = valueObj.id
              ? valueObj.id
              : valueObj.value.replace(" ", "");
            return (
              <Radio
                key={`radio-${name}-${index}`}
                name={name}
                value={valueObj.value}
                label={valueObj.label}
                id={id}
                current={currentValue}
                onChange={onChange}
                disabled={disabled}
                valid={valid}
                size={size}
              />
            );
          })}
    </div>
  );
};

export default RadioGroup;

import {
  getTaxPercentage,
  termSolver,
  calculateEntireDepreciationSchedule,
  CA_FEDERAL_TAX_PERCENTAGE,
  US_FEDERAL_TAX_PERCENTAGE
} from "@enpowered/payment-proposal-view";

export const isDepreciationIncentive = incentiveName =>
  ["CCA", "MACRS"].includes(incentiveName);

export const getDefaultTaxRateByRegion = (countryCode, state) => {
  const federalTaxRate =
    countryCode === "CA"
      ? CA_FEDERAL_TAX_PERCENTAGE
      : state === "PR"
        ? 0
        : US_FEDERAL_TAX_PERCENTAGE;
  return {
    federalTaxRate,
    stateTaxRate: getTaxPercentage(state) - federalTaxRate
  };
};

export const calculateDepreciation = async (
  proposalInputsForCalculator,
  taxRate
) => {
  const term = termSolver(proposalInputsForCalculator);
  if (!term.payable) return Promise.reject("Not payable");
  else {
    return Promise.resolve(
      calculateEntireDepreciationSchedule({
        ...proposalInputsForCalculator,
        incentives: proposalInputsForCalculator.incentivesYear,
        termMonths: term.years * 12 + (term.months ?? 0),
        taxRate
      })
    ).then(depreciation => {
      const otherYears = depreciation?.slice(1).reduce((ac, i) => ac + i, 0);
      return {
        amount: isNaN(depreciation?.[0]) ? 0 : depreciation?.[0],
        otherYears: isNaN(otherYears) ? 0 : otherYears
      };
    });
  }
};

export const calculateInvestmentOptionsValues = ({
  costs,
  savings,
  otherSavings,
  numYears,
  cumulativeCashflow,
  currentUtilityCost,
  utilityCostEscalator
}) =>
  Array(numYears)
    .fill(0)
    .reduce(
      (ac, _cur, idx) =>
        idx === 0
          ? {
              cashPurchase: [savings[idx] + otherSavings[idx] - costs],
              ...(currentUtilityCost > 0
                ? {
                    doNothing: [0]
                  }
                : {}),
              finance: [cumulativeCashflow[idx]],
              labels: [`${idx + 1}`]
            }
          : {
              cashPurchase: ac.cashPurchase.concat(
                ac.cashPurchase[idx - 1] + savings[idx] + otherSavings[idx]
              ),
              ...(currentUtilityCost > 0
                ? {
                    doNothing: ac.doNothing.concat(
                      ac.doNothing[idx - 1] +
                        (ac.doNothing[idx - 1] - currentUtilityCost) *
                          utilityCostEscalator
                    )
                  }
                : {}),
              finance: ac.finance.concat(cumulativeCashflow[idx]),
              labels: ac.labels.concat(`${idx + 1}`)
            },
      {}
    );

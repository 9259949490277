import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

const CaptureClickOutsideComponent = ({
  elementRef,
  onClickOutsideElement
}) => {
  const [id] = useState(uuid());
  useEffect(() => {
    const handleClickOutside = event => {
      if (elementRef.current && !elementRef.current.contains(event.target))
        onClickOutsideElement();
    };
    document.addEventListener(`click-${id}`, handleClickOutside);
    return () => {
      document.removeEventListener(`click-${id}`, handleClickOutside);
    };
  }, []);

  return "";
};

export default CaptureClickOutsideComponent;

import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setCookie = (cookieName, data) => {
  cookies.set(
    cookieName,
    data,
    process.env.REACT_APP_LOCAL_ENV === "true"
      ? {
          domain: "localhost",
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90)
        }
      : {
          domain: ".enpowered.com",
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 90)
        }
  );
};

export const getCookie = cookieName => cookies.get(cookieName);

export const removeCookie = cookieName =>
  cookies.remove(cookieName, {
    path: "/",
    domain:
      process.env.REACT_APP_LOCAL_ENV === "true"
        ? "localhost"
        : ".enpowered.com"
  });

import { Switch } from "@enpowered/ui";
import React from "react";
import { ReactComponent as WavingHandIcon } from "assets/waving-hand.svg";
import classNames from "classnames";
import { getMixpanel } from "../../services/mixpanel";
import "./styles.scss";

function ProposalProSwitch({ infoAssistOn, setInfoAssistOn, className }) {
  const mixpanel = getMixpanel();
  return (
    <div className={classNames(className ?? "", "flex items-center gap-2")}>
      <div className="switchDiv">
        <WavingHandIcon
          className={classNames({
            wavingHandOff: !infoAssistOn,
            wavingHandOn: infoAssistOn
          })}
        />
        <label>Proposal Pro</label>
      </div>
      <Switch
        onChange={() => {
          setInfoAssistOn(!infoAssistOn);
          mixpanel.track("Proposal Pro switch clicked", {
            "On/Off": !infoAssistOn ? "On" : "Off"
          });
        }}
        label={infoAssistOn ? " On" : " Off"}
        name="infoAssistOn"
        checked={infoAssistOn}
        className="switchBlue"
      />
    </div>
  );
}

export default ProposalProSwitch;

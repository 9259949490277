const { getAssetLifetime } = require("./calculations");

/**
 *
 * @param {Array<Number>} cumulativeCashflow
 * @param {number} downPayment
 * @param {("Solar" | "HVAC" | "Lighting" | "Other")} [projectType]
 * @returns
 */

export const calculateROIOverAssetLifetime = (
  cumulativeCashflow,
  downPayment,
  projectType = "Other"
) => {
  const assetLifeTime = getAssetLifetime(projectType);
  if (
    !cumulativeCashflow?.[assetLifeTime - 1] ||
    !downPayment ||
    downPayment <= 0
  )
    return null;
  return (cumulativeCashflow[assetLifeTime - 1] / downPayment) * 100;
};

import { getTaxPercentage } from "../utils/taxes";
import { calculateEntireDepreciationSchedule } from "../utils/depreciation";
import { createConsoleLog } from "../utils/console";
const console_log = createConsoleLog("Calculator", { debug: false });
const thresholdTermYears = 30;

/**
 * Financial formula for PMT from Excell
 * Compute the payment against loan principal plus interest.
 *
 * @param {number} i
 * @param {number} n
 * @param {number} pv
 * @returns {number}
 */
export const pmt = (i, n, pv) => {
  const temp = (1 + i) ** n;
  return (pv * i * temp) / (temp - 1);
};

export const calculateInitialMonthlyPayment = ({
  useEscalatingPayments,
  percentageOfSavings,
  costSavings,
  otherCostSavings,
  interestRate,
  termMonths,
  costsWithAdditionalCostsAndSavings
}) =>
  useEscalatingPayments
    ? (percentageOfSavings * (costSavings + otherCostSavings)) / 12
    : pmt(interestRate / 12, termMonths, costsWithAdditionalCostsAndSavings);

export const calculateAnnualSavings = ({
  numYears,
  costSavings,
  utilityCostEscalator,
  projectType,
  systemDegradationRate,
  OEMCost
}) =>
  Array(numYears)
    .fill(costSavings)
    .map(
      (elem, idx) =>
        costSavings *
          Math.pow(1 + utilityCostEscalator, idx) *
          Math.pow(
            projectType === "Solar" ? 1 - systemDegradationRate : 1,
            idx
          ) -
        OEMCost
    );

export const calculateAnnualPayments = ({
  numYears,
  term,
  annualSavingsArray,
  otherCostSavings,
  percentageOfSavings,
  useEscalatingPayments
}) =>
  useEscalatingPayments
    ? Array(numYears)
        .fill(0)
        .map((_elem, idx) =>
          idx < term.years
            ? -(annualSavingsArray[idx] + otherCostSavings) *
              percentageOfSavings
            : idx === term.years
            ? (-(annualSavingsArray[idx] + otherCostSavings) *
                percentageOfSavings *
                term.months) /
              12
            : 0
        )
    : null;

export const calculateUtilityCosts = ({
  currentUtilityCost,
  numYears,
  costSavings,
  utilityCostEscalator
}) =>
  currentUtilityCost
    ? Array(numYears)
        .fill(Math.max(currentUtilityCost - costSavings, 0))
        .map(
          (_elem, idx) =>
            Math.max(currentUtilityCost - costSavings, 0) *
            Math.pow(1 + utilityCostEscalator, idx)
        )
    : [];

export const calculateAnnualOtherSavings = ({
  numYears,
  otherCostSavings,
  incentivesYear,
  yearlyDepreciationValues
}) => {
  const depreciationIncentive = getDepreciationFromIncentives(incentivesYear);
  return Array(numYears)
    .fill(otherCostSavings)
    .map(
      (elem, idx) =>
        (idx === 0
          ? elem + getIncentivesTotalNotUsableInPayments(incentivesYear)
          : elem) +
        (!depreciationIncentive ||
        (depreciationIncentive?.useInPayments && idx === 0)
          ? 0
          : !depreciationIncentive?.useInPayments && idx === 0
          ? yearlyDepreciationValues[0]
          : yearlyDepreciationValues[idx] ?? 0)
    );
};
export const calculateOpportunitySavings = ({ numYears, schedule }) =>
  Array(numYears)
    .fill(0)
    .map(
      (elem, idx) => schedule.annualSavings[idx] + schedule.annualPayments[idx]
    )
    .map(elem => (elem < 0 ? 0 : elem));

export const calculateOpportunityOtherSavings = ({
  numYears,
  schedule,
  otherCostSavings
}) =>
  Array(numYears)
    .fill(0)
    .map((_elem, idx) =>
      schedule.annualSavings[idx] + schedule.annualPayments[idx] >= 0
        ? otherCostSavings
        : otherCostSavings -
          Math.abs(schedule.annualSavings[idx] + schedule.annualPayments[idx])
    )
    .map(elem => (elem < 0 ? 0 : elem));

export const calculateOpportunity = ({
  schedule,
  numYears,
  currentUtilityCost,
  utilityCostArray,
  otherCostSavings,
  incentives
}) => {
  const customerPayments = schedule.annualPayments.map(elem => -elem);
  const savings = calculateOpportunitySavings({ numYears, schedule });
  const otherSavings = calculateOpportunityOtherSavings({
    numYears,
    schedule,
    otherCostSavings
  });
  const yearlyIncentives = [
    getIncentivesTotalNotUsableInPayments(incentives) +
      (!getDepreciationFromIncentives(incentives)?.useInPayments &&
      schedule?.depreciation[0]
        ? schedule?.depreciation[0]
        : 0),
    ...(schedule?.depreciation?.length ? schedule.depreciation.slice(1) : [])
  ];
  let utilityCost, labels;
  if (currentUtilityCost) {
    utilityCost = utilityCostArray;
    utilityCost.unshift(currentUtilityCost);
    customerPayments.unshift(0);
    savings.unshift(0);
    otherSavings.unshift(0);
    yearlyIncentives.unshift(0);
    labels = savings.map((elem, idx) => `${idx}`);
  } else {
    labels = savings.map((elem, idx) => `${idx + 1}`);
  }
  const netCashFlow = schedule.netCashflow;
  return {
    customerPayments,
    savings,
    otherSavings,
    utilityCost,
    yearlyIncentives,
    netCashFlow,
    labels
  };
};

/**
 * @returns {number} construction period interest
 */
export const getConstructionPeriodInterest = ({
  projectCostMinusDownPayment,
  constructionPeriodLength = 0,
  progressPayments = [],
  interestRate
}) => {
  if (!Array.isArray(progressPayments) || !progressPayments?.length) return 0;

  let balance = 0;
  let totalInterest = 0;
  let lastDrawMonth = 1;
  progressPayments.sort((a, b) => a.month - b.month);
  progressPayments.forEach(({ percentage, month }) => {
    for (
      let m = lastDrawMonth;
      m < month && m <= constructionPeriodLength;
      m++
    ) {
      totalInterest += balance * (interestRate / 12);
      balance += balance * (interestRate / 12);
    }
    balance += projectCostMinusDownPayment * (percentage / 100);
    lastDrawMonth = month;
  });

  // in case the last draw is not at the end of the construction period (lastDrawMonth < constructionPeriodLength)
  for (let m = lastDrawMonth; m <= constructionPeriodLength; m++) {
    totalInterest += balance * (interestRate / 12);
    balance += balance * (interestRate / 12);
  }
  return totalInterest;
};

export const calculateMonthlySchedule = (
  proposalInputs,
  costsWithAdditionalCostsAndSavings,
  termMonths,
  depreciation,
  numYears
) => {
  const {
    costSavings,
    projectCost,
    downPayment = 0,
    otherCostSavings,
    utilityCostEscalator,
    incentivesYear,
    projectType,
    interestRate = 8.5 / 100,
    systemDegradationRate = 0,
    useEscalatingPayments,
    percentageOfSavings,
    progressPayments = [],
    constructionPeriodLength,
    OEMCost = 0
  } = proposalInputs;

  let projectCostMinusDownPayment = projectCost - downPayment;
  let monthlySavings = (costSavings + otherCostSavings - OEMCost) / 12;

  const monthlyPayment = calculateInitialMonthlyPayment({
    useEscalatingPayments,
    percentageOfSavings,
    costSavings,
    otherCostSavings,
    interestRate,
    termMonths,
    costsWithAdditionalCostsAndSavings
  });
  const monthlyNetCosts = monthlySavings - monthlyPayment;

  let updatedMonthlySavings = monthlySavings;
  let monthlyPayments =
    useEscalatingPayments && percentageOfSavings
      ? monthlySavings * percentageOfSavings
      : monthlyPayment;

  let currentBalance =
    projectCostMinusDownPayment +
    getConstructionPeriodInterest({
      projectCostMinusDownPayment,
      constructionPeriodLength,
      progressPayments,
      interestRate
    });
  let remainingBalance = currentBalance;
  let cumulativeSavings = 0;
  let monthsUntilBreakEven = -1;
  let monthlySchedule = [];
  for (
    let month = 1;
    month < numYears * 12 + 1 ||
    (monthlySchedule.length > 1 &&
      monthlySchedule[monthlySchedule.length - 1]?.cumulativeSavings < 0 &&
      monthlySchedule[monthlySchedule.length - 1]?.cumulativeSavings >
        monthlySchedule[monthlySchedule.length - 2]?.cumulativeSavings);
    month++
  ) {
    currentBalance = remainingBalance;
    let interestPaidMonthly = (currentBalance * interestRate) / 12;
    if (month % 12 === 1 && month !== 1) {
      updatedMonthlySavings =
        (updatedMonthlySavings + (-otherCostSavings + OEMCost) / 12) *
          (1 + utilityCostEscalator) *
          (projectType === "Solar" ? 1 - systemDegradationRate : 1) +
        (otherCostSavings - OEMCost) / 12;
    }
    monthlyPayments =
      currentBalance === 0
        ? 0
        : Math.min(
            currentBalance + interestPaidMonthly,
            useEscalatingPayments && percentageOfSavings
              ? updatedMonthlySavings * percentageOfSavings
              : monthlyPayment
          );
    let principalPaid = monthlyPayments - interestPaidMonthly;
    let additionalPayment = 0;
    let additionalSavings = 0;
    if (
      (month === 12 && projectType !== "Lighting") ||
      (month === 6 && projectType === "Lighting")
    ) {
      const [
        incentivesApplied,
        incentivesNotApplied
      ] = getIncentivesWithoutDepreciation(incentivesYear).reduce(
        ([applied, notApplied], incentive) =>
          incentive.useInPayments
            ? [applied + incentive.amount, notApplied]
            : [applied, notApplied + incentive.amount],
        [0, 0]
      );
      additionalPayment += incentivesApplied;
      additionalSavings += incentivesNotApplied;
    }
    //add depreciation as lump sum payment if it is used in payments
    if (
      getDepreciationFromIncentives(incentivesYear)?.useInPayments &&
      month === 12
    )
      additionalPayment += depreciation[0];
    //add depreciation for all years, except the first year if it is not used in payments
    else if (
      month % 12 === 0 &&
      depreciation?.[month / 12 - 1] > 0 &&
      (month !== 12 ||
        getDepreciationFromIncentives(incentivesYear)?.useInPayments === false)
    )
      additionalSavings += depreciation[month / 12 - 1];
    remainingBalance = Math.max(
      0,
      remainingBalance - (principalPaid + additionalPayment)
    );

    if (
      (cumulativeSavings < 0 &&
        cumulativeSavings +
          (updatedMonthlySavings + additionalSavings - monthlyPayments) >=
          0) ||
      (cumulativeSavings >= 0 &&
        (monthlySchedule.length < 1 ||
          monthlySchedule.slice(-1)[0].cumulativeSavings < 0))
    )
      monthsUntilBreakEven = month;
    else if (cumulativeSavings < 0) monthsUntilBreakEven = -1;
    cumulativeSavings +=
      updatedMonthlySavings +
      additionalSavings -
      monthlyPayments -
      (month === 1 ? downPayment : 0);

    monthlySchedule.push({
      month,
      currentBalance,
      monthlyPayments,
      interestPaidMonthly,
      principalPaid,
      additionalPayment,
      remainingBalance: Math.max(remainingBalance, 0),
      monthlySavings: updatedMonthlySavings,
      additionalSavings,
      cumulativeSavings,
      savings: updatedMonthlySavings + additionalSavings,
      netSavings: updatedMonthlySavings + additionalSavings - monthlyPayments
    });
  }
  return {
    monthlySchedule,
    monthsUntilBreakEven,
    monthlyPayment,
    monthlyNetCosts,
    monthlySavings
  };
};
export const calculateCostsAndOtherCostsAndSavings = ({
  projectCost,
  downPayment = 0,
  termMonths,
  incentivesYear,
  applyTaxes,
  interestRate,
  countryCode,
  state,
  constructionPeriodLength,
  estimatedConstructionStartDate,
  progressPayments,
  yearConstructionCompleted
}) => {
  console_log("calculate other costs and savings");
  let projectCostMinusDownPayment = projectCost - downPayment;
  let costsWithAdditionalCostsAndSavings = projectCostMinusDownPayment;
  let costsForCashOption = projectCostMinusDownPayment;
  const constructionPeriodInterest = getConstructionPeriodInterest({
    projectCostMinusDownPayment,
    constructionPeriodLength,
    progressPayments,
    interestRate
  });
  costsWithAdditionalCostsAndSavings += constructionPeriodInterest;
  let depreciation = [],
    incentivesFutureValue = 0,
    totalDepreciationSavings = 0;

  const depreciationIncentive = getDepreciationFromIncentives(incentivesYear);
  if (depreciationIncentive) {
    let taxRate;
    if (applyTaxes) {
      taxRate =
        depreciationIncentive.federalTaxRate &&
        depreciationIncentive.stateTaxRate
          ? depreciationIncentive.federalTaxRate +
            depreciationIncentive.stateTaxRate
          : getTaxPercentage(state) / 100;
    }
    console_log("depreciation inputs:", {
      projectCost,
      countryCode,
      termMonths,
      taxRate,
      incentives: incentivesYear,
      estimatedConstructionStartDate,
      constructionPeriodLength,
      yearConstructionCompleted
    });
    // @ts-ignore
    depreciation = calculateEntireDepreciationSchedule({
      projectCost,
      countryCode,
      taxRate,
      incentives: incentivesYear,
      estimatedConstructionStartDate,
      constructionPeriodLength,
      yearConstructionCompleted
    });
    console_log("depreciation result:", depreciation);
    totalDepreciationSavings = depreciation.reduce((ac, cur) => ac + cur, 0);
    if (getDepreciationFromIncentives(incentivesYear).useInPayments) {
      costsWithAdditionalCostsAndSavings -=
        depreciation[0] * Math.pow(1 + interestRate / 12, -12);
      costsForCashOption -= depreciation[0];
    }
  }
  const usableIncentives = getIncentivesTotalUsableInPayments(incentivesYear);
  //future value of incentives after 12 months
  //lump payment on the project cost
  incentivesFutureValue =
    usableIncentives * Math.pow(1 + interestRate / 12, -12);

  costsForCashOption -= usableIncentives;
  costsWithAdditionalCostsAndSavings -= incentivesFutureValue;

  console_log("calculate other costs and savings result: ", {
    projectCost,
    projectCostMinusDownPayment,
    constructionPeriodInterest,
    totalDepreciationSavings
  });

  return {
    incentivesFutureValue,
    costsWithAdditionalCostsAndSavings,
    costsForCashOption,
    depreciation,
    constructionPeriodInterest,
    totalDepreciationSavings
  };
};
//eslint-disable-next-line
export const calculatePayability = ({
  projectCost,
  downPayment,
  costSavings,
  otherCostSavings,
  incentivesYear,
  projectType,
  utilityCostEscalator,
  percentageOfSavings,
  interestRate = 8.5 / 100,
  systemDegradationRate,
  OEMCost = 0,
  applyTaxes,
  state,
  countryCode,
  constructionPeriodLength,
  estimatedConstructionStartDate,
  progressPayments,
  yearConstructionCompleted
}) => {
  let {
    costsWithAdditionalCostsAndSavings
  } = calculateCostsAndOtherCostsAndSavings({
    projectCost,
    downPayment,
    termMonths: thresholdTermYears * 12,
    incentivesYear,
    applyTaxes,
    interestRate,
    constructionPeriodLength,
    estimatedConstructionStartDate,
    progressPayments,
    countryCode,
    state,
    yearConstructionCompleted
  });

  let isPaid = false,
    i,
    j;

  for (i = 0; i < thresholdTermYears && !isPaid; i++) {
    costsWithAdditionalCostsAndSavings += OEMCost;
    let monthlyPayment =
      (percentageOfSavings * (costSavings + otherCostSavings)) /
      // * (1 - taxRate)
      12;
    for (j = 1; j <= 12; j++) {
      costsWithAdditionalCostsAndSavings -=
        monthlyPayment -
        (interestRate / 12) * costsWithAdditionalCostsAndSavings;
      if (costsWithAdditionalCostsAndSavings <= 0) {
        isPaid = true;
        break;
      }
    }
    costSavings *=
      (1 + utilityCostEscalator) *
      (projectType === "Solar" ? 1 - systemDegradationRate : 1);
  }
  return { payable: isPaid, ...(isPaid ? { years: i - 1, months: j } : {}) };
};

/**
 *
 * @param {object} values
 * @param {number} values.projectCost
 * @param {number} values.downPayment
 * @param {number} values.costSavings
 * @param {number} values.otherCostSavings
 * @param {number} values.incentivesYear
 * @param {"Lighting" | "Solar"} values.projectType
 * @param {number} [values.utilityCostEscalator]
 * @param {boolean} [values.useEscalatingPayments]
 * @param {number} [values.percentageOfSavings]
 * @param {number} [values.OEMCost]
 * @param {boolean} [values.applyTaxes]
 * @param {number} [values.interestRate]
 * @param {number} [values.constructionPeriodLength]
 * @param {array} [values.progressPayments]
 * @param {string} [values.estimatedConstructionStartDate]
 * @param {number} [values.systemDegradationRate]
 * @param {number} [values.state]
 * @param {string} [values.countryCode]
 * @returns {number | Object}
 */
export const calculateTermYears = ({
  projectCost,
  downPayment = 0,
  costSavings,
  otherCostSavings,
  incentivesYear,
  projectType,
  utilityCostEscalator,
  useEscalatingPayments,
  percentageOfSavings,
  interestRate = 8.5 / 100,
  systemDegradationRate = projectType === "Solar" ? 0.5 / 100 : 0,
  constructionPeriodLength,
  estimatedConstructionStartDate,
  progressPayments,
  OEMCost = 0,
  applyTaxes = true,
  state,
  countryCode
}) => {
  let termYears = 2;
  const depreciationIncentive = getDepreciationFromIncentives(incentivesYear);
  const taxRate =
    depreciationIncentive?.federalTaxRate && depreciationIncentive?.stateTaxRate
      ? depreciationIncentive.federalTaxRate +
        depreciationIncentive.stateTaxRate
      : getTaxPercentage(state) / 100;

  if (useEscalatingPayments) {
    // @ts-ignore
    return calculatePayability({
      projectCost,
      downPayment,
      costSavings,
      otherCostSavings,
      incentivesYear,
      projectType,
      utilityCostEscalator,
      percentageOfSavings,
      interestRate,
      systemDegradationRate,
      OEMCost,
      applyTaxes,
      state,
      countryCode,
      constructionPeriodLength,
      estimatedConstructionStartDate,
      progressPayments
    });
  } else {
    let projectCostMinusDownPayment = projectCost - downPayment;
    let usableIncentives = getIncentivesTotalUsableInPayments(incentivesYear);
    if (usableIncentives > 0) {
      //future value of incentives after 12 months
      usableIncentives =
        usableIncentives * Math.pow(1 + interestRate / 12, -12);
      //lump payment on the project cost
      projectCostMinusDownPayment -= usableIncentives;
    }
    projectCostMinusDownPayment += getConstructionPeriodInterest({
      projectCostMinusDownPayment,
      constructionPeriodLength,
      progressPayments,
      interestRate
    });

    let depreciation;
    if (depreciationIncentive) {
      // @ts-ignore
      depreciation = calculateEntireDepreciationSchedule({
        projectCost,
        countryCode,
        taxRate,
        incentives: incentivesYear,
        estimatedConstructionStartDate,
        constructionPeriodLength
      });
    }
    while (termYears <= 10 || (projectType === "Solar" && termYears <= 20)) {
      const termMonths = termYears * 12;
      const monthlyPayment = pmt(
        interestRate / 12,
        termMonths,
        projectCostMinusDownPayment
      );
      const annualPayments = monthlyPayment * 12;
      if (
        (costSavings + otherCostSavings - OEMCost) * (1 - taxRate) +
          (depreciation
            ? depreciation[termYears - 2] - projectCostMinusDownPayment
            : 0) >
        annualPayments
      ) {
        break;
      }
      termYears++;
    }
    termYears = Math.min(termYears, projectType === "Solar" ? 20 : 10);
    return { payable: true, years: termYears };
  }
};

/**
 *
 * @param {("Solar" | "HVAC" | "Lighting" | "Other")} projectType
 * @returns {number} Asset lifetime
 */

export const getAssetLifetime = projectType =>
  ({
    Solar: 25,
    HVAC: 20,
    Lighting: 10
  }[projectType] ?? 15);

export const isDepreciationIncentive = incentiveName =>
  incentiveName?.toUpperCase() === "CCA" ||
  incentiveName?.toUpperCase() === "MACRS";

export const getDepreciationFromIncentives = incentives =>
  (incentives ?? []).find(i => isDepreciationIncentive(i.name));

export const getIncentivesTotalUsableInPayments = incentives =>
  (incentives ?? [])
    .filter(i => !isDepreciationIncentive(i.name) && i.useInPayments)
    .reduce((acc, incentive) => acc + incentive.amount, 0);

export const getIncentivesTotalNotUsableInPayments = incentives =>
  (incentives ?? [])
    .filter(i => !isDepreciationIncentive(i.name) && !i.useInPayments)
    .reduce((acc, incentive) => acc + incentive.amount, 0);

export const getIncentivesWithoutDepreciation = incentives =>
  (incentives ?? []).filter(i => !isDepreciationIncentive(i.name));

export const getIncentivesTotalWithoutDepreciation = incentives =>
  (incentives ?? []).reduce(
    (ac, i) => (!isDepreciationIncentive(i.name) ? ac + i.amount : ac),
    0
  );

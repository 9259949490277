import { Alert, Snackbar } from "@mui/material";
import React from "react";
import "./styles.scss";
import classNames from "classnames";

/**
 *
 * @param {string[]} props.messages,
 * @param {("error" | "info" | "success")} props.type
 * @param {string} [props.fieldLabel]
 * @param {number} [props.duration] - duration in ms. isOpen and setIsOpen must be defined for duration to take effect
 * @param {import("react").ReactComponentElement} [props.actionContent]
 * @returns
 */
function SnackBar({
  messages = [],
  type = "error",
  fieldLabel,
  duration,
  actionContent = "",
  isOpen = true,
  setIsOpen = () => {}
}) {
  const handleClose = (event, reason) =>
    reason === "timeout" && setIsOpen(false);
  return (
    !!messages?.length && (
      <Snackbar
        onClose={handleClose}
        autoHideDuration={duration}
        className={classNames({
          [`snackBar ${type}`]: true,
          withFieldLabel: !!fieldLabel
        })}
        open={!!messages?.length && isOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={type} action={actionContent}>
          {type !== "error" ? (
            <p className="snackBar-fieldName">{fieldLabel}</p>
          ) : messages?.length > 1 ? (
            <p>These items need your attention:</p>
          ) : (
            ""
          )}
          <ul
            className={classNames({
              "snackBar-messageList": true,
              "list-disc": type !== "info",
              "list-none": type === "info"
            })}
          >
            {messages.length !== 1 ? (
              messages.map((error, idx) => (
                <li key={`li-error-${idx}`}>
                  <span>{error}</span>
                </li>
              ))
            ) : (
              <span>{messages[0]}</span>
            )}
          </ul>
        </Alert>
      </Snackbar>
    )
  );
}

export default SnackBar;

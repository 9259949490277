import { Tooltip } from "@enpowered/ui";
import classNames from "classnames";
import React, { useState, useRef } from "react";
import "./styles.scss";
import IconTextButton from "../IconTextButton";
import CaptureClickOutsideComponent from "../CaptureClickOutsideComponent";
/**
 *
 * @param {object} props
 * @param {{label: JSX.Element, onClick: function, disabled: boolean}[]} props.options
 * @param {(option:{label?:JSX.Element}) => JSX.Element} [props.getOptionLabel]
 * @param {string} [props.ref]
 * @returns {JSX.Element}
 */

export const DropdownMenu = ({
  menuLabel,
  menuIcon,
  tooltipTitle,
  options,
  disabled = false,
  getOptionLabel = opt => opt.label,
  className
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const onOptionClicked = option => {
    setShowMenu(false);
    option.onClick();
  };

  return (
    <div className={classNames("dropdownMenu", className)}>
      <Tooltip placement="top" title={tooltipTitle}>
        <IconTextButton
          className="borderYellow1 round"
          label={menuLabel}
          icon={menuIcon}
          disabled={disabled}
          onClick={() => setShowMenu(!showMenu)}
        />
      </Tooltip>
      <CaptureClickOutsideComponent
        elementRef={menuRef}
        onClickOutsideElement={() => setShowMenu(false)}
      />
      {showMenu && (
        <div
          ref={menuRef}
          className="dropdownMenu-content mt-1 bg-white text-black z-10 width-fit-content absolute"
        >
          {options.map((option, index) => (
            <div
              key={`menuButton-option-${index}`}
              className={classNames({
                "dropdownMenu-option": true,
                "text-gray-400": option.disabled,
                "cursor-pointer hoverEffect": !!option.onClick
              })}
              onClick={
                option.onClick
                  ? () => !option.disabled && onOptionClicked(option)
                  : undefined
              }
            >
              {getOptionLabel(option)}
            </div>
          ))}
        </div>
      )}

      {options.map(option => {
        return option.displayAction && option.actionBody;
      })}
    </div>
  );
};
export default DropdownMenu;

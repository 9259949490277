import classNames from "classnames";
import React from "react";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import { ReactComponent as XIcon } from "assets/x.svg";
import "./styles.scss";
import { getMixpanel } from "../../services/mixpanel";

function SearchField({
  listItemName,
  searchString,
  setSearchString,
  className
}) {
  const mixpanel = getMixpanel();
  return (
    <div className={`searchField ${className ? className : ""}`}>
      <div className="relative w-0 h-0">
        <SearchIcon
          onClick={() => mixpanel.track("Search Icon Clicked")}
          className="searchIcon absolute"
        />
      </div>
      <input
        name="searchProject"
        id="searchProject"
        className={classNames({
          "searchInput pl-11 py-2 pr-9 placeholder-en-gray-300": true
        })}
        placeholder="Search by project name"
        value={searchString}
        onChange={e => {
          setSearchString(e.target.value);
        }}
        onBlur={() =>
          searchString?.length >= 3 &&
          mixpanel.track(`Search ${listItemName}`, { searchString })
        }
      />
      {searchString?.length > 0 && (
        <div className="xIconDiv">
          <button
            className="xButton absolute"
            onClick={() => setSearchString("")}
          >
            <XIcon width={15} height={15} />
          </button>
        </div>
      )}
    </div>
  );
}

export default SearchField;

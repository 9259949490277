export const color = {
  GREEN: "#99ca6d",
  RED: "#F45C5C",
  LIGHT_GREEN: "#B0DD8C",
  LIGHTER_GREEN: "#D8EEC6",
  YELLOW: "#ffd204",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  DARKEST_GRAY: "#00000029",
  DARK_GRAY: "#404040",
  GRAY2: "#343333",
  LIGHT_GRAY: "#F0F0F0",
  BLUE2: "#56C4E5",
  LIGHT_BLUE: "#9DD6E9",
  BLUE: "#1A2250"
};

import { DateTime } from "luxon";

export const handleHttpError = async response => {
  if (!response.ok) {
    throw { status: response.status, ...(await response.json()) };
  }
  return response;
};

export const verifyForErrors = (data, errorMessage) => {
  const clientErrorMessage = `Error: ${
    errorMessage ??
    `Error when sending request to the financing proposal server`
  } - ${DateTime.local().toISO()}`;
  const error = data?.error ?? data?.[0]?.error;
  if (error?.message) {
    console.error(
      (errorMessage ?? "Error when sending request to proposal service") +
        DateTime.utc().toISO(),
      error.message
    );
    throw `${clientErrorMessage} - ${error.message}`;
  } else if (error?.name) {
    console.error("Error when sending request to proposal service", error);
    throw clientErrorMessage;
  } else if (error?.body?.message) {
    console.error(
      "Error when sending request to proposal service",
      error?.body?.message
    );
    throw `${clientErrorMessage} - ${error.body.message}`;
  }
  return data?.result ?? data;
};

import { TextInput } from "@enpowered/ui";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import "./styles.scss";

const formatNumber = (value, decimalPlaces = 2) =>
  (+(+value).toFixed(decimalPlaces)).toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces
  });

const NumberInput = ({
  onChange,
  inputRef = useRef(null),
  prefix = "$",
  suffix,
  value,
  decimalPlaces = 2,
  min,
  max,
  size = "md",
  className,
  ...props
}) => {
  let validKeyPressed = false;
  const [active, setActive] = useState(false);

  const handler = (e, value) => {
    let number =
      value
        .match(/[0-9.]/g)
        ?.join("")
        .match(/[0-9]*\.?[0-9]*/)
        ?.join("") ?? "";

    if (number.length > 0) {
      let validateRangeNumber = +number;
      if (number.slice(-1) !== ".") {
        if (validateRangeNumber > max || validateRangeNumber < min) return;
        //numbers beyonde the decimal places length are ignored
        if (
          number.indexOf(".") >= 0 &&
          number.split(".")[1].length > decimalPlaces
        )
          return;
      }
      //ingore left zeros
      if (validateRangeNumber !== 0) {
        number = number.replace(/^0*/, "");
        if (number[0] === ".") number = `0${number}`;
      }
    }
    e.target.value = number;

    typeof onChange === "function" && onChange(e);
  };
  const handlePaste = e => {
    handler(e, e.clipboardData.getData("text"));
  };
  const handleChange = e => {
    if (!validKeyPressed) return;
    handler(e, e.target.value);
  };
  const handleKeyDown = event => {
    if (event.key === "." && event.target.value.indexOf(".") >= 0) {
      event.preventDefault();
    } else if (
      event.key.match(/[0-9.]/) ||
      event.key === "Backspace" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Tab" ||
      event.ctrlKey ||
      event.metaKey
    )
      validKeyPressed = true;
    else event.preventDefault();
  };

  return (
    <div
      className={className}
      onBlur={() => setActive(false)}
      onFocus={() => {
        setActive(true);
        inputRef.current.selectionStart = 0;
        inputRef.current.selectionEnd = inputRef.current.value.length;
      }}
    >
      <TextInput
        className={classNames({
          "inline-block": true,
          textInputSmall: size === "sm",
          textInputMedium: size === "md",
          textInputLarge: size === "lg",
          textInputNoLimit: size === "noLimit"
        })}
        onClick={e => {
          //select all
          inputRef.current.selectionStart = 0;
          inputRef.current.selectionEnd = e.target.value.length;
        }}
        ref={inputRef}
        value={`${prefix ? prefix + " " : ""}${
          active ? value : formatNumber(value, decimalPlaces)
        }`}
        onKeyDown={handleKeyDown}
        {...props}
        onChange={handleChange}
        onPaste={handlePaste}
      >
        {suffix && (
          <span
            className={classNames(
              "suffix relative w-0 h-0",
              suffix.length === 1
                ? "right-6"
                : suffix.length === 2
                  ? "right-7"
                  : "right-18"
            )}
          >
            <span className="absolute bottom-0">{suffix}</span>
          </span>
        )}
      </TextInput>
    </div>
  );
};

export default NumberInput;

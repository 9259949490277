import { noAuthFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 */
export const sendInvitations = async (users, organizationId) => {
  return Promise.all(
    users.map(user =>
      user.partnerCompanyId && user.partnerRepEmail
        ? noAuthFetch(`${apiRoot}/iam/unsecure-jsonrpc`, {
            body: JSON.stringify({
              jsonrcp: "2.0",
              id: `invite-user:${user.partnerRepEmail}`,
              method: "com.enpowered.iam:inviteUser",
              params: {
                email: user.partnerRepEmail,
                scope: [
                  `read,write:/organizations/${
                    organizationId ?? user.partnerCompanyId
                  }/*`
                ],
                ...(organizationId ? { organizationId } : {}),
                given_name: user.partnerRepName.split(" ")?.[0],
                family_name:
                  user.partnerRepName.split(" ")?.slice(1)?.join(" ") ?? "",
                acceptUrl: `${process.env.REACT_APP_IAM_UI_URL}/accept`
              }
            })
          })
            .then(res => res.json())
            //@ts-ignore
            .then(verifyForErrors)
            .then(result => {
              return result?.items ?? [];
            })
        : Promise.reject(
            `user ${user.partnerRepName}[${user.partnerRepId} email or company id missing: email: ${user.partnerRepEmail}, company: ${user.partnerCompanyId}]`
          )
    )
  );
};

import React, { useEffect } from "react";
import InfoTooltipLabel from "common/InfoTooltip";
import NumberInput from "common/NumberInput";
import AdvancedValuesSection from "../AdvancedValuesSection";

function CostsAndSavingsStep({
  formProps,
  errors,
  hasError,
  infoAssistOn,
  fieldFocused,
  setFieldFocused
}) {
  const { handleBlur, values: proposal, setFieldValue } = formProps;

  useEffect(() => {
    document.querySelector(`input[name='currentUtilityCost']`).focus();
    infoAssistOn && setFieldFocused("currentUtilityCost");
  }, []);
  const fieldProps = fieldName => ({
    name: fieldName,
    id: fieldName,
    value: proposal[fieldName],
    onChange: e => {
      setFieldValue(fieldName, e.target.value);
    },
    onBlur: e => infoAssistOn && setFieldFocused("") && handleBlur(e),
    onFocus: () => infoAssistOn && setFieldFocused(fieldName),
    valid: !hasError(errors, fieldName),
    disabled: proposal.isArchived
  });

  return (
    <div className="step">
      <div className="numberField">
        <InfoTooltipLabel
          tooltipMessage="The estimate of the customers current utility bill cost"
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "currentUtilityCost"}
        >
          Annual Utility Cost
        </InfoTooltipLabel>
        <NumberInput {...fieldProps("currentUtilityCost")} />
      </div>
      <div className="numberField">
        <InfoTooltipLabel
          tooltipMessage="The cost of maintaining the asset annually."
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "OEMCost"}
        >
          Annual O&M Cost
        </InfoTooltipLabel>
        <NumberInput {...fieldProps("OEMCost")} />
      </div>
      <div className="numberField">
        <InfoTooltipLabel
          tooltipMessage="Input the total monetary value of SRECs (Solar Renewable Energy Credits), RECs (Renewable Energy Certificates), and any other ancillary revenue streams that will offset the project costs."
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "otherCostSavings"}
        >
          Other Savings
        </InfoTooltipLabel>
        <NumberInput {...fieldProps("otherCostSavings")} />
      </div>
      <AdvancedValuesSection />
      <div className="numberField">
        <InfoTooltipLabel
          tooltipMessage="% rate at which the cost of energy grows annually for the customer"
          infoAssistOn={infoAssistOn}
          focused={fieldFocused === "utilityCostEscalator"}
        >
          Utility Cost Escalator
        </InfoTooltipLabel>
        <NumberInput
          size="sm"
          {...{
            ...fieldProps("utilityCostEscalator"),
            prefix: "",
            suffix: "%"
          }}
          max={100}
          decimalPlaces={2}
        />
      </div>
    </div>
  );
}

export default CostsAndSavingsStep;

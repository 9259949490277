import React, { useContext } from "react";
import { ProposalGen } from "@enpowered/payment-proposal-view";
import "@enpowered/payment-proposal-view/dist/index.css";
import DropdownMenu from "common/DropdownMenu";
import { adaptProposalInputsToAnnualValues } from "../FinancingScheduleView";
import DownloadIcon from "assets/download.svg";
import PdfIcon from "assets/pdf.svg";
import CsvIcon from "assets/csv.svg";
import { Spinner } from "@enpowered/ui";
import { getMixpanel } from "../../../services/mixpanel";
import { CA_STATES, US_STATES } from "../../../lib/constants";
import { VIEW_TYPES } from "../../FormNavigator";
import { isNewProject } from "../..";
import { UserContext } from "../../../auth/contexts/user";
import "./styles.scss";

/**
 *
 * @param {("export" | "changeView")} eventName
 * @param {("Financing Schedule" | "Collateral")} viewType
 * @returns {number}
 */

function CollateralView({
  proposalInputs,
  generateCsv,
  generatePdf,
  isGeneratingPdf,
  areRequiredInputsAvailable,
  isPayable,
  adaptAnnualValuesToGenerator
}) {
  let loggedUser = useContext(UserContext).user;
  const variables = adaptAnnualValuesToGenerator(
    proposalInputs,
    adaptProposalInputsToAnnualValues(proposalInputs)
  );
  const mixpanel = getMixpanel();
  const trackMixpanelEvents = viewType =>
    mixpanel.track("Proposal Exported", {
      Partner: proposalInputs?.partnerCompanyName,
      "Internal Request": !!loggedUser?.email,
      ["New Project"]: isNewProject(proposalInputs),
      ["View Type"]: viewType,
      ...(proposalInputs.projectType === "Solar"
        ? {
            "System Degradation Rate": `${
              proposalInputs.systemDegradationRate * 100
            }%`
          }
        : {}),
      Country: proposalInputs.countryCode === "CA" ? "Canada" : "United States",
      Region:
        (proposalInputs.countryCode === "CA" ? CA_STATES : US_STATES).find(
          state => proposalInputs.state === state.value
        )?.label ?? "",
      ...(proposalInputs.streetAddress
        ? { "Street Address": proposalInputs.streetAddress }
        : {}),
      ...(proposalInputs.unitNumber
        ? { "Unit number": proposalInputs.unitNumber }
        : {}),
      ...(proposalInputs.city ? { City: proposalInputs.city } : {}),
      ...(proposalInputs.postalCode
        ? { "Postal Code": proposalInputs.postalCode }
        : {}),
      "Project ID": proposalInputs.projectId,
      "Project Name": proposalInputs.projectName,
      "Customer Company Name": proposalInputs.customerCompanyName,
      "Project Type": proposalInputs.projectType,
      "Financing Option": proposalInputs.financingOption,
      "Project Description": proposalInputs.projectDescription,
      "Is the customer tax exempt?": proposalInputs.isTaxExempt,
      "Organization type": proposalInputs.organizationType,
      "Project Cost": `$ ${proposalInputs.projectCost}`,
      "Type of Savings": "Annual",
      "Annual Savings Amount": `$ ${proposalInputs.costSavings}`,
      Incentives: proposalInputs.incentives,
      "Use Escalating Payments": proposalInputs.useEscalatingPayments,
      ...(proposalInputs.useEscalatingPayments
        ? {
            "Percentage of Savings": `${
              proposalInputs.percentageOfSavings * 100
            }%`
          }
        : {
            "Term Length": `${proposalInputs.termLength} years`
          }),
      "Interest Rate": +proposalInputs.interestRate * 100,
      "Annual Utility Cost": `$ ${proposalInputs.currentUtilityCost}`,
      "Annual O&M Cost": `$ ${proposalInputs.OEMCost}`,
      "Annual Other Savings": `$ ${proposalInputs.otherCostSavings}`,
      "Utility Cost Escalator": `${proposalInputs.utilityCostEscalator * 100}%`,
      "Est. Construction Start": `${proposalInputs.estimatedConstructionStartDate}`,
      "Construction Period": `${proposalInputs.constructionPeriodLength} months`,
      "Down Payment": 0,
      "Progress payments": Object.entries(proposalInputs.progressPayments ?? {})
        .map(([month, percentage]) => `month ${month}: ${percentage}%`)
        .join(", ")
    });
  return (
    <div className="collateralView">
      <div className="collateralView-header">
        <h4>Proposal Preview</h4>
        <DropdownMenu
          tooltipTitle="Download"
          className="mr-6 ml-2"
          disabled={
            isGeneratingPdf || !areRequiredInputsAvailable || !isPayable
          }
          menuLabel={isGeneratingPdf ? "Generating..." : "Download Options"}
          menuIcon={isGeneratingPdf ? <Spinner /> : <img src={DownloadIcon} />}
          options={[
            {
              label: (
                <div>
                  <img className="mr-2 inline-block" src={CsvIcon} />
                  <div className="inline-block">Schedules as CSV</div>
                </div>
              ),
              onClick: () => {
                trackMixpanelEvents(VIEW_TYPES.FINANCING_SCHEDULE);
                generateCsv(
                  proposalInputs,
                  adaptProposalInputsToAnnualValues(proposalInputs)
                );
              }
            },
            {
              label: (
                <div>
                  <img className="mr-2 inline-block" src={PdfIcon} />
                  <div className="inline-block">Estimate as PDF</div>
                </div>
              ),
              onClick: () => {
                trackMixpanelEvents(VIEW_TYPES.COLLATERAL);
                generatePdf();
              }
            }
          ]}
        />
      </div>
      <ProposalGen variables={variables} isPreview={true} />
    </div>
  );
}

export default CollateralView;

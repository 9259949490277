import React, { useEffect, useState } from "react";
import ShareLink from "../../ShareLink";
import "./styles.scss";
// import * as Yup from "yup";
import classNames from "classnames";
import IconTextButton from "../../../common/IconTextButton";
import { getTaskIcon } from "../CreditAppForm/FinancingApplicationStep";
import { ReactComponent as HelpIcon } from "assets/help.svg";
import BigUploadFiles, {
  FILE_ERROR,
  FILE_ERROR_BEFORE_UPLOADED,
  FILE_UPLOADING,
  FILE_VALIDATED,
  FILE_VALIDATING
} from "../../BigUploadFiles";
import { DateTime } from "luxon";
import {
  deleteUploadedDocument,
  getDocumentCharacterizations,
  getSignedUploadUrl,
  uploadFile
} from "../../../services";
import { sleep } from "../../../utils/fetch";
import InformationPopover from "../../../common/InformationPopover";
import { ReactComponent as ShareIcon } from "assets/share.svg";
import useCreditAppTasks from "../../../hooks/useCreditAppTasks";

function DocumentUploadStep({
  creditApp,
  updateCreditApp,
  goToNextSection,
  goToPreviousSection,
  mixpanelTrack,
  setShowHelpPanel,
  // setErrorMessage,
  setErrorMessageAndOpen,
  setFieldValue
}) {
  const [isNewRequirementsPopoverOpen, setIsNewRequirementsPopoverOpen] =
    useState(false);

  const [isFileUploadingNow, setIsFileUploadingNow] = useState(false);

  const LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED =
    "newRequirementPopoverClosed";
  const newRequirementsAlreadyClosed =
    localStorage.getItem(LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED) ===
    "true";

  const { getRelevantCharacteristics, noTaxReturnRequired } =
    useCreditAppTasks();

  const {
    incomeStatement2,
    balanceSheet2,
    taxReturn2,
    incomeStatement1,
    balanceSheet1,
    taxReturn1
  } = getRelevantCharacteristics(creditApp?.financialDocuments);

  const currentYear = +DateTime.local().year;
  const getDocumentYears = doc => {
    const dateRange1 = doc.characteristics?.dateRange?.[0];
    const dateRange2 = doc.characteristics?.dateRange?.[1];
    return [
      ...(dateRange1?.indexOf(`${currentYear - 2}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear - 2}`) >= 0
        ? [currentYear - 2]
        : []),
      ...(dateRange1?.indexOf(`${currentYear - 1}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear - 1}`) >= 0
        ? [currentYear - 1]
        : []),
      ...(dateRange1?.indexOf(`${currentYear}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear}`) >= 0
        ? [currentYear]
        : [])
    ];
  };

  const uploadStep = (currentFiles, newFile, fileInfo, url) => {
    if (!url) {
      console.error("Failed to get Signed url");
      return Promise.reject("Failed to prepare document for upload");
    }
    fileInfo.status = FILE_UPLOADING;
    setFieldValue("financialDocuments", currentFiles);
    updateCreditApp({ financialDocuments: currentFiles });
    return uploadFile(url, newFile);
  };

  const checkIfNewRequirementsPopoverShouldOpen = () => {
    newRequirementsAlreadyClosed ||
    noTaxReturnRequired(creditApp.financialDocuments)
      ? isNewRequirementsPopoverOpen && setIsNewRequirementsPopoverOpen(false)
      : !isNewRequirementsPopoverOpen && setIsNewRequirementsPopoverOpen(true);
  };
  //confirm files that were still under validation
  useEffect(() => {
    (creditApp.financialDocuments ?? []).forEach(async doc => {
      if (doc.status === FILE_VALIDATING)
        await validateStep(creditApp.financialDocuments, doc.filename, doc, 0);
      else if (doc.status === FILE_UPLOADING && !isFileUploadingNow) {
        doc.status = FILE_ERROR_BEFORE_UPLOADED;
        setFieldValue("financialDocuments", creditApp.financialDocuments);
        updateCreditApp({ financialDocuments: creditApp.financialDocuments });
      }
    });
  }, [!!creditApp?.financialDocuments?.[0]]);

  useEffect(
    () => checkIfNewRequirementsPopoverShouldOpen(),
    [creditApp?.financialDocuments?.length]
  );

  const addValidationResultToFile = (fileInfo, currentFiles) =>
    getDocumentCharacterizations(creditApp.creditAppId, fileInfo.filename).then(
      filesCharacteristics => {
        console.info("file validated:", fileInfo.filename);
        fileInfo.status = FILE_VALIDATED;
        fileInfo.characteristics = filesCharacteristics?.[0];
        if (fileInfo.characteristics)
          fileInfo.characteristics.years = getDocumentYears(fileInfo);
        setFieldValue("financialDocuments", currentFiles);
        updateCreditApp({ financialDocuments: currentFiles });

        mixpanelTrack("Credit App Financial File Uploaded", {
          ...fileInfo.characteristics,
          filename: fileInfo.filename
        });
        checkIfNewRequirementsPopoverShouldOpen();
      }
    );

  const validateStep = async (
    currentFiles,
    filename,
    fileInfo,
    firstWait = 15000
  ) => {
    console.info("file uploaded:", filename);
    if (filename?.endsWith(".pdf")) {
      fileInfo.status = FILE_VALIDATING;
      setFieldValue("financialDocuments", currentFiles);
      updateCreditApp({ financialDocuments: currentFiles });
      let validationAttempt = 0;
      let validationError = null;
      do {
        try {
          await sleep(validationAttempt === 0 ? firstWait : 5000);
          await addValidationResultToFile(fileInfo, currentFiles);
        } catch (err) {
          if (err?.data?.extra.indexOf("key does not exist") >= 0)
            console.info(
              `Characteristics not yet ready (${15 + validationAttempt * 5}s)`,
              err
            );
          else {
            validationError = err;
          }
        } finally {
          if (validationAttempt === 3 || validationError) {
            const err = "";
            console.error(
              `Error validating file ${
                validationError ? "[202.3b]" : "[202.3c]"
              }`,
              validationError ?? err
            );
            fileInfo.status = FILE_ERROR;
            updateCreditApp({ financialDocuments: currentFiles });
            // setErrorMessageAndOpen(
            //   `Error validating financial file ${
            //     validationError ? "[202.3b]" : "[202.3c]"
            //   } - ${err?.message ?? err} (${DateTime.utc().toString()})`
            // );
          }
          validationAttempt++;
        }
      } while (
        validationAttempt <= 3 &&
        fileInfo.status !== FILE_VALIDATED &&
        !validationError
      );
    } else {
      console.info("file not pdf:", filename);
      fileInfo.status = FILE_VALIDATED;
      setFieldValue("financialDocuments", currentFiles);
      updateCreditApp({ financialDocuments: currentFiles });
    }
  };

  /**
   * @returns {function}
   */
  let onChangeFiles = async files => {
    //filter removed files
    let currentFiles = creditApp.financialDocuments;
    //get new files
    let newFiles = (files ?? []).reduce(
      (filesToAdd, newFile) =>
        !currentFiles.some(curFile => newFile.name === curFile.filename)
          ? [...filesToAdd, newFile]
          : filesToAdd,
      []
    );
    return Promise.allSettled(
      newFiles.map(newFile => {
        console.info("file added:", newFile.name);
        let fileInfo = {
          filename: newFile.name,
          status: FILE_UPLOADING
        };
        currentFiles.push(fileInfo);
        setIsFileUploadingNow(true);
        return setFieldValue("financialDocuments", currentFiles)
          .then(() => updateCreditApp({ financialDocuments: currentFiles }))
          .then(() =>
            getSignedUploadUrl(creditApp.creditAppId, fileInfo.filename)
          )
          .then(url => uploadStep(currentFiles, newFile, fileInfo, url))
          .then(async () => validateStep(currentFiles, newFile.name, fileInfo))
          .catch(err => {
            console.error("Error uploading/validating file [202.3a]", err);
            fileInfo.status = [FILE_VALIDATING, FILE_VALIDATED].includes(
              fileInfo.status
            )
              ? FILE_ERROR
              : FILE_ERROR_BEFORE_UPLOADED;
            setFieldValue("financialDocuments", currentFiles);
            updateCreditApp({ financialDocuments: currentFiles });
            setErrorMessageAndOpen(
              `Error uploading financial file [202.3a] - ${
                err?.message ?? err
              } (${DateTime.utc().toString()})`
            );
          })
          .finally(() => {
            setIsFileUploadingNow(false);
          });
      })
    );
  };

  const onRemoveFile = ({ filename, status }) => {
    (status === FILE_ERROR_BEFORE_UPLOADED
      ? Promise.resolve()
      : deleteUploadedDocument(creditApp.creditAppId, filename)
    )
      .then(() => {
        mixpanelTrack("Credit App Financial File Removed", {
          "File format": filename?.split(".")?.[1],
          "File name": filename
        });
        const updatedDocs = creditApp.financialDocuments.filter(
          doc => doc.filename !== filename
        );
        return setFieldValue("financialDocuments", updatedDocs).then(() =>
          updateCreditApp({ financialDocuments: updatedDocs })
        );
      })
      .catch(err => {
        setErrorMessageAndOpen(
          `Error on removing financial file [203.1] - ${
            err?.message ?? err
          } (${DateTime.utc().toString()})`
        );
      });
  };
  return (
    <div className="documentUploadStep flex flex-col w-full h-full px-8">
      <div className="flex w-full gap-2 justify-between items-center pb-2">
        <h2 className="">Supporting Documents</h2>
        <div className="flex gap-2">
          <IconTextButton
            icon={<HelpIcon className="getHelpIcon" />}
            label={"Get Help"}
            className="getHelpButton borderBlueLight round"
            onClick={() => {
              mixpanelTrack("Credit App Show Help Page");
              setShowHelpPanel(true);
            }}
          />
          <ShareLink
            buttonIcon={<ShareIcon />}
            buttonLabel={"Invite Contributors"}
            title={"Invite Contributors"}
            mixpanelTrack={mixpanelTrack}
            centered={false}
            onClick={() =>
              mixpanelTrack("Credit App Invite Contributors Clicked")
            }
            link={`${window.location.protocol}//${window.location.host}/creditApp/${creditApp?.creditAppId}`}
            description={
              "Share this link with a colleague who has the required information or can upload documents to help complete the application."
            }
          />
        </div>
      </div>
      <div className="flex w-full mt-4">
        <div className="w-full">
          <div className="flex w-full s18 gap-4 my-3">
            <div className="documentUploadStep-leftSide">
              <h5 className="w-full">
                To expedite your financing application, upload the required
                documentation.
              </h5>
              <div className="mx-auto my-0 w-full gap-2 grid grid-cols-2">
                <span>
                  {getTaskIcon(incomeStatement2)}
                  <span
                    className={
                      incomeStatement2
                        ? "font-semibold"
                        : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Income Statements 2023
                  </span>
                </span>
                <span>
                  {getTaskIcon(incomeStatement1)}
                  <span
                    className={
                      incomeStatement1
                        ? "font-semibold"
                        : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Income Statements 2022
                  </span>
                </span>
                <span>
                  {getTaskIcon(balanceSheet2)}
                  <span
                    className={
                      balanceSheet2 ? "font-semibold" : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Balance Sheet 2023
                  </span>
                </span>
                <span>
                  {getTaskIcon(balanceSheet1)}
                  <span
                    className={
                      balanceSheet1 ? "font-semibold" : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Balance Sheet 2022
                  </span>
                </span>
                <span
                  className={
                    (incomeStatement2 === "internal" ||
                    balanceSheet2 === "internal"
                      ? "fadein"
                      : "fadeout") + " flex items-center gap-1"
                  }
                >
                  {getTaskIcon(taxReturn2)}{" "}
                  <span
                    className={
                      taxReturn2 ? "font-semibold" : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Tax Returns 2023
                  </span>
                  <span
                    className={classNames(
                      "documentUploadStep-newRequirementTag",
                      !taxReturn2 ? "fadein" : "fadeout"
                    )}
                    onClick={() => setIsNewRequirementsPopoverOpen(true)}
                  >
                    NEW REQUIREMENT
                  </span>
                </span>
                <span
                  className={
                    (incomeStatement1 === "internal" ||
                    balanceSheet1 === "internal"
                      ? "fadein"
                      : "fadeout") + " flex items-center gap-1"
                  }
                >
                  {getTaskIcon(taxReturn1)}
                  <span
                    className={
                      taxReturn1 ? "font-semibold" : "text-primary-lightGray"
                    }
                  >
                    {" "}
                    Tax Returns 2022
                  </span>
                  <span
                    className={classNames(
                      "documentUploadStep-newRequirementTag",
                      !taxReturn1 ? "fadein" : "fadeout"
                    )}
                    onClick={() => setIsNewRequirementsPopoverOpen(true)}
                  >
                    NEW REQUIREMENT
                  </span>
                </span>
              </div>
              <InformationPopover
                isOpen={isNewRequirementsPopoverOpen}
                title={"New document requirements"}
                width={"100%"}
              >
                <div className="w-full flex flex-col gap-3">
                  <p>
                    Internally prepared financials must be paired with tax
                    returns
                  </p>
                  <div className="w-full">
                    <button
                      className="informationPopover-closeButton"
                      type="button"
                      onClick={() => {
                        setIsNewRequirementsPopoverOpen(false);
                        localStorage.setItem(
                          LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED,
                          true
                        );
                      }}
                    >
                      I understand
                    </button>
                  </div>
                </div>
              </InformationPopover>
            </div>
            <div className="documentUploadStep-rightSide">
              <BigUploadFiles
                id={"financialDocuments"}
                title={"Financials"}
                subtitle={"Files Uploaded"}
                files={creditApp.financialDocuments}
                addFiles={onChangeFiles}
                creditAppId={creditApp.creditAppId}
                mixpanelTrack={mixpanelTrack}
                onRemoveFile={onRemoveFile}
              ></BigUploadFiles>
            </div>
          </div>
          <div className="w-full flex justify-end mt-10 mb-7 gap-5 pr-10">
            <IconTextButton
              label={"Back"}
              onClick={goToPreviousSection}
              outlined
            />
            <IconTextButton
              label={"Next: Review Application"}
              onClick={goToNextSection}
              primary
              filled
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUploadStep;

import { authedFetch } from "../utils/fetch";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @returns {Promise<CompanyAccount[]>}
 */
export const getCompanyAccounts = async () => {
  return authedFetch(`${apiRoot}/auth/enpowered-accounts`)
    .then(res => res.json())
    .then(res =>
      res?.data?.map(({ id, attributes }) => ({ id, ...attributes }))
    );
};

/**
 *
 * @param {string} enpoweredAccountId
 * @returns {Promise<CompanyAccount>}
 */
export const getCompanyAccountById = async enpoweredAccountId => {
  return authedFetch(`${apiRoot}/auth/enpowered-accounts/${enpoweredAccountId}`)
    .then(res => res.json())
    .then(res => ({ id: res.data.id, ...res.data.attributes }));
};

/**
 *
 * @param {string} id
 * @param {Partial<CompanyAccount>} attributes
 * @returns {Promise<any>}
 */
export const updateCompanyAccount = async (id, attributes) => {
  return authedFetch(`${apiRoot}/auth/enpowered-accounts/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      data: {
        attributes
      }
    })
  }).then(res => res.json());
};

/**
 *
 * @param {Partial<CompanyAccount>} attributes
 * @returns {Promise<CompanyAccount>}
 */
export const createCompanyAccount = async attributes => {
  return authedFetch(`${apiRoot}/auth/enpowered-accounts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(attributes)
  }).then(res => res.json());
};

/**
 * @typedef {object} CompanyAccount
 * @property {string} id
 * @property {string} name
 * @property {{ locationSlug: string }[]} locations
 * @property {CompanyAccountAddress} businessAddress
 * @property {CompanyAccountAddress} billingAddress
 */

/**
 * @typedef {object} CompanyAccountAddress
 * @property {string} addressLine
 * @property {string} areaCode
 * @property {string} city
 * @property {string} country
 * @property {string} state
 */

import React, { useEffect, useRef, useState } from "react";

import PlusIcon from "assets/plus.svg";
import { ReactComponent as XIcon } from "assets/x.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";

import "./styles.scss";
import NumberInput from "common/NumberInput";
import InputTooltip from "common/InputTooltip";

const AddButton = ({ onClick, disabled }) => (
  <button
    type="button"
    className="addDownPayment"
    onClick={onClick}
    disabled={disabled}
  >
    <img className="inline-block" src={PlusIcon} />
    <span>Add</span>
  </button>
);

const FinalPayment = ({ percentage = 0 }) => (
  <div className="finalPayment">
    <span>Final payment</span>
    <div>{percentage} %</div>
  </div>
);

const DownPayment = ({ month, percentage, removeDownPayment, disabled }) => (
  <div className="downPayment">
    <span className="downPayment-month">{month}</span>
    <span className="downPayment-percentage">{percentage}%</span>
    <button disabled={disabled} type="button" onClick={removeDownPayment}>
      <XIcon />
    </button>
  </div>
);

const NewDownPayment = ({
  saveDownPayment,
  constructionPeriodLength,
  monthInputRef
}) => {
  const [month, setMonth] = useState("");
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    monthInputRef?.current?.focus();
  }, []);

  return (
    <div
      onKeyDown={event =>
        event.key === "Enter" && saveDownPayment(month, percentage)
      }
      className="newDownPayment"
    >
      <InputTooltip title="Month number within the construction period">
        <NumberInput
          inputRef={monthInputRef}
          prefix=""
          decimalPlaces={0}
          max={constructionPeriodLength}
          className="newDownPayment-month"
          value={month}
          onChange={e => setMonth(e.target.value)}
        />
      </InputTooltip>
      <InputTooltip title="Percentage of the project cost">
        <NumberInput
          prefix=""
          suffix="%"
          decimalPlaces={0}
          max={100}
          className="newDownPayment-percentage"
          value={percentage}
          onChange={e => setPercentage(e.target.value)}
        />
      </InputTooltip>
      <button type="button" onClick={() => saveDownPayment(month, percentage)}>
        <CheckIcon />
      </button>
    </div>
  );
};
function DownPayments({
  progressPayments = {},
  setDownPayments,
  constructionPeriodLength,
  disabled
}) {
  const [isNewDownPayment, setIsNewDownPayment] = useState(false);
  const monthInputRef = useRef(null);

  const removeDownPayment = month => {
    let newDownPayments = {
      ...progressPayments,
      final:
        parseInt(progressPayments.final) + parseInt(progressPayments[month])
    };
    delete newDownPayments[month];
    setDownPayments(newDownPayments);
  };
  const saveDownPayment = (month, percentage) => {
    if (month > 0 && percentage > 0 && percentage <= progressPayments.final) {
      setDownPayments({
        ...progressPayments,
        [month]: percentage,
        final:
          progressPayments.final - (percentage - (progressPayments[month] ?? 0))
      });
      setIsNewDownPayment(false);
    } else {
      //error TODO
    }
  };

  return (
    <div className="progressPayments">
      {Object.entries(progressPayments).map(
        ([month, percentage], idx) =>
          month !== "final" && (
            <DownPayment
              removeDownPayment={() => removeDownPayment(month)}
              key={`downPayment-${idx}`}
              month={month}
              percentage={percentage}
              disabled={disabled}
            />
          )
      )}
      {isNewDownPayment && (
        <NewDownPayment
          monthInputRef={monthInputRef}
          constructionPeriodLength={constructionPeriodLength}
          saveDownPayment={saveDownPayment}
        />
      )}
      {!disabled && (
        <AddButton
          onClick={() => {
            setIsNewDownPayment(progressPayments.final > 0);
          }}
          disabled={disabled}
        />
      )}
      <FinalPayment percentage={progressPayments.final} />
    </div>
  );
}

export default DownPayments;

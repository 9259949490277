import React from "react";
import { ProposalGen } from "./components";
import "@enpowered/ui/dist/index.css";
import "@enpowered/ui/dist/fonts.css";
import { createRoot } from "react-dom/client";
/**
 *
 * @returns {JSX.Element}
 */
function App() {
  const mount = (containerRef, props) => {
    console.log("Proposal props::", JSON.stringify(props));
    const container = document.getElementById(containerRef);
    const ErrorBoundary = props?.ErrorBoundary || React.Fragment;

    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <ProposalGen {...props} />
        </ErrorBoundary>
      </React.StrictMode>
    );
  };
  const getWindow = () => {
    /** @type {Partial<Window> & { [key: string]: any }} */
    const $window = typeof window === "undefined" ? {} : window;
    return $window;
  };

  if (typeof window === "undefined") {
    console.warn(
      "Payment Proposal view cannot be registered without a global window scope"
    );
    // @ts-ignore
    return {};
  }
  const $window = getWindow();
  $window.enpowered = $window.enpowered || {};
  $window.enpowered.PaymentProposalView =
    $window.enpowered.PaymentProposalView || {};
  $window.enpowered.PaymentProposalView.proposalGen = { mount };
  return $window.enpowered.PaymentProposalView.proposalGen;
}

export default App();

import React, { useEffect, useState } from "react";
import "./styles.scss";
import IconTextButton from "common/IconTextButton";
import Modal from "common/Modal";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { Label, Spinner, TextInput } from "@enpowered/ui";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { emitter } from "utils/emitter";
import { DateTime } from "luxon";

import { useMutation } from "react-query";
import {
  getMixpanel,
  postFinancingScenario,
  updateCreditApp
} from "../../services";
import { PROJECT_ID } from "../../ProposalForm";

export const ALMOST_DONE_FINANCIAL_INFO = "financialInfo";
export const ALMOST_DONE_ALL = "all";

/**
 *
 * @param {object} props
 * @param {function} props.mixpanelTrack
 * @param {object} props.setIsAlmostDonePanelOpen
 * @param {() => Array<{complete: boolean, label: string}>} props.financialInfoTasks
 * @param {() => Array<{complete: boolean, label: string}>} props.projectDetailsTasks
 * @returns
 */
function CreateProjectModal({
  isOpen,
  setIsOpen,
  partnerCompanyId,
  partnerCompanyName,
  setErrorMessageAndOpen
}) {
  useEffect(() => {
    if (isOpen) {
      document.getElementById("projectName").focus();
    }
  }, [isOpen]);
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("");
  const mixpanel = getMixpanel();
  const [projectNameInvalid, setProjectNameInvalid] = useState(false);
  const { mutate: create, isLoading } = useMutation(() =>
    postFinancingScenario({ projectName, partnerCompanyId })
      .then(result =>
        result?.financingScenarioId
          ? updateCreditApp(
              null,
              {
                submissionLabel: projectName,
                submissionSecondaryId: partnerCompanyId,
                financingScenarioId: result?.financingScenarioId,
                financingOption: "Loan"
              },
              { secured: true }
            )
              .then(res => {
                postFinancingScenario({
                  creditAppId: res?.creditAppId,
                  financingScenarioId: result.financingScenarioId,
                  projectName,
                  partnerCompanyId
                }).then(() => {
                  navigate(
                    `/project/${result.financingScenarioId}?creditAppId=${res?.creditAppId}`
                  );
                  mixpanel.track("New Project Created", {
                    [PROJECT_ID]: result?.financingScenarioId,
                    ["Project Name"]: projectName,
                    Partner: partnerCompanyName
                  });
                  emitter.emit("MainSnackBar", {
                    type: "success",
                    text: "Project created and added to list"
                  });
                });
              })
              .catch(err => {
                setErrorMessageAndOpen(
                  // emitter.emit(
                  {
                    type: "error",
                    text: `Failed to create credit app [102] ${
                      err?.message ?? err
                    } - (${DateTime.utc().toString()})`
                  }
                );
              })
          : Promise.reject("No id returned")
      )
      .catch(err => {
        setErrorMessageAndOpen(
          // emitter.emit(
          {
            type: "error",
            text: `Failed to create project [101] ${
              err?.message ?? err
            } - (${DateTime.utc().toString()})`
          }
        );
      })
  );
  return (
    <Modal
      centered={true}
      className={
        "createProjectModal flex justify-center items-center py-8 shadow-darkZ8 rounded-[8px] bg-white"
      }
      isOpen={isOpen}
    >
      <div className="flex flex-col w-full gap-[28px] px-[32px] s16">
        <h3 className="text-left">Create New Project</h3>
        <div className="">
          <Label>Project Name</Label>
          <TextInput
            name="projectName"
            id="projectName"
            className={classNames({
              "inputField s16": true
            })}
            valid={!projectNameInvalid}
            placeholder="Type project name"
            value={projectName}
            onChange={e => {
              projectNameInvalid &&
                e.target.value?.trim() !== "" &&
                setProjectNameInvalid(false);
              setProjectName(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-[16px] justify-end">
          <IconTextButton
            filled
            label="Cancel"
            onClick={() => {
              setProjectName("");
              setIsOpen(false);
            }}
          />
          <IconTextButton
            className="flex justify-center items-center w-[163px]"
            label={isLoading ? <Spinner /> : "Create Project"}
            icon={
              !isLoading && (
                <CheckIcon width={25} height={25} className="iconBlack" />
              )
            }
            filled
            primary
            onClick={
              () => {
                if (!projectName.trim()) {
                  emitter.emit("MainSnackBar", {
                    type: "error",
                    text: "Project Name is required"
                  });
                  setProjectNameInvalid(true);
                } else return create();
              }
              // navigate(`/project/new${location.search}`);
            }
          />
        </div>
      </div>
    </Modal>
  );
}

export default CreateProjectModal;

import React from "react";
import { Label } from "@enpowered/ui";

export const ReadOnlyFieldBlock = ({ className, label, value }) => {
  return (
    <div className={"inline-block fieldBlock " + (className ?? "")}>
      <Label>{label}</Label>
      <div className="value font-bold">{value}</div>
    </div>
  );
};

export const getStateLabel = (countryCode, state) =>
  countryCode === "US"
    ? US_STATES.find(s => s.value === state)?.label
    : CA_STATES.find(s => s.value === state)?.label;

export const CA_STATES = [
  {
    value: "AB",
    label: "Alberta",
    utilityEscalatorRate: 4.8
  },
  {
    value: "BC",
    label: "British Columbia",
    utilityEscalatorRate: 4.5
  },
  {
    value: "MB",
    label: "Manitoba",
    utilityEscalatorRate: 2.7
  },
  {
    value: "NB",
    label: "New Brunswick",
    utilityEscalatorRate: 1.5
  },
  {
    value: "NL",
    label: "Newfoundland and Labrador",
    utilityEscalatorRate: 0.9
  },
  {
    value: "NS",
    label: "Nova Scotia",
    utilityEscalatorRate: 3.5
  },
  {
    value: "ON",
    label: "Ontario",
    utilityEscalatorRate: 2.5
  },
  {
    value: "PE",
    label: "Prince Edward Island",
    utilityEscalatorRate: 2.1
  },
  {
    value: "QC",
    label: "Quebec",
    utilityEscalatorRate: 1.4
  },
  {
    value: "SK",
    label: "Saskatchewan",
    utilityEscalatorRate: 3.2
  },
  {
    value: "NT",
    label: "Northwest Territories",
    utilityEscalatorRate: 0
  },
  {
    value: "NU",
    label: "Nunavut",
    utilityEscalatorRate: 0
  },
  {
    value: "YT",
    label: "Yukon",
    utilityEscalatorRate: 0
  }
];

export const US_STATES = [
  {
    value: "AL",
    label: "Alabama",
    utilityEscalatorRate: 3.1
  },
  {
    value: "AK",
    label: "Alaska",
    utilityEscalatorRate: 3.6
  },
  {
    value: "AZ",
    label: "Arizona",
    utilityEscalatorRate: 2.1
  },
  {
    value: "AR",
    label: "Arkansas",
    utilityEscalatorRate: 2.5
  },
  {
    value: "CA",
    label: "California",
    utilityEscalatorRate: 2.4
  },
  {
    value: "CO",
    label: "Colorado",
    utilityEscalatorRate: 3.0
  },
  {
    value: "CT",
    label: "Connecticut",
    utilityEscalatorRate: 2.7
  },
  {
    value: "DE",
    label: "Delaware",
    utilityEscalatorRate: 1.4
  },
  {
    value: "DC",
    label: "District of Columbia",
    utilityEscalatorRate: 2.7
  },
  {
    value: "FL",
    label: "Florida",
    utilityEscalatorRate: 1.3
  },
  {
    value: "GA",
    label: "Georgia",
    utilityEscalatorRate: 2.5
  },
  {
    value: "HI",
    label: "Hawaii",
    utilityEscalatorRate: 3.9
  },
  {
    value: "ID",
    label: "Idaho",
    utilityEscalatorRate: 2.5
  },
  {
    value: "IL",
    label: "Illinois",
    utilityEscalatorRate: 1.5
  },
  {
    value: "IN",
    label: "Indiana",
    utilityEscalatorRate: 3.8
  },
  {
    value: "IA",
    label: "Iowa",
    utilityEscalatorRate: 2.4
  },
  {
    value: "KS",
    label: "Kansas",
    utilityEscalatorRate: 3.3
  },
  {
    value: "KY",
    label: "Kentucky",
    utilityEscalatorRate: 3.9
  },
  {
    value: "LA",
    label: "Louisiana",
    utilityEscalatorRate: 1.3
  },
  {
    value: "ME",
    label: "Maine",
    utilityEscalatorRate: 1.1
  },
  {
    value: "MD",
    label: "Maryland",
    utilityEscalatorRate: 2.0
  },
  {
    value: "MA",
    label: "Massachusetts",
    utilityEscalatorRate: 2.2
  },
  {
    value: "MI",
    label: "Michigan",
    utilityEscalatorRate: 2.5
  },
  {
    value: "MN",
    label: "Minnesota",
    utilityEscalatorRate: 3.4
  },
  {
    value: "MS",
    label: "Mississippi",
    utilityEscalatorRate: 2.1
  },
  {
    value: "MO",
    label: "Missouri",
    utilityEscalatorRate: 2.9
  },
  {
    value: "MT",
    label: "Montana",
    utilityEscalatorRate: 2.7
  },
  {
    value: "NE",
    label: "Nebraska",
    utilityEscalatorRate: 2.7
  },
  {
    value: "NV",
    label: "Nevada",
    utilityEscalatorRate: 0.0
  },
  {
    value: "NH",
    label: "New Hampshire",
    utilityEscalatorRate: 2.3
  },
  {
    value: "NJ",
    label: "New Jersey",
    utilityEscalatorRate: 1.4
  },
  {
    value: "NM",
    label: "New Mexico",
    utilityEscalatorRate: 2.1
  },
  {
    value: "NY",
    label: "New York",
    utilityEscalatorRate: 0.8
  },
  {
    value: "NC",
    label: "North Carolina",
    utilityEscalatorRate: 1.7
  },
  {
    value: "ND",
    label: "North Dakota",
    utilityEscalatorRate: 2.7
  },
  {
    value: "OH",
    label: "Ohio",
    utilityEscalatorRate: 1.3
  },
  {
    value: "OK",
    label: "Oklahoma",
    utilityEscalatorRate: 1.7
  },
  {
    value: "OR",
    label: "Oregon",
    utilityEscalatorRate: 2.3
  },
  {
    value: "PA",
    label: "Pennsylvania",
    utilityEscalatorRate: 0.4
  },
  {
    value: "PR",
    label: "Puerto Rico",
    utilityEscalatorRate: 1.0
  },
  {
    value: "RI",
    label: "Rhode Island",
    utilityEscalatorRate: 2.3
  },
  {
    value: "SC",
    label: "South Carolina",
    utilityEscalatorRate: 2.7
  },
  {
    value: "SD",
    label: "South Dakota",
    utilityEscalatorRate: 2.8
  },
  {
    value: "TN",
    label: "Tennessee",
    utilityEscalatorRate: 2.8
  },
  {
    value: "TX",
    label: "Texas",
    utilityEscalatorRate: 0.0
  },
  {
    value: "UT",
    label: "Utah",
    utilityEscalatorRate: 2.3
  },
  {
    value: "VT",
    label: "Vermont",
    utilityEscalatorRate: 2.2
  },
  {
    value: "VA",
    label: "Virginia",
    utilityEscalatorRate: 2.0
  },
  {
    value: "WA",
    label: "Washington",
    utilityEscalatorRate: 2.5
  },
  {
    value: "WV",
    label: "West Virginia",
    utilityEscalatorRate: 3.6
  },
  {
    value: "WI",
    label: "Wisconsin",
    utilityEscalatorRate: 2.7
  },
  {
    value: "WY",
    label: "Wyoming",
    utilityEscalatorRate: 3.1
  }
];

export const getUtilityEscalatorRate = state =>
  US_STATES.find(s => s.value === state)
    ? US_STATES.find(s => s.value === state).utilityEscalatorRate
    : CA_STATES.find(s => s.value === state)
      ? CA_STATES.find(s => s.value === state).utilityEscalatorRate
      : 3.6;

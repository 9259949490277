import { useContext } from "react";
import { UserContext } from "../contexts/user";
import decodeJWT from "jwt-decode";

/**
 *
 * @returns {(tokens: UserTokens) => Promise<any>}
 */
export const useResetTokens = () => {
  const { internalUserSingleSignOnInitialize } = useContext(UserContext);
  return async tokens => {
    const accessToken = tokens.access_token;
    const accessTokenClaims = decodeJWT(accessToken) || {};
    const userId = accessTokenClaims.sub;
    if (!userId) {
      throw new Error("AuthError: Invalid Tokens");
    }
    const clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;

    // const cognitoLastUserKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;
    // const cognitoAccessTokenStorageKey = `CognitoIdentityServiceProvider.${clientId}.${userId}.accessToken`;
    const cognitoIdTokenStorageKey = `CognitoIdentityServiceProvider.${clientId}.${userId}.idToken`;
    // const cognitoRefreshTokenStorageKey = `CognitoIdentityServiceProvider.${clientId}.${userId}.refreshToken`;

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("CognitoIdentityServiceProvider.")) {
        localStorage.removeItem(key);
      }
    }
    // localStorage.setItem(cognitoLastUserKey, userId);
    // localStorage.setItem(cognitoAccessTokenStorageKey, tokens.access_token);
    localStorage.setItem(cognitoIdTokenStorageKey, tokens.id_token);
    // localStorage.setItem(cognitoRefreshTokenStorageKey, tokens.refresh_token);

    await internalUserSingleSignOnInitialize(tokens.id_token);
  };
};

/**
 * @typedef {object} UserTokens
 * @property {string} access_token
 * @property {string} id_token
 * @property {string} refresh_token
 * @property {string} token_type
 * @property {number} expires_in
 */

import React, { useState } from "react";
import "./styles.scss";
import { Formik } from "formik";
import classNames from "classnames";
// import { emitter } from "utils/emitter";
import {
  // FILE_PREPARING_UPLOAD, // removed because of requirement https://www.notion.so/enpowered/Acceptance-Review-Financing-App-547f201578e74ca9bf5678e7f907ab12?pvs=4#d95f76fc0fd34c31a88ebda9b9b01c92
  FILE_VALIDATED,
  FILE_VALIDATING
} from "../../../common/UploadFiles";
import HelpPanel from "../HelpPanel";
import * as Yup from "yup";
import SnackBar from "../../../common/SnackBar";
import SubmitConfirmationModal from "../SubmitConfirmationModal";
import ShareLink from "../../ShareLink";
import { ReactComponent as ShareIcon } from "assets/share.svg";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import FinancingApplicationStep, {
  getRelevantCharacteristics,
  wereAllRelevantDocumentsUploaded
} from "./FinancingApplicationStep";
import { updateCreditApp } from "../../../services/CreditApps";
import ProjectDetailsStep from "./ProjectDetailsStep";
import IconTextButton from "../../../common/IconTextButton";
import { DateTime } from "luxon";
import {
  TAB_FINANCING_APPLICATION,
  TAB_PROJECT_DETAILS,
  tabs
} from "../CreditAppFeature";
import AlmostDonePanel, {
  ALMOST_DONE_ALL,
  ALMOST_DONE_FINANCIAL_INFO
} from "../AlmostDonePanel";
import CongratulationsPanel from "../CongratulationsPanel";

/**
 *
 * @param {*} props
 * @returns
 */
function CreditAppForm({
  creditAppData,
  mixpanelTrack = () => {},
  isPartnerView = false,
  proposal,
  loggedUser,
  onSubmissionConfirmed,
  currentTab,
  setCurrentTab = () => {},
  refetchCreditApp,
  partnerCompanyName
}) {
  const [showHelpPanel, setShowHelpPanel] = useState(false);
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(true);
  const [isCongratulationsOpened, setIsCongratulationsOpened] = useState(false);
  const [almostDonePanelTypeOpen, setAlmostDonePanelTypeOpen] = useState(null);

  const setErrorMessageAndOpen = messages => {
    setErrorMessage(messages);
    setIsMessageSnackBarOpen(true);
  };

  const financingApplicationSchema = Yup.object({
    businessLegalName: Yup.string().required("Business legal name is required"),
    yearsInBusiness: Yup.number()
      .typeError("Years in business must be a year number")
      .moreThan(0, "Years in business must be a positive number")
      .required("Years in business is required"),
    unsettledObligations: Yup.boolean().required(
      "Answer if there are any unsettled obligations is required"
    ),
    filledBankruptcy: Yup.boolean().required(
      "Answer if filled bankruptcy is required"
    ),
    canProvideGuarantee: Yup.boolean().required(
      "Answer if can provide guarantee is required"
    ),
    contactName: Yup.string().required("Contact name is required"),
    contactEmail: Yup.string()
      .email("Contact email must be a valid email address")
      .required("Contact email is required"),
    contactPhoneNumber: Yup.string(),
    countryCode: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    streetAddress: Yup.string().required("Street address is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required")
  });
  const projecDetailsSchema = Yup.object({
    creditAppProjectCost: Yup.number().required("Project cost is required"),
    firstYearAnnualSavings: Yup.number().required("Annual savings is required"),
    creditAppFinancingOption: Yup.string().required(
      "Financing option is required"
    ),
    creditAppProjectCountryCode: Yup.string().required("Country is required"),
    creditAppProjectState: Yup.string().required("State is required"),
    creditAppProjectCity: Yup.string().required("City is required"),
    creditAppProjectStreetAddress: Yup.string().required(
      "Street address is required"
    ),
    creditAppProjectPostalCode: Yup.string().required("Postal code is required")
  });

  const getBusinessDetailsTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.businessLegalName &&
        creditApp.yearsInBusiness &&
        creditApp.businessType &&
        ![null, undefined].includes(creditApp.unsettledObligations) &&
        ![null, undefined].includes(creditApp.filledBankruptcy) &&
        ![null, undefined].includes(creditApp.canProvideGuarantee)
      ),
      label: "Legal information"
    },
    {
      complete: !!(creditApp.contactName && creditApp.contactEmail),
      label: "Contact Information"
    },
    {
      complete: !!(
        creditApp.countryCode &&
        creditApp.state &&
        creditApp.streetAddress &&
        creditApp.city &&
        creditApp.postalCode
      ),
      label: "Business Address"
    }
  ];
  const getSupportingDocumentationTasks = (creditApp = creditAppData) => {
    const {
      incomeStatement2,
      incomeStatement1,
      balanceSheet2,
      balanceSheet1,
      taxReturn2,
      taxReturn1
    } = getRelevantCharacteristics(creditApp.financialDocuments);
    const year2 = 2023;
    const year1 = 2022;
    return [
      {
        complete: !!incomeStatement1,
        label: `Income Statement ${year1}`
      },
      {
        complete: !!balanceSheet1,
        label: `Balance Sheet ${year1}`
      },
      ...(incomeStatement1 === "internal" || balanceSheet1 === "internal"
        ? [
            {
              complete: !!taxReturn1,
              label: `Tax Return ${year1}`
            }
          ]
        : []),
      {
        complete: !!incomeStatement2,
        label: `Income Statement ${year2}`
      },
      {
        complete: !!balanceSheet2,
        label: `Balance Sheet ${year2}`
      },
      ...(incomeStatement2 === "internal" || balanceSheet2 === "internal"
        ? [
            {
              complete: !!taxReturn2,
              label: `Tax Return ${year2}`
            }
          ]
        : [])
    ];
  };
  const getFinancialInfoTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.businessLegalName &&
        creditApp.yearsInBusiness &&
        creditApp.businessType &&
        ![null, undefined].includes(creditApp.unsettledObligations) &&
        ![null, undefined].includes(creditApp.filledBankruptcy) &&
        ![null, undefined].includes(creditApp.canProvideGuarantee) &&
        creditApp.contactName &&
        creditApp.contactEmail &&
        creditApp.countryCode &&
        creditApp.state &&
        creditApp.streetAddress &&
        creditApp.city &&
        creditApp.postalCode
      ),
      label: "Customer details & project info completed"
    },
    {
      complete: !!wereAllRelevantDocumentsUploaded(
        creditApp.financialDocuments
      ),
      label: "Financial documents uploaded"
    }
  ];
  const getProjectDetailsTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.creditAppProjectCost &&
        creditApp.firstYearAnnualSavings &&
        creditApp.creditAppFinancingOption &&
        creditApp.creditAppProjectCountryCode &&
        creditApp.creditAppProjectState &&
        creditApp.creditAppProjectStreetAddress &&
        creditApp.creditAppProjectCity &&
        creditApp.creditAppProjectPostalCode
      ),
      label: "Project information completed"
    },
    {
      complete: !!creditApp.assetDocuments?.length,
      label: "Asset info updated"
    }
  ];

  const areAllTasksCompleted = values =>
    getFinancialInfoTasks(values).every(task => task.complete) &&
    getProjectDetailsTasks(values).every(task => task.complete);

  const areAllFinancialInfoTasksCompleted = values =>
    getFinancialInfoTasks(values).every(task => task.complete);

  const refetchAndSetIsAlmostDonePanelOpen = value => {
    refetchCreditApp();
    setAlmostDonePanelTypeOpen(value);
  };

  const onSubmitAnyway = () => setIsSubmissionModalOpen(true);

  const submit = values =>
    onSubmissionConfirmed(values)
      .then(() =>
        isPartnerView && !areAllTasksCompleted(values)
          ? refetchAndSetIsAlmostDonePanelOpen(ALMOST_DONE_ALL)
          : setIsCongratulationsOpened(true)
      )
      .catch(err =>
        setErrorMessageAndOpen(
          `Error when submitting application [204] - ${
            err?.message ?? err
          } (${DateTime.utc().toString()})`
        )
      );

  return almostDonePanelTypeOpen ? (
    <AlmostDonePanel
      getFinancialInfoTasks={getFinancialInfoTasks}
      getProjectDetailsTasks={getProjectDetailsTasks}
      getBusinessDetailsTasks={getBusinessDetailsTasks}
      getSupportingDocumentationTasks={getSupportingDocumentationTasks}
      setAlmostDonePanelTypeOpen={setAlmostDonePanelTypeOpen}
      type={almostDonePanelTypeOpen}
      onSubmit={onSubmitAnyway}
    />
  ) : isCongratulationsOpened ? (
    <CongratulationsPanel
      partnerCompanyName={partnerCompanyName}
      setIsCongratulationsOpened={value => {
        refetchCreditApp();
        setIsCongratulationsOpened(value);
      }}
      mixpanelTrack={mixpanelTrack}
      isPartnerView={isPartnerView}
    />
  ) : (
    <div
      className={classNames("creditApp-form flex justify-center items-center", {
        partnerView: isPartnerView
      })}
    >
      <div className="flex block w-full h-full">
        <div className="w-full">
          <Formik
            initialValues={{
              businessLegalName: "",
              creditAppProjectCost: proposal?.projectCost,
              firstYearAnnualSavings: proposal?.costSavings,
              creditAppFinancingOption: proposal?.financingOption,
              creditAppPreferredTermLength: proposal?.termLength,
              creditAppProjectCountryCode: proposal?.countryCode,
              creditAppProjectState: proposal?.state,
              creditAppProjectStreetAddress: proposal?.streetAddress,
              creditAppProjectUnitNumber: proposal?.unitNumber,
              creditAppProjectCity: proposal?.city,
              creditAppProjectPostalCode: proposal?.postalCode,
              downPayment: proposal?.downPayment,
              ...creditAppData,
              financialDocuments: creditAppData?.financialDocuments?.some(
                f => ![FILE_VALIDATED, FILE_VALIDATING].includes(f.status)
              )
                ? creditAppData.financialDocuments.filter(f =>
                    [FILE_VALIDATED, FILE_VALIDATING].includes(f.status)
                  )
                : creditAppData?.financialDocuments ?? [],
              otherDocuments: creditAppData?.otherDocuments?.some(
                f => ![FILE_VALIDATED, FILE_VALIDATING].includes(f.status)
              )
                ? creditAppData.otherDocuments.filter(f =>
                    [FILE_VALIDATED, FILE_VALIDATING].includes(f.status)
                  )
                : creditAppData?.otherDocuments ?? []
            }}
            validationSchema={
              currentTab === TAB_FINANCING_APPLICATION
                ? financingApplicationSchema
                : projecDetailsSchema
            }
            enableReinitialize
            validateOnChange={false}
            onSubmit={values => {
              currentTab === TAB_PROJECT_DETAILS
                ? submit(values)
                : areAllFinancialInfoTasksCompleted(values)
                  ? setIsSubmissionModalOpen(true)
                  : refetchAndSetIsAlmostDonePanelOpen(
                      ALMOST_DONE_FINANCIAL_INFO
                    );
            }}
          >
            {formik => {
              /**
               * @property {object} values
               * @property {CreditApp} formik.values
               */
              const { values, errors } = formik;

              const update = async (extraProperties = {}) => {
                const userEmail = isPartnerView
                  ? loggedUser?.email
                  : localStorage.getItem("currentUserEmail");
                if (values.creditAppId && userEmail)
                  return updateCreditApp(userEmail, {
                    ...values,
                    ...extraProperties,
                    isCustomerView: !isPartnerView,
                    formUpdated: currentTab
                  });
                throw new Error("Credit app id or user email not found");
              };

              const [isEditing, setIsEditing] = useState(
                isPartnerView ? false : true
              );
              return (
                <form onBlur={() => update()} className="h-full">
                  <SnackBar
                    messages={[
                      ...(Object.entries(errors)?.length
                        ? ["The fields in red require a valid input"]
                        : []),
                      ...(errorMessage?.length ? [errorMessage] : [])
                    ]}
                    setIsOpen={isOpen => {
                      !isOpen && setErrorMessage("");
                      setIsMessageSnackBarOpen(isOpen);
                    }}
                    isOpen={isMessageSnackBarOpen}
                    type={"error"}
                    duration={5000}
                  />
                  <SubmitConfirmationModal
                    isOpen={isSubmissionModalOpen}
                    setIsOpen={setIsSubmissionModalOpen}
                    onConfirmed={() => submit(values)}
                    mixpanelTrack={mixpanelTrack}
                    isSubmitted={values.isSubmitted}
                    financialDocuments={values.financialDocuments}
                  />
                  <HelpPanel
                    setIsOpen={value => {
                      !value && mixpanelTrack("Credit App Close Help Page");
                      setShowHelpPanel(value);
                    }}
                    open={showHelpPanel}
                  />
                  {isPartnerView && (
                    <div className="tabAndLine">
                      <div className="tabNavigator">
                        <ul>
                          {Object.entries(tabs).map(([id, label], idx) => (
                            <li
                              onClick={() => setCurrentTab(id)}
                              id={id}
                              className={classNames({
                                selected: currentTab === id
                              })}
                              key={`tabNavigatorOption-${idx}`}
                            >
                              {label}
                            </li>
                          ))}
                        </ul>
                        {currentTab === TAB_FINANCING_APPLICATION && (
                          <div className="actions">
                            <ShareLink
                              buttonIcon={<ShareIcon />}
                              buttonLabel={"Share with customer"}
                              mixpanelTrack={mixpanelTrack}
                              title={"Share Application with Customers"}
                              description="To save time, send a link to your customer so they can complete the financing application."
                              link={`${window.location.protocol}//${window.location.host}/creditApp/${values?.creditAppId}`}
                            />
                            <IconTextButton
                              className="w-28 justify-center round"
                              icon={
                                isEditing ? (
                                  <CheckIcon className="svgBlack" />
                                ) : (
                                  <EditIcon />
                                )
                              }
                              label={isEditing ? "Done" : "Edit"}
                              onClick={() => {
                                mixpanelTrack(
                                  isEditing
                                    ? "Done Editing Customer Financial Info"
                                    : "Edit Customer Financial Info"
                                );
                                setIsEditing(!isEditing);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="lineSeparator mt-4 absolute top-10"></div>
                      {currentTab === TAB_FINANCING_APPLICATION &&
                        values.dateLastModified &&
                        values.userEmailLastModified &&
                        DateTime.fromISO(values.dateLastModified).isValid && (
                          <div className="lastModifiedRibbon">
                            Last updated by{" "}
                            {<b>{values.userEmailLastModified}</b>} on{" "}
                            {DateTime.fromISO(values.dateLastModified).toFormat(
                              "MMM dd, yyyy, hh:mm a"
                            )}
                          </div>
                        )}
                    </div>
                  )}
                  {!isPartnerView ||
                  currentTab === TAB_FINANCING_APPLICATION ? (
                    <FinancingApplicationStep
                      formik={formik}
                      isEditing={isEditing}
                      mixpanelTrack={mixpanelTrack}
                      updateCreditApp={update}
                      setShowHelpPanel={setShowHelpPanel}
                      setErrorMessage={setErrorMessage}
                      setErrorMessageAndOpen={setErrorMessageAndOpen}
                      isPartnerView={isPartnerView}
                      setIsMessageSnackBarOpen={setIsMessageSnackBarOpen}
                    />
                  ) : (
                    <ProjectDetailsStep
                      formik={formik}
                      proposal={proposal}
                      mixpanelTrack={mixpanelTrack}
                      updateCreditApp={update}
                      setErrorMessageAndOpen={setErrorMessageAndOpen}
                      setIsMessageSnackBarOpen={setIsMessageSnackBarOpen}
                    />
                  )}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CreditAppForm;

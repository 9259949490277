import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.scss";

import Logo from "../assets/Logo_White_Bg.png";

/**
 *
 * @param {object} params
 * @param {object} params.variables
 * @param {string} [params.className]
 * @returns
 */
export const Header = ({ variables: { header }, className }) => (
  <div
    className={classNames(
      "header bg-white px-3 pt-1 flex justify-between",
      className
    )}
  >
    <div className="logo">
      <img className="h-24 mt-1px" src={Logo} alt="logo" />
    </div>
    <div>
      <div className="partnershipText">
        <span>In partnership with</span>
      </div>
      {header.partnerLogoUrl && (
        <div className="partnerLogoDiv">
          <img
            className="h-24"
            src={header.partnerLogoUrl}
            alt={header.partner + " logo"}
          />
        </div>
      )}
    </div>
  </div>
);

Header.propTypes = {
  variables: PropTypes.object,
  className: PropTypes.string
};

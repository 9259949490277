import React, { useState } from "react";
import IconTextButton from "../../common/IconTextButton";
import { ReactComponent as AddLinkIcon } from "assets/add-link.svg";
import "./styles.scss";
import { sleep } from "../../utils/fetch";
import classNames from "classnames";

function ShareLink({
  buttonLabel,
  buttonIcon,
  title,
  description,
  link,
  centered = false,
  onClick,
  mixpanelTrack
}) {
  const [isHidden, setIsHidden] = useState(true);
  const [copyLinkClicked, setCopyLinkClicked] = useState(false);
  return (
    <div className={classNames("shareLink", { centered })}>
      <IconTextButton
        className="borderYellow1 round whitespace-nowrap"
        icon={buttonIcon}
        label={buttonLabel}
        onClick={() => {
          setIsHidden(false);
          typeof onClick === "function" && onClick();
        }}
      />
      <div
        className={classNames("shareLink-window", { centered })}
        hidden={isHidden}
      >
        <div className="relative float-right mr-1 h-0 w-0">
          <button
            type="button"
            className="absolute"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setCopyLinkClicked(false);
              setIsHidden(true);
            }}
          >
            <span className="x">×</span>
          </button>
        </div>
        <h4 className="pt-3">{title}</h4>
        <p className="mt-2">{description}</p>
        <div className="flex my-5">
          <input
            className="shareLink-link w-3/4"
            value={link}
            onChange={() => {}}
          />
          <IconTextButton
            className="copyLinkButton filled primary w-1/4"
            icon={<AddLinkIcon />}
            label={copyLinkClicked ? "Link copied!" : "Copy Link"}
            onClick={async () => {
              navigator.clipboard.writeText(link);
              setCopyLinkClicked(true);
              mixpanelTrack("Financing Application Sharing Link Copied");
              await sleep(4000);
              setCopyLinkClicked(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ShareLink;

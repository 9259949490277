import React from "react";
import classNames from "classnames";
import "./styles.scss";
/**
 *
 * @param {string} props.className
 * @returns
 */
const LineSeparator = ({ className }) => (
  <div className={classNames("lineSeparator mx-8 mt-4", className)}></div>
);

export default LineSeparator;

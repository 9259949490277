import React, { useContext, useState } from "react";
import "./styles.scss";
import CreditAppForm from "../CreditAppForm";
import { getMixpanel } from "../../../services/mixpanel";
import { getCreditAppById } from "../../../services/CreditApps";
import { useQuery } from "react-query";
import { UserContext } from "../../../auth/contexts/user";
import { updateCreditApp } from "services/CreditApps";
import LoadingModal from "common/LoadingModal";

export const TAB_FINANCING_APPLICATION = "financingApplication";
export const TAB_PROJECT_DETAILS = "projectDetails";
export const tabs = {
  [TAB_FINANCING_APPLICATION]: "Customer Financial Info",
  [TAB_PROJECT_DETAILS]: "Project Details"
};

function CreditAppFeature({
  proposal
  // setMessage,
  // setFieldValue,
  // areRequiredFieldsFilled,
  // showRequiredFields,
  // postFinancingScenarioUpdates
}) {
  const { creditAppId, financingScenarioId } = proposal;
  const loggedUser = useContext(UserContext).user;
  const {
    data: creditApp = {},
    refetch,
    isLoading: isLoadingCreditApp,
    isError
  } = useQuery(
    `credit-app-${creditAppId}`,
    async () => creditAppId && getCreditAppById(creditAppId)
  );

  const mixpanelTrack = (msg, additionalProps) =>
    getMixpanel().track(msg, {
      "Application ID": creditAppId,
      "Project ID": financingScenarioId,
      ...additionalProps
    });

  const [currentTab, setCurrentTab] = useState("financingApplication");

  const onSubmissionConfirmed = values => {
    const userEmail = loggedUser?.email;
    return values.creditAppId && userEmail
      ? updateCreditApp(
          userEmail,
          {
            ...values,
            ...(currentTab === TAB_FINANCING_APPLICATION
              ? {
                  isSubmitted: true,
                  formSubmitted: TAB_FINANCING_APPLICATION
                }
              : {
                  isProjectDetailsSubmitted: true,
                  formSubmitted: TAB_PROJECT_DETAILS
                }),
            isCustomerView: false
          },
          { secured: true }
        )
      : Promise.reject("Credit app id or user email not found");
  };
  return (
    <div>
      <>
        {(isLoadingCreditApp || isError) && (
          <LoadingModal errorOnLoad={isError} />
        )}
        <CreditAppForm
          creditAppData={creditApp}
          mixpanelTrack={mixpanelTrack}
          isPartnerView={true}
          proposal={proposal}
          onSubmissionConfirmed={onSubmissionConfirmed}
          loggedUser={loggedUser}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
          refetchCreditApp={refetch}
        />
      </>
    </div>
  );
}

export default CreditAppFeature;

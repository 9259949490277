const wereAllRelevantDocumentsUploaded = docs => {
  const {
    incomeStatement2,
    incomeStatement1,
    balanceSheet2,
    balanceSheet1,
    taxReturn2,
    taxReturn1
  } = getRelevantCharacteristics(docs);
  return !!(
    (incomeStatement2 || balanceSheet2) &&
    ((incomeStatement2 !== "internal" && balanceSheet2 !== "internal") ||
      taxReturn2) &&
    (incomeStatement1 || balanceSheet1) &&
    ((incomeStatement1 !== "internal" && balanceSheet1 !== "internal") ||
      taxReturn1)
  );
};

const getRelevantCharacteristics = docs => {
  return (docs ?? []).reduce((ac, doc) => {
    const reviewLevel = doc.characteristics?.isInternal ? "internal" : "other";
    return {
      ...ac,
      ...(!doc.characteristics?.isIncomeStatement
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { incomeStatement2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { incomeStatement1: reviewLevel }
              : {})
          }),
      ...(!doc.characteristics?.isBalanceSheet
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { balanceSheet2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { balanceSheet1: reviewLevel }
              : {})
          }),
      ...(!(
        doc.characteristics?.isCorporationTaxReturn ||
        doc.characteristics?.isSoleProprietorshipTaxReturn ||
        doc.characteristics?.isPartnershipTaxReturn ||
        doc.characteristics?.isNonProfitTaxReturn
      )
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { taxReturn2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { taxReturn1: reviewLevel }
              : {})
          })
    };
  }, {});
};

const noTaxReturnRequired = docs => {
  const {
    incomeStatement2,
    balanceSheet2,
    taxReturn2,
    incomeStatement1,
    balanceSheet1,
    taxReturn1
  } = getRelevantCharacteristics(docs);
  return (
    ((incomeStatement2 !== "internal" && balanceSheet2 !== "internal") ||
      taxReturn2) &&
    ((incomeStatement1 !== "internal" && balanceSheet1 !== "internal") ||
      taxReturn1)
  );
};

const getRelevantCharacteristicsForMixpanel = financialDocs =>
  Object.entries(getRelevantCharacteristics(financialDocs ?? [])).reduce(
    (ac, [key, val]) => ({
      ...ac,
      [key]: true,
      ...(["incomeStatement1", "balanceSheet1"].includes(key) &&
      val === "internal" &&
      ac["taxReturn1"] === undefined
        ? { taxReturn1: false }
        : {}),
      ...(["incomeStatement2", "balanceSheet2"].includes(key) &&
      val === "internal" &&
      ac["taxReturn2"] === undefined
        ? { taxReturn2: false }
        : {})
    }),
    {
      incomeStatement1: false,
      incomeStatement2: false,
      balanceSheet1: false,
      balanceSheet2: false
    }
  );

const useCreditAppTasks = creditAppData => {
  const getBusinessDetailsTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.businessLegalName &&
        creditApp.yearsInBusiness &&
        creditApp.businessType &&
        ![null, undefined].includes(creditApp.unsettledObligations) &&
        ![null, undefined].includes(creditApp.filledBankruptcy) &&
        ![null, undefined].includes(creditApp.canProvideGuarantee)
      ),
      label: "Legal information"
    },
    {
      complete: !!(creditApp.contactName && creditApp.contactEmail),
      label: "Contact Information"
    },
    {
      complete: !!(
        creditApp.countryCode &&
        creditApp.state &&
        creditApp.streetAddress &&
        creditApp.city &&
        creditApp.postalCode
      ),
      label: "Business Address"
    }
  ];
  const getSupportingDocumentationTasks = (creditApp = creditAppData) => {
    const {
      incomeStatement2,
      incomeStatement1,
      balanceSheet2,
      balanceSheet1,
      taxReturn2,
      taxReturn1
    } = getRelevantCharacteristics(creditApp.financialDocuments);
    const year2 = 2023;
    const year1 = 2022;
    return [
      {
        complete: !!incomeStatement1,
        label: `Income Statement ${year1}`
      },
      {
        complete: !!balanceSheet1,
        label: `Balance Sheet ${year1}`
      },
      ...(incomeStatement1 === "internal" || balanceSheet1 === "internal"
        ? [
            {
              complete: !!taxReturn1,
              label: `Tax Return ${year1}`
            }
          ]
        : []),
      {
        complete: !!incomeStatement2,
        label: `Income Statement ${year2}`
      },
      {
        complete: !!balanceSheet2,
        label: `Balance Sheet ${year2}`
      },
      ...(incomeStatement2 === "internal" || balanceSheet2 === "internal"
        ? [
            {
              complete: !!taxReturn2,
              label: `Tax Return ${year2}`
            }
          ]
        : [])
    ];
  };
  const getFinancialInfoTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.businessLegalName &&
        creditApp.yearsInBusiness &&
        creditApp.businessType &&
        ![null, undefined].includes(creditApp.unsettledObligations) &&
        ![null, undefined].includes(creditApp.filledBankruptcy) &&
        ![null, undefined].includes(creditApp.canProvideGuarantee) &&
        creditApp.contactName &&
        creditApp.contactEmail &&
        creditApp.countryCode &&
        creditApp.state &&
        creditApp.streetAddress &&
        creditApp.city &&
        creditApp.postalCode
      ),
      label: "Customer details & project info completed"
    },
    {
      complete: !!wereAllRelevantDocumentsUploaded(
        creditApp.financialDocuments
      ),
      label: "Financial documents uploaded"
    }
  ];
  const getProjectDetailsTasks = (creditApp = creditAppData) => [
    {
      complete: !!(
        creditApp.creditAppProjectCost &&
        creditApp.firstYearAnnualSavings &&
        creditApp.creditAppFinancingOption &&
        creditApp.creditAppProjectCountryCode &&
        creditApp.creditAppProjectState &&
        creditApp.creditAppProjectStreetAddress &&
        creditApp.creditAppProjectCity &&
        creditApp.creditAppProjectPostalCode
      ),
      label: "Project information completed"
    },
    {
      complete: !!creditApp.assetDocuments?.length,
      label: "Asset info updated"
    }
  ];

  const areAllTasksCompleted = values =>
    getFinancialInfoTasks(values).every(task => task.complete) &&
    getProjectDetailsTasks(values).every(task => task.complete);

  const areAllFinancialInfoTasksCompleted = values =>
    getFinancialInfoTasks(values).every(task => task.complete);

  return {
    //with credit app data
    getBusinessDetailsTasks,
    getSupportingDocumentationTasks,
    getFinancialInfoTasks,
    getProjectDetailsTasks,
    areAllTasksCompleted,
    areAllFinancialInfoTasksCompleted,
    //without credit app data
    wereAllRelevantDocumentsUploaded,
    noTaxReturnRequired,
    getRelevantCharacteristics,
    getRelevantCharacteristicsForMixpanel
  };
};

export default useCreditAppTasks;

import React from "react";
import "./styles.scss";
import { LineSeparator } from "..";

function StartNow({ variables }) {
  return (
    <div className="mx-8">
      <div className="marketingTextDiv">
        <div className="title pb-2 italic font-light">
          Start now. Pay with your savings.
        </div>
        <div className="s16">
          Why pay over time, when you could save over time? With our Payments
          solution, you will start your energy projects, reduce your energy
          usage, and repay your assets as you generate savings - with no upfront
          costs.
        </div>
      </div>
      <LineSeparator />
      <div>
        <div className="projectInfo flex justify-between">
          {variables?.header?.partner && (
            <div>
              <span className="font-heavy">{variables.header.project}</span>{" "}
              <br />
              <span>{variables.header.location}</span>
            </div>
          )}
          {variables?.header?.project && (
            <div className="text-right">
              {variables.header.dateProcessed} <br />
              {variables.header.stateName ? variables.header.stateName : ``}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StartNow;

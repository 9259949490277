import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  PointElement,
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip
);

import { color } from "../utils/constants";
import { renderDollar } from "../utils/format";

const fontFamily = "source-sans-pro,sans-serif"; //"Gotham Black";

export const options = {
  animation: false,
  font: {
    family: fontFamily,
    size: 18
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        borderColor: "#767676",
        drawBorder: true,
        color: "#f0f0f0",
        drawOnChartArea: true,
        drawTicks: true
      },
      ticks: {
        padding: 10,
        color: color.GRAY2,
        font: {
          color: color.GRAY2,
          weight: "300",
          size: "15"
        }
      }
    },
    y: {
      stacked: true,
      grid: {
        borderColor: "#767676",
        drawBorder: false,
        color: "#f0f0f0",
        drawOnChartArea: true,
        drawTicks: false
      },
      ticks: {
        padding: 10,
        color: color.GRAY2,
        font: {
          color: color.GRAY2,
          weight: "300",
          size: "15"
        },
        callback: function(value) {
          return "$" + this.getLabelForValue(value);
        }
      }
    }
  },
  layout: {
    padding: {
      bottom: 60,
      left: 30,
      right: 40
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: context =>
          context.dataset.label &&
          `${context.dataset.label}: ${renderDollar(context.parsed.y)}`,
        title: context => {
          return `Year ${context[0].label}`;
        }
      }
    }
  }
};

const opportunityLayerPlugin = {
  id: "opportunity_bar_foreground_layer",
  afterDraw: chart => {
    const { ctx } = chart;
    ctx.save();

    const { width, height } = chart;
    const xAxis = chart.boxes[0];

    const { numYears, data, countryCode } = chart.config.data.datasets[0];
    const numColumns = data.length;
    const barWidth = xAxis.width / numColumns;

    // draw horizontal dash line
    const barElement = chart._metasets[0].data[1];
    const lineY = barElement.y;
    const lineX1 = barElement.x - barElement.width / 2;

    ctx.beginPath();
    ctx.setLineDash([5]);
    ctx.lineWidth = 2;
    ctx.moveTo(lineX1, lineY);
    // draw tooltips
    const { datasets } = chart.config.data;
    const x = 200;
    let y = 200;
    let isUtilityCostProvided = false;
    datasets.forEach(({ tooltip, backgroundColor, label }) => {
      if (tooltip) {
        renderTooltip(ctx, x, y, tooltip, backgroundColor);
        y -= 70;
      }
      if (label === "Utility Costs") isUtilityCostProvided = true;
    });

    // draw horizontal axis triangle markers
    const triangle_y = xAxis.top + 15;
    const triangle_x_1 = xAxis.left + barWidth;
    const triangle_x_2 = xAxis.left + (numYears + 1) * barWidth;
    ctx.font = `400 16px ${fontFamily}`;
    if (isUtilityCostProvided)
      renderTriangleMarker(ctx, triangle_x_1, triangle_y, "Project installed");
    renderTriangleMarker(ctx, triangle_x_2, triangle_y, "Payments completed");

    ctx.font = `700 18px ${fontFamily}`;
    renderCenteredText(ctx, width, height - 3, "Years");
    renderYAxisLabel(ctx, 25, height, countryCode === "CA" ? "CAD" : "USD");

    ctx.restore();
  }
};

const renderYAxisLabel = (ctx, dx, height, text) => {
  ctx.save();

  ctx.fillStyle = color.GRAY2;
  ctx.rotate(-Math.PI / 2);
  ctx.fillText(text, -(height + ctx.measureText(text).width - 50) / 2, dx);

  ctx.restore();
};

const renderCenteredText = (ctx, width, dy, text) => {
  ctx.save();

  const textMetrics = ctx.measureText(text);
  const dx = (width - textMetrics.width) / 2;
  ctx.fillStyle = color.GRAY2;
  ctx.fillText(text, dx, dy);

  ctx.restore();
};

const renderTriangleMarker = (ctx, x, y, text) => {
  ctx.save();

  const h = 20;
  const w = 16;
  const wh = w / 2;

  ctx.setLineDash([]);
  ctx.beginPath();
  ctx.moveTo(x - wh, y + h / 2);
  ctx.lineTo(x, y);
  ctx.lineTo(x + wh, y + h / 2);
  ctx.moveTo(x, y);
  ctx.lineTo(x, y + h);
  // ctx.closePath();
  ctx.stroke();

  const lines = text.split(" ");
  let dy = y + h + 24;
  lines.forEach(text => {
    const textMetrics = ctx.measureText(text);
    const fontHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;

    const dx = x - textMetrics.width / 2;
    ctx.fillStyle = color.GRAY2;
    ctx.font = "400 14px ";
    ctx.fillText(text, dx, dy);
    dy += fontHeight + 3;
  });

  ctx.restore();
};

const renderTooltip = (ctx, x, y, text, barColor) => {
  ctx.save();

  ctx.font = `bold 14px ${fontFamily}`;

  const textMetrics = ctx.measureText(text);
  const fontHeight =
    textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

  const canvas = {
    width: 30 + textMetrics.width + 10,
    height: 26
  };

  ctx.fillStyle = "white";

  const path = new Path2D();
  path.rect(x, y, canvas.width, canvas.height);
  // @ts-ignore
  //path.roundRect(x, y, canvas.width, canvas.height, 5);

  ctx.shadowColor = color.DARK_GRAY;
  ctx.shadowBlur = 6;
  ctx.shadowOffsetX = 4;
  ctx.shadowOffsetY = 4;

  ctx.lineWidth = 2;
  ctx.stroke(path);
  ctx.fillStyle = "white";
  ctx.fill(path);

  ctx.shadowBlur = 0;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;

  ctx.fillStyle = barColor;
  ctx.fillRect(x + 10, y + 7, 12, 12);

  ctx.fillStyle = color.GRAY2;
  ctx.fillText(text, x + 30, y + fontHeight + 3);

  ctx.restore();
};

/**
 *
 * @param {object} params
 * @param {object} params.data
 * @returns {JSX.Element}
 */
export const Legend = ({ data }) => (
  <div className="legend px-16 pt-3 pb-3 text-base font-semibold flex justify-around">
    {data.map(({ label, backgroundColor }, idx) => (
      <div key={idx} className="legendItem mx-3 flex items-center">
        <div
          className="mx-2 inline-block rounded-full"
          style={{ backgroundColor, width: 18, height: 18 }}
        ></div>
        <span className="text-sm">{label}</span>
      </div>
    ))}
  </div>
);

/**
 *
 * @param {object} params
 * @param {object} [params.variables]
 * @param {object} [params.calculations]
 * @param {string} [params.className]
 * @returns {JSX.Element}
 */
export const YourOpportunityTo = ({ variables, calculations, className }) => {
  const { projectType, utilityCostEscalator, countryCode } = variables.project;
  const { termYears, opportunity } = calculations;

  const chartData = {
    labels: opportunity.labels,
    datasets: [
      ...(opportunity.utilityCost?.length
        ? [
            {
              projectType,
              utilityCostEscalator,
              numYears: termYears,
              label: "Utility Payments",
              countryCode,
              data: opportunity.utilityCost,
              backgroundColor: color.BLUE,
              type: "bar"
            }
          ]
        : []),
      {
        label: "Customer Payments",
        countryCode,
        data: opportunity.customerPayments,
        backgroundColor: color.YELLOW,
        type: "bar"
      },
      {
        label: "Savings",
        data: opportunity.savings,
        backgroundColor: color.GREEN,
        type: "bar"
      },
      ...(Array.isArray(opportunity.otherSavings) &&
      opportunity.otherSavings.some(savings => savings > 0)
        ? [
            {
              label: "Other Savings",
              data: opportunity.otherSavings,
              backgroundColor: color.LIGHT_GREEN,
              type: "bar"
            }
          ]
        : []),
      ...(Array.isArray(opportunity.yearlyIncentives) &&
      opportunity.yearlyIncentives.some(yearValue => yearValue > 0)
        ? [
            {
              label: "Project Incentives",
              data: opportunity.yearlyIncentives,
              backgroundColor: color.LIGHT_BLUE,
              type: "bar"
            }
          ]
        : [])
    ]
  };

  return (
    <div className={classNames("your-opportunity-to", className)}>
      <div className="title pl-8 pb-4">Project Cash Flow Impact</div>
      <div className="pl-8">
        <Bar
          height={440}
          width={1000}
          // @ts-ignore
          options={options}
          //@ts-ignore
          data={chartData}
          plugins={[opportunityLayerPlugin]}
        />
      </div>
      <Legend data={chartData.datasets} />
    </div>
  );
};

YourOpportunityTo.propTypes = {
  calculations: PropTypes.object,
  className: PropTypes.string
};

export default YourOpportunityTo;

import React, { useState } from "react";
import "./styles.scss";
import "../../index.scss";
import { GoBackToProjectsList } from "../../ProposalForm/FormNavigator";
import { useQuery } from "react-query";
import { getArchivedFinancingScenarios } from "../../services/FinancingScenarios";
import SnackBar from "common/SnackBar";
import ArchiveTable from "./ArchiveTable";
import { MainFrame } from "common/TopBar";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {object} props
 * @param {function} props.onBackClicked
 * @param {string} props.partnerCompanyId
 * @param {object} [props.getArchivedFinancingScenarios]
 * @returns {React.Component}
 */
function ProjectsArchive({
  onBackClicked,
  partnerCompanyId,
  getArchivedFinancingScenarios
}) {
  const navigate = useNavigate();
  const {
    data: projectArchiveList,
    error,
    refetch
  } = useQuery("get-archive", () =>
    getArchivedFinancingScenarios(partnerCompanyId)
  );
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState({});

  return (
    <MainFrame>
      <div className="projectsPage">
        {(error || message.text) && (
          <SnackBar messages={[error ?? message.text]} type={message.type} />
        )}
        <div className="flex block w-full h-full py-2 ">
          <div className="w-full window">
            <div className="projectsPage-header">
              <div className="flex items-center s16">
                <GoBackToProjectsList
                  className="mr-3"
                  onBackClicked={onBackClicked}
                  navigate={navigate}
                />
                <h4 className="font-normal">{"Projects"}</h4>
                <h4>&nbsp;| Archive</h4>
              </div>
            </div>
            <ArchiveTable
              projectList={projectArchiveList}
              refetchArchive={refetch}
            />
          </div>
        </div>
      </div>
    </MainFrame>
  );
}

ProjectsArchive.defaultProps = {
  getArchivedFinancingScenarios
};

export default ProjectsArchive;

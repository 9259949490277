import React from "react";
import ShareLink from "../../ShareLink";
import { ReactComponent as SearchIcon } from "assets/search.svg";
import "./styles.scss";
import { Label, TextInput, AutocompleteInput, Checkbox } from "@enpowered/ui";
import * as Yup from "yup";
import classNames from "classnames";
import { CA_STATES, US_STATES } from "../../../lib/constants";
import { ReactComponent as LowerThanIcon } from "../../../assets/lower_than.svg";
import IconTextButton from "../../../common/IconTextButton";
import { ReactComponent as ShareIcon } from "assets/share.svg";
import { ReactComponent as RightArrowIcon } from "assets/right-arrow-big.svg";

function LegalBusinessNameSearch({ onClick, queryString, setQueryString }) {
  return (
    <div className="legalBusinessSearch flex relative">
      <input
        className="legalBusinessSearch-input"
        type="text"
        placeholder="Legal business name"
        value={queryString}
        onChange={e => setQueryString(e.target.value)}
        onKeyDown={e => e.key === "Enter" && onClick()}
      />
      <div className="legalBusinessSearch-button relative w-0 h-0">
        <div
          className="absolute w-12 h-12 right-0 cursor-pointer"
          onClick={onClick}
        >
          <SearchIcon className="absolute p-2 r-4" />
        </div>
      </div>
    </div>
  );
}

function BusinessInfoStep({
  creditApp,
  goToNextSection,
  mixpanelTrack,
  setBusinessCompanySearchModalOpen,
  queryString,
  setQueryString,
  errors,
  showBusinessInfoForm,
  setShowBusinessInfoForm,
  getWasAnyBusinessInfoAdded,
  setErrorMessage,
  setErrorMessageAndOpen,
  businessTypeIntermediateString,
  formik
}) {
  const { handleBlur, handleChange, setFieldValue, values, validateForm } =
    formik;

  const validateEmail = email =>
    Yup.object({
      ["Contact email"]: Yup.string()
        .email("Contact email must be a valid email address")
        .required("Contact email is required")
    })
      .validate({ ["Contact email"]: email })
      .then(() => setErrorMessage([]))
      .catch(err => {
        console.error("Error validating email", err);
        setErrorMessageAndOpen(err?.message ?? err);
      });

  const emptyOption = { value: "", label: "" };
  const countryCodes = [
    { label: "Canada", value: "CA" },
    { label: "United States", value: "US" }
  ];
  const provinces = values.countryCode === "CA" ? CA_STATES : US_STATES;
  const provincesServiceAddress =
    values.customerProjectAddressCountryCode === "CA" ? CA_STATES : US_STATES;

  const businessTypes = [
    { value: "Corporation", label: "Corporation" },
    {
      value: "Proprietorship",
      label: "Proprietorship"
    },
    { value: "Partnership", label: "Partnership" },
    {
      value: "Not-for-profit",
      label: "Not-for-profit"
    },
    { value: "LLC", label: "LLC" },
    { value: "Other", label: "Other" }
  ];
  return (
    <div className="businessInfoStep flex flex-col w-full h-full px-8">
      <div className="flex w-full gap-2 justify-between items-center pb-2">
        <h2 className="">Business Information</h2>
        <ShareLink
          buttonIcon={<ShareIcon />}
          buttonLabel={"Invite Contributors"}
          title={"Invite Contributors"}
          mixpanelTrack={mixpanelTrack}
          centered={false}
          onClick={() =>
            mixpanelTrack("Credit App Invite Contributors Clicked")
          }
          link={`${window.location.protocol}//${window.location.host}/creditApp/${creditApp?.creditAppId}`}
          description={
            "Share this link with a colleague who has the required information or can upload documents to help complete the application."
          }
        />
      </div>
      <div className="flex w-full">
        {!showBusinessInfoForm ? (
          <div className="w-full flex flex-col s18 gap-3 mb-4 mt-16 text-center items-center justify-center">
            <p>Start by searching for your legal business name</p>
            <LegalBusinessNameSearch
              onClick={() =>
                (queryString ?? "").trim()?.length > 0 &&
                setBusinessCompanySearchModalOpen(queryString)
              }
              queryString={queryString}
              setQueryString={setQueryString}
            />
            <p className="pt-1 text-center">Or</p>
            <p
              onClick={() => setShowBusinessInfoForm(true)}
              className="cursor-pointer flex gap-2 items-center text-center text-text-link underline"
            >
              {getWasAnyBusinessInfoAdded(values)
                ? "Return to Company Details"
                : "Enter details manually"}{" "}
              <RightArrowIcon />
            </p>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex w-full s18 gap-4 my-3">
              <div className="businessInfoStep-leftSide">
                <h4 className="font-normal">Company Details</h4>
                <div className="inputField long w-full">
                  <Label>Business Legal Name</Label>
                  <TextInput
                    name="businessLegalName"
                    id="businessLegalName"
                    placeholder="Type business legal name"
                    value={values.businessLegalName}
                    onChange={handleChange}
                    valid={!errors.businessLegalName}
                    onBlur={e => {
                      mixpanelTrack("Credit App Business Legal Name Entered");
                      errors.businessLegalName && handleBlur(e);
                    }}
                  />
                </div>

                <div className="flex gap-4">
                  {!values.businessTypeObtainedAutomatically &&
                    !businessTypeIntermediateString && (
                      <div className="inputField mb-4 w-5/6">
                        <Label>Business Type</Label>
                        <AutocompleteInput
                          className={classNames({
                            "selectField p-1 pr-8": true,
                            error: errors.businessType
                          })}
                          name="businessType"
                          id="businessType"
                          placeholder={`Type or select a type`}
                          autocompleteData={[emptyOption].concat(businessTypes)}
                          getOptionLabel={o => o.label ?? ""}
                          value={
                            businessTypes.find(
                              t => t.value === values?.businessType?.choice
                            ) ?? emptyOption
                          }
                          onSelected={selected => {
                            setFieldValue("businessType", {
                              choice: selected.value
                            });
                            mixpanelTrack("Credit App Business Type Entered", {
                              value: selected.value
                            });
                            errors.businessType &&
                              validateForm({
                                ...values,
                                businessType: { choice: selected.value }
                              });
                          }}
                          valid={!errors.businessType}
                        />
                      </div>
                    )}
                  {!values?.yearsInBusinessObtainedAutomatically && (
                    <div className="yearsInBusiness">
                      <Label>Years in business</Label>
                      <TextInput
                        name="yearsInBusiness"
                        id="yearsInBusiness"
                        placeholder="Years"
                        value={values.yearsInBusiness}
                        onChange={handleChange}
                        valid={!errors.yearsInBusiness}
                        onBlur={e => {
                          mixpanelTrack("Credit App Years in Business Entered");
                          errors.yearsInBusiness && handleBlur(e);
                        }}
                      />
                    </div>
                  )}
                </div>
                <h4 className="font-normal">Business Address</h4>
                <div className="flex gap-4">
                  <div className="inputField w-1/2">
                    <Label>Country</Label>
                    <AutocompleteInput
                      className={classNames({
                        "selectField p-1 pr-8": true,
                        error: errors.countryCode
                      })}
                      name="countryCode"
                      id="countryCode"
                      placeholder={`Type or select a country`}
                      autocompleteData={[emptyOption].concat(countryCodes)}
                      getOptionLabel={o => o.label ?? ""}
                      value={
                        countryCodes.find(
                          t => t.value === values?.countryCode
                        ) ?? emptyOption
                      }
                      onSelected={selected => {
                        setFieldValue("countryCode", selected.value);
                        setFieldValue("countryName", selected.label);
                        setFieldValue("state", "");
                        errors.countryCode &&
                          validateForm({
                            ...values,
                            countryCode: selected.value
                          });
                        mixpanelTrack("Credit App Business Country Selected");
                      }}
                      valid={!errors.countryCode}
                    />
                  </div>
                  <div className="inputField w-1/2">
                    <Label>State/Region/Province</Label>
                    <AutocompleteInput
                      className={classNames({
                        "selectField p-1 pr-8": true,
                        error: errors.state
                      })}
                      name="state"
                      id="state"
                      placeholder={`Type or select a ${
                        values.countryCode === "CA" ? "province" : "state"
                      }`}
                      autocompleteData={[emptyOption].concat(provinces)}
                      getOptionLabel={o => o.label ?? ""}
                      value={
                        provinces.find(t => t.value === values?.state) ??
                        provinces.find(t => t.value === "")
                      }
                      onSelected={selected => {
                        setFieldValue("state", selected.value);
                        errors.state &&
                          validateForm({
                            ...values,
                            state: selected.value
                          });
                        mixpanelTrack("Credit App Business Region Selected");
                      }}
                      valid={!errors.state}
                    />
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="inputField w-1/2">
                    <Label>Street Address</Label>
                    <TextInput
                      name="streetAddress"
                      id="streetAddress"
                      placeholder="Type the street address"
                      value={values.streetAddress}
                      valid={!errors.streetAddress}
                      onChange={target => {
                        handleChange(target);
                      }}
                      onBlur={e => {
                        mixpanelTrack(
                          "Credit App Business Street Address Entered"
                        );
                        errors.streetAddress && handleBlur(e);
                      }}
                    />
                  </div>
                  <div className="inputField short w-1/2">
                    <Label>Unit Number</Label>
                    <TextInput
                      name="unitNumber"
                      id="unitNumber"
                      placeholder="Type the unit number"
                      value={values.unitNumber}
                      onChange={target => {
                        handleChange(target);
                      }}
                      valid={!errors.unitNumber}
                      onBlur={() =>
                        mixpanelTrack("Credit App Business Unit Number Entered")
                      }
                    />
                  </div>
                </div>

                <div className="flex gap-4">
                  <div className="inputField city w-1/2">
                    <Label>City / Town</Label>
                    <TextInput
                      name="city"
                      id="city"
                      placeholder="City / Town"
                      value={values.city}
                      onChange={target => {
                        handleChange(target);
                      }}
                      valid={!errors.city}
                      onBlur={e => {
                        mixpanelTrack("Credit App Business City Entered");
                        errors.city && handleBlur(e);
                      }}
                    />
                  </div>
                  <div className="inputField short maxShort w-1/2">
                    <Label>Zip / Postal Code</Label>
                    <TextInput
                      name="postalCode"
                      id="postalCode"
                      placeholder={
                        values.countryCode === "US" ? "12345" : "A1B 2C3"
                      }
                      value={values.postalCode}
                      onChange={target => {
                        handleChange(target);
                      }}
                      valid={!errors.postalCode}
                      onBlur={e => {
                        mixpanelTrack(
                          "Credit App Business Postal Code Entered"
                        );
                        errors.postalCode && handleBlur(e);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center mt-2 gap-1">
                  <Checkbox
                    id="projectAddressIsDifferent"
                    name="projectAddressIsDifferent"
                    checked={values.projectAddressIsDifferent}
                    defaultChecked={false}
                    size="small"
                    className={classNames("mx-1 border align-middle", {
                      // "bg-white": selectedProjects.length !== projectList?.length,
                      "bg-en-yellow-100": values.projectAddressIsDifferent
                    })}
                    onChange={() =>
                      setFieldValue(
                        "projectAddressIsDifferent",
                        !values.projectAddressIsDifferent
                      )
                    }
                  />
                  <span>Project address is a different location</span>
                </div>

                {values.projectAddressIsDifferent && (
                  <>
                    <h4 className="mt-1 font-normal">Project Address</h4>
                    <div className="flex gap-4">
                      <div className="inputField w-1/2">
                        <Label>Country</Label>
                        <AutocompleteInput
                          className={classNames({
                            "selectField p-1 pr-8": true,
                            error: errors.customerProjectAddressCountryCode
                          })}
                          name="customerProjectAddressCountryCode"
                          id="customerProjectAddressCountryCode"
                          placeholder={`Type or select a country`}
                          autocompleteData={[emptyOption].concat(countryCodes)}
                          getOptionLabel={o => o.label ?? ""}
                          value={
                            countryCodes.find(
                              t =>
                                t.value ===
                                values?.customerProjectAddressCountryCode
                            ) ?? emptyOption
                          }
                          onSelected={selected => {
                            setFieldValue(
                              "customerProjectAddressCountryCode",
                              selected.value
                            );
                            setFieldValue("countryName", selected.label);
                            setFieldValue("customerProjectAddressState", "");
                            errors.customerProjectAddressCountryCode &&
                              validateForm({
                                ...values,
                                customerProjectAddressCountryCode:
                                  selected.value
                              });
                            mixpanelTrack(
                              "Credit App Business Country Selected"
                            );
                          }}
                          valid={!errors.customerProjectAddressCountryCode}
                        />
                      </div>
                      <div className="inputField w-1/2">
                        <Label>State/Region/Province</Label>
                        <AutocompleteInput
                          className={classNames({
                            "selectField p-1 pr-8": true,
                            error: errors.customerProjectAddressState
                          })}
                          name="customerProjectAddressState"
                          id="customerProjectAddressState"
                          placeholder={`Type or select a ${
                            values.customerProjectAddressCountryCode === "CA"
                              ? "province"
                              : "state"
                          }`}
                          autocompleteData={[emptyOption].concat(
                            provincesServiceAddress
                          )}
                          getOptionLabel={o => o.label ?? ""}
                          value={
                            provincesServiceAddress.find(
                              t =>
                                t.value === values?.customerProjectAddressState
                            ) ??
                            provincesServiceAddress.find(t => t.value === "")
                          }
                          onSelected={selected => {
                            setFieldValue(
                              "customerProjectAddressState",
                              selected.value
                            );
                            errors.customerProjectAddressState &&
                              validateForm({
                                ...values,
                                customerProjectAddressState: selected.value
                              });
                            mixpanelTrack(
                              "Credit App Business Region Selected"
                            );
                          }}
                          valid={!errors.customerProjectAddressState}
                        />
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="inputField w-1/2">
                        <Label>Street Address</Label>
                        <TextInput
                          name="customerProjectAddressStreetAddress"
                          id="customerProjectAddressStreetAddress"
                          placeholder="Type the street address"
                          value={values.customerProjectAddressStreetAddress}
                          valid={!errors.customerProjectAddressStreetAddress}
                          onChange={target => {
                            handleChange(target);
                          }}
                          onBlur={e => {
                            mixpanelTrack(
                              "Credit App Business Street Address Entered"
                            );
                            errors.customerProjectAddressStreetAddress &&
                              handleBlur(e);
                          }}
                        />
                      </div>
                      <div className="inputField short w-1/2">
                        <Label>Unit Number</Label>
                        <TextInput
                          name="customerProjectAddressUnitNumber"
                          id="customerProjectAddressUnitNumber"
                          placeholder="Type the unit number"
                          value={values.customerProjectAddressUnitNumber}
                          onChange={target => {
                            handleChange(target);
                          }}
                          valid={!errors.customerProjectAddressUnitNumber}
                          onBlur={() =>
                            mixpanelTrack(
                              "Credit App Business Unit Number Entered"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <div className="inputField city w-1/2">
                        <Label>City / Town</Label>
                        <TextInput
                          name="customerProjectAddressCity"
                          id="customerProjectAddressCity"
                          placeholder="City / Town"
                          value={values.customerProjectAddressCity}
                          onChange={target => {
                            handleChange(target);
                          }}
                          valid={!errors.customerProjectAddressCity}
                          onBlur={e => {
                            mixpanelTrack("Credit App Business City Entered");
                            errors.customerProjectAddressCity && handleBlur(e);
                          }}
                        />
                      </div>
                      <div className="inputField short maxShort w-1/2">
                        <Label>Zip / Postal Code</Label>
                        <TextInput
                          name="customerProjectAddressPostalCode"
                          id="customerProjectAddressPostalCode"
                          placeholder={
                            values.customerProjectAddressCountryCode === "US"
                              ? "12345"
                              : "A1B 2C3"
                          }
                          value={values.customerProjectAddressPostalCode}
                          onChange={target => {
                            handleChange(target);
                          }}
                          valid={!errors.customerProjectAddressPostalCode}
                          onBlur={e => {
                            mixpanelTrack(
                              "Credit App Business Postal Code Entered"
                            );
                            errors.customerProjectAddressPostalCode &&
                              handleBlur(e);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="businessInfoStep-rightSide">
                <h4 className="font-normal">Contact Information</h4>
                <div className="flex gap-4">
                  <div className="inputField w-2/3">
                    <Label>Contact Person</Label>
                    <TextInput
                      name="contactName"
                      id="contactName"
                      placeholder="Name"
                      value={values.contactName}
                      onChange={handleChange}
                      valid={!errors.contactName}
                      onBlur={e => {
                        mixpanelTrack("Credit App Contact Name Entered");
                        errors.contactName && handleBlur(e);
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="inputField w-2/3">
                    <Label>Contact Email Address</Label>
                    <TextInput
                      name="contactEmail"
                      id="contactEmail"
                      placeholder="name@email.com"
                      value={values.contactEmail}
                      onChange={e => {
                        setFieldValue(
                          "contactEmail",
                          e.target.value?.trim() ?? ""
                        );
                      }}
                      valid={!errors.contactEmail}
                      onBlur={e => {
                        mixpanelTrack(
                          "Credit App Contact Email Address Entered"
                        );
                        errors.contactEmail
                          ? handleBlur(e)
                          : validateEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="inputField w-2/3">
                    <Label>Phone number (optional)</Label>
                    <TextInput
                      name="contactPhoneNumber"
                      id="contactPhoneNumber"
                      placeholder="222-222-2222"
                      value={values.contactPhoneNumber}
                      onChange={handleChange}
                      valid={!errors.contactPhoneNumber}
                      onBlur={() =>
                        mixpanelTrack("Credit App Contact Phone Number Entered")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-end mt-10 mb-7 gap-5 pr-10">
              <div
                className="businessInfoStep-backToSearch"
                onClick={() => setShowBusinessInfoForm(false)}
              >
                <div className="flex items-center">
                  <LowerThanIcon className="" />
                  <div className="p-3">Back to Company Search</div>
                </div>
              </div>
              <IconTextButton
                label={"Next: Update Documents"}
                onClick={goToNextSection}
                primary
                filled
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BusinessInfoStep;

import "./styles.scss";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

/**
 * @template TValues
 * @template {string} TValueType
 * @typedef {TValues | (TValueType & { invalid?: never })} OneOf
 */

/**
 * @typedef {OneOf<'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' |
 * 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' |
 * 'right-end' | 'left' | 'left-start' | 'left-end', string>} TooltipPosition
 */

/**
 *
 * @param {object} props
 * @param {any} props.children
 * @param {TooltipPosition} props.placement
 * @param {string} props.title
 */
export const InputTooltip = ({ children, placement, title }) => {
  const [onFocus, setOnFocus] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    //@ts-ignore
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8]
        }
      },
      {
        name: "arrow",
        options: {
          element: arrowElement
        }
      }
    ]
  });

  const [destination, setDestination] = useState(
    document.querySelector("#tooltip-popper")
  );
  useEffect(() => {
    if (!destination) {
      const newDestination = document.createElement("div");
      newDestination.id = "tooltip-popper";
      document.querySelector("body").append(newDestination);
      setDestination(newDestination);
    }
  }, [destination]);

  return (
    <>
      <div
        ref={setReferenceElement}
        className="inline-block"
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
      >
        {children}
      </div>

      {onFocus &&
        ReactDOM.createPortal(
          <div
            className="bg-en-gray-600 text-white px-4 py-2 rounded text-center text-sm"
            role="tooltip"
            id="tooltip"
            ref={setPopperElement}
            style={{ ...styles.popper, maxWidth: 300 }}
            {...attributes.popper}
          >
            {title}
            <div ref={setArrowElement} id="arrow" data-popper-arrow></div>
          </div>,
          destination
        )}
    </>
  );
};

InputTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  placement: PropTypes.string
};

InputTooltip.defaultProps = {
  title: "NO TEXT",
  placement: "bottom"
};

export default InputTooltip;

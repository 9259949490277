import classNames from "classnames";
import React, { useRef, useState } from "react";
import CaptureClickOutsideComponent from "../../../common/CaptureClickOutsideComponent";
import { AutocompleteInput } from "@enpowered/ui";
import "./styles.scss";

export function SimpleAnswer({ label, selected, onClick }) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "w-full p-[16px] py-[6px] rounded-[4px] cursor-pointer",
        {
          "bg-secondary-darkBlue text-white shadow-light": selected,
          "text-primary-darkGray bg-white": !selected
        }
      )}
    >
      {label}
    </div>
  );
}

function ExplanationAnswer({ currentValue, setCurrentValue, onBlur, onFocus }) {
  return (
    <div>
      <textarea
        className="rounded-[4px] w-full py-2 px-3 resize-none outline-none placeholder:text-background-disabled"
        value={currentValue?.explanation}
        placeholder="Type reason..."
        onChange={e =>
          setCurrentValue({
            ...currentValue,
            explanation: e.target.value
          })
        }
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

function DropdownAnswer({ options, currentValue, setCurrentValue, title }) {
  const emptyOption = { value: "", label: "Select an option" };
  return (
    <div className="reviewQuestion">
      <AutocompleteInput
        className={classNames({
          "selectField p-1 pr-8": true
        })}
        name={title}
        id={title}
        // placeholder={`Type or select a country`}
        autocompleteData={[emptyOption].concat(options)}
        getOptionLabel={o => o.label ?? ""}
        value={
          options.find(t => t.value === currentValue?.choice) ?? emptyOption
        }
        onSelected={selected => {
          setCurrentValue({
            choice: selected.value,
            requireExplanation: !!selected.requireExplanation,
            explanation: ""
          });
        }}
      />
    </div>
  );
}

/**
 * @typedef {Object} Option
 * @property {string} label
 * @property {string} value
 * @property {boolean} [requireExplanation]
 *
 * @typedef {MultipleOptions | ExplanationOnly} Answer
 *
 * @typedef {Object} MultipleOptions
 * @property {string} choice
 * @property {string} [explanation]
 *
 * @typedef {Object} ExplanationOnly
 * @property {string} explanation
 *
 * @param {*} props
 * @param {string} props.title
 * @param {string} props.paragraph
 * @param {string} props.label
 * @param {Option[]} props.options
 * @param {string} props.errorMessage
 * @param {Answer} props.currentValue
 * @param {Function} props.setCurrentValue
 * @returns
 */
function ReviewQuestion({
  title,
  paragraph,
  label,
  options,
  errorMessage,
  currentValue,
  setCurrentValue,
  updateCreditApp,
  mixpanelTrackQuestion
}) {
  const [isFocused, setIsFocused] = useState(false);

  const questionRef = useRef(null);
  return (
    <>
      <CaptureClickOutsideComponent
        elementRef={questionRef}
        onClickOutsideElement={() => isFocused && setIsFocused(false)}
      />
      <div
        className={classNames(
          "bg-background-light p-[32px] rounded-[8px] flex items-start gap-[32px] w-full border-[2px]",
          {
            "border-transparent": !isFocused && !errorMessage,
            "border-error-16": !!errorMessage
            // "border-secondary-darkBlue": isFocused
          }
        )}
        ref={questionRef}
      >
        <div className="w-1/2 flex flex-col gap-[20px]">
          <p className="s24 font-semibold">
            {title}
            {errorMessage && (
              <div className="">
                <span className="bg-error-16 text-error s16 px-[8px] py-[4px] rounded-[4px]">
                  {errorMessage}
                </span>
              </div>
            )}
          </p>

          <p className="s16">{paragraph}</p>
        </div>
        <div className="bg-background w-1/2 flex flex-col gap-[12px] rounded-[8px] p-[16px]">
          {label && <p className="s18 font-semibold">{label}</p>}
          {(options ?? []).length < 5 ? (
            (options ?? []).map((option, i) => (
              <>
                {(options.length !== 1 || !option.requireExplanation) && (
                  <SimpleAnswer
                    key={i}
                    label={option.label}
                    selected={currentValue?.choice === option.value}
                    onClick={() => {
                      setIsFocused(true);
                      let newValue = {
                        ...currentValue,
                        choice: option.value,
                        requireExplanation: !!option.requireExplanation,
                        explanation: ""
                      };
                      setCurrentValue(newValue);
                      updateCreditApp(newValue);
                      mixpanelTrackQuestion(newValue);
                    }}
                  />
                )}
                {option.requireExplanation &&
                  (options.length === 1 ||
                    currentValue?.choice === option.value) && (
                    <ExplanationAnswer
                      currentValue={currentValue}
                      setCurrentValue={setCurrentValue}
                      onFocus={() => setIsFocused(true)}
                      onBlur={e => {
                        updateCreditApp(e.target.value);
                        setIsFocused(false);
                        mixpanelTrackQuestion(currentValue);
                      }}
                    />
                  )}
              </>
            ))
          ) : (
            <DropdownAnswer
              options={options}
              currentValue={currentValue}
              setCurrentValue={val => {
                setCurrentValue(val);
                updateCreditApp(val);
              }}
              title={title}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ReviewQuestion;

import { MIXPANEL_COMPANY_GROUP_ID, getMixpanel } from "../mixpanel";
import { getPartnerCompany } from "../PartnerCompany";
import { dealOwnerOptions } from "../../AdminPage";
import { getDealOwnerbyPartnerCompanyId } from "../DealOwners";
import { handleHttpError } from "../../utils/handleHttpError";

//used for internal users only
const apiRoot = process.env.REACT_APP_API_ROOT;
export const getMe = accessToken =>
  fetch(`${apiRoot}/auth/me`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })
    .then(handleHttpError)
    .then(res => res.json());

export const sendSignedInUserToMixpanel = async user => {
  const mixpanel = getMixpanel();
  mixpanel.identify(user.partnerRepId);
  mixpanel.people.set({
    $name: user.partnerRepName
  });
  mixpanel.people.set({
    partnerCompanyId: user.partnerCompanyId
  });
  mixpanel.people.set({
    $email: user.email
  });
  mixpanel.set_group(MIXPANEL_COMPANY_GROUP_ID, user.partnerCompanyId);

  return Promise.all([
    getPartnerCompany(user.partnerCompanyId),
    getDealOwnerbyPartnerCompanyId(user.partnerCompanyId)
  ])
    .then(([partnerCompany, dealOwner]) => {
      mixpanel.get_group(MIXPANEL_COMPANY_GROUP_ID, user.partnerCompanyId).set({
        $name: partnerCompany.partnerCompanyName,
        ...(dealOwner?.dealOwnerId
          ? {
              dealOwner: dealOwnerOptions.find(
                d => d.value === dealOwner?.dealOwnerId
              )?.label
            }
          : {})
      });
    })
    .catch(err => console.error(err))
    .finally(() => {
      mixpanel.track("Sign In");
    });
};

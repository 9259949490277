import React from "react";
import PropTypes from "prop-types";
import { renderDollar } from "../utils/format";
import "./styles.scss";
import {
  getIncentivesTotalWithoutDepreciation,
  isDepreciationIncentive
} from "../utils";

/**
 *
 * @param {object} params
 * @param {object} params.variables
 * @param {object} params.calculations
 * @param {string} [params.className]
 * @returns {JSX.Element}
 */
export const ProjectDetails = ({ variables, calculations }) => (
  <div className="px-8">
    <div className="title pt-5">Financing Details</div>
    <div className="project-details">
      <div className="flex justify-between mb-1px">
        <table className="mainTable">
          <tbody>
            <tr className="border-b border-dashed border-en-gray-200">
              <td>Project Cost</td>
              <td className="font-bold">
                {renderDollar(variables.project.projectCost, 2)}
              </td>
            </tr>
            <tr>
              <td>Financing Term</td>
              <td className="font-bold">
                {`${Math.trunc(calculations.termMonths / 12)} yr${
                  calculations.termMonths % 12 > 0
                    ? ` ${calculations.termMonths % 12} mo`
                    : ""
                }`}
              </td>
            </tr>
            {variables.project.downPayment > 0 && (
              <tr className="border-t border-dashed border-en-gray-200">
                <td>Down Payment</td>
                <td className="font-bold">
                  {renderDollar(variables.project.downPayment, 2)}
                </td>
              </tr>
            )}
            {!!variables.project.incentivesYear?.length && (
              <>
                <tr className="totalIncentivesRow border-t border-dashed border-en-gray-200">
                  <td>Project Incentives</td>
                  <td className="font-bold align-top">
                    {renderDollar(
                      getIncentivesTotalWithoutDepreciation(
                        variables.project.incentivesYear
                      ) + (calculations.totalDepreciationSavings ?? 0),
                      2
                    )}
                  </td>
                </tr>
                <tr className="projectIncentivesRow">
                  <td colSpan={2}>
                    <table className="incentivesTable">
                      {variables.project.incentivesYear
                        ?.sort((a, b) => a.name?.localeCompare(b.name))
                        ?.map((i, idx) => (
                          <tr key={`incentive-${idx}`}>
                            <td>
                              {i.name}
                              {i.useInPayments && <span>*</span>}
                            </td>
                            <td>
                              {renderDollar(
                                isDepreciationIncentive(i.name)
                                  ? calculations.totalDepreciationSavings
                                  : i.amount,
                                2
                              )}
                            </td>
                          </tr>
                        ))}
                      {variables.project.incentivesYear.some(
                        i => i.useInPayments
                      ) && (
                        <tr className="lumpSumDescriptionRow">
                          <td colSpan={2}>
                            *Applied as a lump sum payment on month{" "}
                            {variables.project.projectType === "Lighting"
                              ? 6
                              : 12}
                          </td>
                        </tr>
                      )}
                    </table>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <table className="mainTable">
          <tbody>
            {calculations.totalSavingsDuringTerm > 0 && (
              <tr className="border-b border-dashed border-en-gray-200">
                <td>Net Savings Over Term</td>
                <td className="font-bold">
                  {renderDollar(calculations.totalSavingsDuringTerm, 2)}
                </td>
              </tr>
            )}
            <tr className="border-dashed border-en-gray-200">
              <td>Monthly Savings</td>
              <td className="font-bold">
                {renderDollar(calculations.monthlySavings, 2)}
              </td>
            </tr>
            <tr className="border-t border-dashed border-en-gray-200">
              <td>Monthly Payment</td>
              <td className="font-bold">
                {renderDollar(calculations.monthlyPayment, 2)}
              </td>
            </tr>
            {calculations.constructionPeriodInterest > 0 && (
              <tr className="border-t border-dashed border-en-gray-200">
                <td>
                  Accrued Interest
                  <span className="s16">
                    <br />
                    Interest accrued over an est.{" "}
                    {variables.project.constructionPeriodLength} months of
                    construction
                  </span>
                </td>
                <td className="font-bold align-top">
                  {renderDollar(calculations.constructionPeriodInterest, 2)}
                </td>
              </tr>
            )}
            {calculations.monthsUntilBreakEven > 1 && (
              <tr className="border-t border-dashed border-en-gray-200">
                <td className="p-2">{"Payback Period"}</td>
                <td className="font-bold">
                  {`${Math.trunc(calculations.monthsUntilBreakEven / 12)} yr${
                    calculations.monthsUntilBreakEven % 12 > 0
                      ? ` ${calculations.monthsUntilBreakEven % 12} mo`
                      : ""
                  }`}
                </td>
              </tr>
            )}
            {calculations.monthlyNetCosts >= 0 && (
              <tr className="border-t border-dashed border-en-gray-200">
                <td className="p-2">{"Monthly Net Savings"}</td>
                <td className="font-bold">
                  {renderDollar(calculations.monthlyNetCosts, 2)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <p className="s12 mt-2">
        <span className="font-semibold">Note:</span> Estimates may change based
        on credit approval and tax liability. They do not represent final
        financing terms. Monthly savings shown are for year 1.
      </p>
    </div>
  </div>
);

ProjectDetails.propTypes = {
  variables: PropTypes.object,
  calculations: PropTypes.object,
  className: PropTypes.string
};

export default ProjectDetails;

import { authedFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @returns {Promise<Recipient[]>}
 */
export const getRepresentatives = async partnerCompanyId => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        method: "getRepresentativesByPartnerCompanyId",
        params: {
          partnerCompanyId
        }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(data => {
        const error = data?.error;
        if (error?.message) {
          throw error?.message;
        }

        const representativesList = data?.result?.items?.filter(
          partnerRep => !!partnerRep.partnerRepEmail
        );
        return representativesList ?? [];
      })
      .catch(error => {
        throw error;
      })
  );
};

export const getRepresentativeByEmail = async email => {
  return authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "getRepresentativeByEmail",
      params: { email }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .catch(error => {
      throw error;
    });
};

export const addPartnerRep = async ({
  partnerCompanyId,
  partnerRepName,
  partnerRepEmail
}) => {
  return authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "addPartnerRepresentative",
      params: {
        partnerCompanyId,
        partnerRepName,
        partnerRepEmail
      }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .catch(error => {
      throw error;
    });
};

import React from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllPartnerCompanies } from "../../../services/PartnerCompany";
import SnackBar from "common/SnackBar";

function CompaniesTable({ setPartnerCompanyId }) {
  const navigate = useNavigate();

  const { data: companiesList = [], error } = useQuery(
    "get-all-companies",
    () => getAllPartnerCompanies()
  );
  companiesList?.sort((a, b) =>
    a.partnerCompanyName.localeCompare(b.partnerCompanyName)
  );
  return (
    <div>
      {error && <SnackBar messages={[error]} type="error" />}
      <table className="companiesTable">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Company ID</th>
          </tr>
        </thead>
        <tbody>
          {companiesList?.map((company, i) => (
            <tr
              key={`companiesTable-${i}`}
              onClick={() => {
                setPartnerCompanyId(company.partnerCompanyId);
                navigate(`/company/${company.partnerCompanyId}`);
              }}
            >
              <td>{company.partnerCompanyName}</td>
              <td>{company.partnerCompanyId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CompaniesTable;

import { authedFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";
import { uploadFile } from "./PaymentProposal";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 * @param {object} props
 * @param {string} props.customerOrganizationName,
 * @param {string} props.customerOrganizationLogoFilename
 * @param {string} props.dealOwnerId
 * @param {File} props.file
 */
export const AddCustomerOrganizationWithDealOwner = async ({
  customerOrganizationId,
  partnerCompanyName: customerOrganizationName,
  partnerLogoFilename: customerOrganizationLogoFilename,
  file,
  dealOwnerId
}) => {
  return authedFetch(`${apiRoot}/arlo-engine/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "addCustomerOrganization",
      params: {
        customerOrganizationId,
        customerOrganizationName,
        customerOrganizationLogoFilename
      }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .then(({ customerOrganizationId, customerOrganizationLogoUploadUrl }) =>
      Promise.all([
        authedFetch(`${apiRoot}/arlo-engine/jsonrpc-secured`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            method: "addDealOwner",
            params: { dealOwnerId, customerOrganizationId }
          })
        }),
        uploadFile(customerOrganizationLogoUploadUrl, file)
      ]).then(
        ([dealOwnerRes, customerOrganizationLogoUploadResponse]) =>
          console.log({
            dealOwnerRes,
            customerOrganizationLogoUploadResponse
          }) ||
          dealOwnerRes
            .json()
            .then(body => body?.data)
            .then(verifyForErrors)
            .then(addDealOwnerResponse => ({
              customerOrganizationId,
              addDealOwnerResponse,
              customerOrganizationLogoUploadResponse
            }))
      )
    )
    .catch(error => {
      throw error;
    });
};

import { AutocompleteInput, Label, TextInput } from "@enpowered/ui";
import React, { useEffect, useState } from "react";
import RadioGroup from "common/RadioGroup";
import { CA_STATES, US_STATES } from "../../../../lib/constants";
import { ReactComponent as HelpIcon } from "assets/help.svg";
import IconTextButton from "common/IconTextButton";
import TaskIncompleteIcon from "assets/task-incomplete.png";
import { ReactComponent as TaskCompleteIcon } from "assets/task-complete.svg";
import { DateTime } from "luxon";
import { sleep } from "utils/fetch";
import {
  getDocumentCharacterizations,
  getSignedUploadUrl,
  uploadFile
} from "services/CreditApps";
import {
  FILE_ERROR,
  // FILE_PREPARING_UPLOAD, // removed because of requirement https://www.notion.so/enpowered/Acceptance-Review-Financing-App-547f201578e74ca9bf5678e7f907ab12?pvs=4#d95f76fc0fd34c31a88ebda9b9b01c92
  FILE_UPLOADING,
  FILE_VALIDATED,
  FILE_VALIDATING
} from "common/UploadFiles";
import classNames from "classnames";
import * as Yup from "yup";
import ShareLink from "../../../ShareLink";
import { deleteUploadedDocument } from "../../../../services/CreditApps";
import "./styles.scss";
import BigUploadFiles, {
  FILE_ERROR_BEFORE_UPLOADED
} from "../../../BigUploadFiles";
import InformationPopover from "../../../../common/InformationPopover";

export const getTaskIcon = isTaskCompleted => (
  <>
    {isTaskCompleted ? (
      <TaskCompleteIcon className="inline-block taskCompletedIcon" />
    ) : (
      <img className="inline-block" src={TaskIncompleteIcon} />
    )}{" "}
  </>
);

export const noTaxReturnRequired = docs => {
  const {
    incomeStatement2,
    balanceSheet2,
    taxReturn2,
    incomeStatement1,
    balanceSheet1,
    taxReturn1
  } = getRelevantCharacteristics(docs);
  return (
    ((incomeStatement2 !== "internal" && balanceSheet2 !== "internal") ||
      taxReturn2) &&
    ((incomeStatement1 !== "internal" && balanceSheet1 !== "internal") ||
      taxReturn1)
  );
};

export const wereAllRelevantDocumentsUploaded = docs => {
  const {
    incomeStatement2,
    incomeStatement1,
    balanceSheet2,
    balanceSheet1,
    taxReturn2,
    taxReturn1
  } = getRelevantCharacteristics(docs);
  return !!(
    (incomeStatement2 || balanceSheet2) &&
    ((incomeStatement2 !== "internal" && balanceSheet2 !== "internal") ||
      taxReturn2) &&
    (incomeStatement1 || balanceSheet1) &&
    ((incomeStatement1 !== "internal" && balanceSheet1 !== "internal") ||
      taxReturn1)
  );
};

export const getRelevantCharacteristics = docs => {
  return (docs ?? []).reduce((ac, doc) => {
    const reviewLevel = doc.characteristics?.isInternal ? "internal" : "other";
    return {
      ...ac,
      ...(!doc.characteristics?.isIncomeStatement
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { incomeStatement2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { incomeStatement1: reviewLevel }
              : {})
          }),
      ...(!doc.characteristics?.isBalanceSheet
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { balanceSheet2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { balanceSheet1: reviewLevel }
              : {})
          }),
      ...(!(
        doc.characteristics?.isCorporationTaxReturn ||
        doc.characteristics?.isSoleProprietorshipTaxReturn ||
        doc.characteristics?.isPartnershipTaxReturn ||
        doc.characteristics?.isNonProfitTaxReturn
      )
        ? {}
        : {
            ...(doc.characteristics.years?.includes(2023)
              ? { taxReturn2: reviewLevel }
              : {}),
            ...(doc.characteristics.years?.includes(2022)
              ? { taxReturn1: reviewLevel }
              : {})
          })
    };
  }, {});
};

export const getRelevantCharacteristicsForMixpanel = financialDocs =>
  Object.entries(getRelevantCharacteristics(financialDocs ?? [])).reduce(
    (ac, [key, val]) => ({
      ...ac,
      [key]: true,
      ...(["incomeStatement1", "balanceSheet1"].includes(key) &&
      val === "internal" &&
      ac["taxReturn1"] === undefined
        ? { taxReturn1: false }
        : {}),
      ...(["incomeStatement2", "balanceSheet2"].includes(key) &&
      val === "internal" &&
      ac["taxReturn2"] === undefined
        ? { taxReturn2: false }
        : {})
    }),
    {
      incomeStatement1: false,
      incomeStatement2: false,
      balanceSheet1: false,
      balanceSheet2: false
    }
  );

function FinancingApplicationStep({
  mixpanelTrack,
  formik,
  updateCreditApp,
  setShowHelpPanel,
  setErrorMessage,
  setErrorMessageAndOpen,
  setIsMessageSnackBarOpen,
  isPartnerView,
  isEditing
}) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    validateForm,
    setFieldValue,
    errors
  } = formik;
  const currentYear = +DateTime.local().year;
  const emptyOption = {};
  const countryCodes = [
    { label: "Canada", value: "CA" },
    { label: "United States", value: "US" }
  ];
  const provinces = [
    {
      label: `Choose ${values.countryCode === "CA" ? "Province" : "State"}`,
      value: ""
    }
  ].concat((values.countryCode === "CA" ? CA_STATES : US_STATES) ?? []);

  const LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED =
    "newRequirementPopoverClosed";
  const newRequirementsAlreadyClosed =
    localStorage.getItem(LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED) ===
    "true";

  const addValidationResultToFile = (fileInfo, currentFiles) =>
    getDocumentCharacterizations(values.creditAppId, fileInfo.filename).then(
      filesCharacteristics => {
        console.info("file validated:", fileInfo.filename);
        fileInfo.status = FILE_VALIDATED;
        fileInfo.characteristics = filesCharacteristics?.[0];
        if (fileInfo.characteristics)
          fileInfo.characteristics.years = getDocumentYears(fileInfo);
        setFieldValue("financialDocuments", currentFiles);
        updateCreditApp({ financialDocuments: currentFiles });

        mixpanelTrack("Credit App Financial File Uploaded", {
          ...fileInfo.characteristics,
          filename: fileInfo.filename
        });
        checkIfNewRequirementsPopoverShouldOpen();
      }
    );

  const [isNewRequirementsPopoverOpen, setIsNewRequirementsPopoverOpen] =
    useState(false);

  const checkIfNewRequirementsPopoverShouldOpen = () => {
    newRequirementsAlreadyClosed ||
    noTaxReturnRequired(values.financialDocuments)
      ? isNewRequirementsPopoverOpen && setIsNewRequirementsPopoverOpen(false)
      : !isNewRequirementsPopoverOpen && setIsNewRequirementsPopoverOpen(true);
  };
  //confirm files that were still under validation
  useEffect(() => {
    (values.financialDocuments ?? []).forEach(
      async doc =>
        doc.status === FILE_VALIDATING &&
        (await addValidationResultToFile(doc, values.financialDocuments))
    );
  }, [!!values.financialDocuments?.[0]]);

  useEffect(
    () => checkIfNewRequirementsPopoverShouldOpen(),
    [values.financialDocuments?.length]
  );

  const validateEmail = email =>
    Yup.object({
      ["Contact email"]: Yup.string()
        .email("Contact email must be a valid email address")
        .required("Contact email is required")
    })
      .validate({ ["Contact email"]: email })
      .then(() => setErrorMessage([]))
      .catch(err => {
        console.error("Error validating email", err);
        setErrorMessageAndOpen(err?.message ?? err);
      });

  const getDocumentYears = doc => {
    const dateRange1 = doc.characteristics?.dateRange?.[0];
    const dateRange2 = doc.characteristics?.dateRange?.[1];
    return [
      ...(dateRange1?.indexOf(`${currentYear - 2}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear - 2}`) >= 0
        ? [currentYear - 2]
        : []),
      ...(dateRange1?.indexOf(`${currentYear - 1}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear - 1}`) >= 0
        ? [currentYear - 1]
        : []),
      ...(dateRange1?.indexOf(`${currentYear}`) >= 0 ||
      dateRange2?.indexOf(`${currentYear}`) >= 0
        ? [currentYear]
        : [])
    ];
  };

  const uploadStep = (currentFiles, newFile, fileInfo, url) => {
    if (!url) {
      console.error("Failed to get Signed url");
      return Promise.reject("Failed to prepare document for upload");
    }
    fileInfo.status = FILE_UPLOADING;
    setFieldValue("financialDocuments", currentFiles);
    updateCreditApp({ financialDocuments: currentFiles });
    return uploadFile(url, newFile);
  };

  const validateStep = async (currentFiles, newFile, fileInfo) => {
    console.info("file uploaded:", newFile.name);
    if (newFile.name?.endsWith(".pdf")) {
      fileInfo.status = FILE_VALIDATING;
      setFieldValue("financialDocuments", currentFiles);
      updateCreditApp({ financialDocuments: currentFiles });
      let validationAttempt = 0;
      let validationError = null;
      do {
        try {
          await sleep(validationAttempt === 0 ? 15000 : 5000);
          await addValidationResultToFile(fileInfo, currentFiles);
        } catch (err) {
          if (err?.data?.extra.indexOf("key does not exist") >= 0)
            console.info(
              `Characteristics not yet ready (${15 + validationAttempt * 5}s)`,
              err
            );
          else {
            validationError = err;
          }
        } finally {
          if (validationAttempt === 3 || validationError) {
            const err = "";
            console.error("Error validating file", validationError ?? err);
            fileInfo.status = FILE_ERROR;
            setFieldValue("financialDocuments", currentFiles);
            updateCreditApp({ financialDocuments: currentFiles });
            setErrorMessageAndOpen(
              `Error validating financial file ${
                validationError ? "[202.1b]" : "[202.1c]"
              } - ${err?.message ?? err} (${DateTime.utc().toString()})`
            );
          }
          validationAttempt++;
        }
      } while (
        validationAttempt <= 3 &&
        fileInfo.status !== FILE_VALIDATED &&
        !validationError
      );
    } else {
      console.info("file not pdf:", newFile.name);
      fileInfo.status = FILE_VALIDATED;
      setFieldValue("financialDocuments", currentFiles);
      updateCreditApp({ financialDocuments: currentFiles });
    }
  };

  /**
   * @returns {function}
   */
  let onChangeFiles = async files => {
    //filter removed files
    let currentFiles = values.financialDocuments;
    //get new files
    let newFiles = (files ?? []).reduce(
      (filesToAdd, newFile) =>
        !currentFiles.some(curFile => newFile.name === curFile.filename)
          ? [...filesToAdd, newFile]
          : filesToAdd,
      []
    );
    return Promise.allSettled(
      newFiles.map(newFile => {
        console.info("file added:", newFile.name);
        let fileInfo = {
          filename: newFile.name,
          status: FILE_UPLOADING
        };
        currentFiles.push(fileInfo);
        return setFieldValue("financialDocuments", currentFiles)
          .then(() => updateCreditApp({ financialDocuments: currentFiles }))
          .then(() => getSignedUploadUrl(values.creditAppId, fileInfo.filename))
          .then(url => uploadStep(currentFiles, newFile, fileInfo, url))
          .then(async () => validateStep(currentFiles, newFile, fileInfo))
          .catch(err => {
            console.error("Error uploading/validating file", err);
            fileInfo.status = [FILE_VALIDATING, FILE_VALIDATED].includes(
              fileInfo.status
            )
              ? FILE_ERROR
              : FILE_ERROR_BEFORE_UPLOADED;
            setFieldValue("financialDocuments", currentFiles);
            updateCreditApp({ financialDocuments: currentFiles });
            setErrorMessageAndOpen(
              `Error uploading financial file [202.1a] - ${
                err?.message ?? err
              } (${DateTime.utc().toString()})`
            );
          });
      })
    );
  };

  const onRemoveFile = ({ filename, status }) =>
    (status === FILE_ERROR_BEFORE_UPLOADED
      ? Promise.resolve()
      : deleteUploadedDocument(values.creditAppId, filename)
    )
      .then(() => {
        mixpanelTrack("Credit App Financial File Removed", {
          "File format": filename?.split(".")?.[1],
          "File name": filename
        });
        const updatedDocs = values.financialDocuments.filter(
          doc => doc.filename !== filename
        );
        return setFieldValue("financialDocuments", updatedDocs).then(() =>
          updateCreditApp({ financialDocuments: updatedDocs })
        );
      })
      .catch(err => {
        setErrorMessageAndOpen(
          `Error on removing financial file [203.1] - ${
            err?.message ?? err
          } (${DateTime.utc().toString()})`
        );
      });

  return (
    <div className="twoSides">
      <div className="leftSide">
        <h4 className="font-normal">Legal Information</h4>
        <div
          className="grid gap-4"
          style={{ gridTemplateColumns: "1fr minmax(0, auto)" }}
        >
          <div className="inputField long w-2/3">
            <Label>Business Legal Name</Label>
            <TextInput
              name="businessLegalName"
              id="businessLegalName"
              placeholder="Type business legal name"
              value={values.businessLegalName}
              onChange={handleChange}
              disabled={!isEditing}
              valid={!errors.businessLegalName}
              onBlur={e => {
                mixpanelTrack("Credit App Business Legal Name Entered");
                errors.businessLegalName && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField yearsInBusiness">
            <Label>Years in business</Label>
            <TextInput
              name="yearsInBusiness"
              id="yearsInBusiness"
              placeholder="Years"
              disabled={!isEditing}
              value={values.yearsInBusiness}
              onChange={handleChange}
              valid={!errors.yearsInBusiness}
              onBlur={e => {
                mixpanelTrack("Credit App Years in Business Entered");
                errors.yearsInBusiness && handleBlur(e);
              }}
            />
          </div>
        </div>
        <div className="mb-4">
          <Label>Business Type</Label>
          <RadioGroup
            className="mt-2 pr-2 flex flex-wrap gap-2"
            name="businessType"
            id="businessType"
            values={[
              { value: "Corporation", label: "Corporation" },
              {
                value: "Proprietorship",
                label: "Proprietorship"
              },
              { value: "Partnership", label: "Partnership" },
              {
                value: "Not-for-profit",
                label: "Not-for-profit"
              },
              { value: "LLC", label: "LLC" },
              { value: "Other", label: "Other" }
            ]}
            currentValue={values.businessType?.choice ?? values.businessType}
            disabled={!isEditing}
            direction="horizontal"
            size="small"
            onChange={selected => {
              setFieldValue("businessType", { choice: selected.target.value });
              values?.businessTypeObtainedAutomatically &&
                setFieldValue("businessTypeObtainedAutomatically", false);
              mixpanelTrack("Credit App Business Type Entered", {
                value: selected.target.value
              });
            }}
            valid={!errors.businessType}
            onBlur={e => errors.businessType && handleBlur(e)}
          />
        </div>
        <div className="flex gap-2 justify-between">
          <Label>
            Are there any unsettled lawsuits, judgments, disputes, or
            outstanding tax obligations?
          </Label>
          <RadioGroup
            className="mt-2 flex gap-2 justify-evenly"
            name="unsettledObligations"
            id="unsettledObligations"
            disabled={!isEditing}
            values={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            currentValue={
              values.unsettledObligations
                ? "true"
                : values.unsettledObligations === false
                  ? "false"
                  : ""
            }
            direction="horizontal"
            size="small"
            onChange={selected => {
              setFieldValue(
                "unsettledObligations",
                selected.target.value == "true"
              );
              errors.unsettledObligations &&
                validateForm({
                  ...values,
                  unsettledObligations: selected.target.value == "true"
                });

              mixpanelTrack("Credit App Legal Issues Answered", {
                value: selected.target.value == "true"
              });
            }}
            valid={!errors.unsettledObligations}
          />
        </div>
        <div className="flex gap-2 justify-between">
          <Label>Has the business ever filed for bankruptcy?</Label>
          <RadioGroup
            className="mt-2 flex gap-2 justify-evenly"
            name="filledBankruptcy"
            id="filledBankruptcy"
            disabled={!isEditing}
            values={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            currentValue={
              values.filledBankruptcy
                ? "true"
                : values.filledBankruptcy === false
                  ? "false"
                  : ""
            }
            direction="horizontal"
            size="small"
            onChange={selected => {
              setFieldValue(
                "filledBankruptcy",
                selected.target.value == "true"
              );
              errors.filledBankruptcy &&
                validateForm({
                  ...values,
                  filledBankruptcy: selected.target.value == "true"
                });

              mixpanelTrack("Credit App Bankruptcy History Answered", {
                value: selected.target.value == "true"
              });
            }}
            valid={!errors.filledBankruptcy}
          />
        </div>
        <div className="flex gap-2 justify-between">
          <Label>
            Are the company owners willing to provide a personal guarantee if
            requested by the funder?
          </Label>
          <RadioGroup
            className="mt-2 flex gap-2 justify-evenly"
            name="canProvideGuarantee"
            id="canProvideGuarantee"
            disabled={!isEditing}
            values={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" }
            ]}
            currentValue={
              values.canProvideGuarantee
                ? "true"
                : values.canProvideGuarantee === false
                  ? "false"
                  : ""
            }
            direction="horizontal"
            size="small"
            onChange={selected => {
              setFieldValue(
                "canProvideGuarantee",
                selected.target.value == "true"
              );
              errors.canProvideGuarantee &&
                validateForm({
                  ...values,
                  canProvideGuarantee: selected.target.value == "true"
                });

              mixpanelTrack("Credit App Personal Guarantee Option Answered", {
                value: selected.target.value == "true"
              });
            }}
            valid={!errors.canProvideGuarantee}
          />
        </div>
        <h4 className="font-normal">Contact Information</h4>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Contact Person</Label>
            <TextInput
              name="contactName"
              id="contactName"
              placeholder="Name"
              disabled={!isEditing}
              value={values.contactName}
              onChange={handleChange}
              valid={!errors.contactName}
              onBlur={e => {
                mixpanelTrack("Credit App Contact Name Entered");
                errors.contactName && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField w-1/2">
            <Label>Contact Email Address</Label>
            <TextInput
              name="contactEmail"
              id="contactEmail"
              placeholder="name@email.com"
              disabled={!isEditing}
              value={values.contactEmail}
              onChange={e => {
                setFieldValue("contactEmail", e.target.value?.trim() ?? "");
              }}
              valid={!errors.contactEmail}
              onBlur={e => {
                mixpanelTrack("Credit App Contact Email Address Entered");
                errors.contactEmail
                  ? handleBlur(e)
                  : validateEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Phone number (optional)</Label>
            <TextInput
              name="contactPhoneNumber"
              id="contactPhoneNumber"
              placeholder="222-222-2222"
              disabled={!isEditing}
              value={values.contactPhoneNumber}
              onChange={handleChange}
              valid={!errors.contactPhoneNumber}
              onBlur={() =>
                mixpanelTrack("Credit App Contact Phone Number Entered")
              }
            />
          </div>
        </div>
        <h4 className="font-normal">Business Address</h4>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Country</Label>
            <AutocompleteInput
              className={classNames({
                "selectField p-1 pr-8": true,
                error: errors.countryCode
              })}
              name="countryCode"
              id="countryCode"
              placeholder={`Type or select a country`}
              disabled={!isEditing}
              autocompleteData={[emptyOption].concat(countryCodes)}
              getOptionLabel={o => o.label ?? ""}
              value={
                countryCodes.find(t => t.value === values?.countryCode) ??
                emptyOption
              }
              onBlur={handleBlur}
              onSelected={selected => {
                setFieldValue("countryCode", selected.value);
                setFieldValue("countryName", selected.label);
                setFieldValue("state", "");
                errors.countryCode &&
                  validateForm({
                    ...values,
                    countryCode: selected.value
                  });
                mixpanelTrack("Credit App Business Country Selected");
              }}
              valid={!errors.countryCode}
            />
          </div>
          <div className="inputField w-1/2">
            <Label>State/Region/Province</Label>
            <AutocompleteInput
              className={classNames({
                "selectField p-1 pr-8": true,
                error: errors.state
              })}
              name="state"
              id="state"
              placeholder={`Type or select a ${
                values.countryCode === "CA" ? "province" : "state"
              }`}
              disabled={!isEditing}
              autocompleteData={[emptyOption].concat(provinces)}
              getOptionLabel={o => o.label ?? ""}
              value={
                provinces.find(t => t.value === values?.state) ??
                provinces.find(t => t.value === "")
              }
              onBlur={handleBlur}
              onSelected={selected => {
                setFieldValue("state", selected.value);
                errors.state &&
                  validateForm({
                    ...values,
                    state: selected.value
                  });
                mixpanelTrack("Credit App Business Region Selected");
              }}
              valid={!errors.state}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Street Address</Label>
            <TextInput
              name="streetAddress"
              id="streetAddress"
              placeholder="Type the street address"
              disabled={!isEditing}
              value={values.streetAddress}
              valid={!errors.streetAddress}
              onChange={target => {
                handleChange(target);
              }}
              onBlur={e => {
                mixpanelTrack("Credit App Business Street Address Entered");
                errors.streetAddress && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField short w-1/2">
            <Label>Unit Number</Label>
            <TextInput
              name="unitNumber"
              id="unitNumber"
              placeholder="Type the unit number"
              disabled={!isEditing}
              value={values.unitNumber}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.unitNumber}
              onBlur={() =>
                mixpanelTrack("Credit App Business Unit Number Entered")
              }
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="inputField city w-1/2">
            <Label>City / Town</Label>
            <TextInput
              name="city"
              id="city"
              placeholder="City / Town"
              disabled={!isEditing}
              value={values.city}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.city}
              onBlur={e => {
                mixpanelTrack("Credit App Business City Entered");
                errors.city && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField short maxShort w-1/2">
            <Label>Zip / Postal Code</Label>
            <TextInput
              name="postalCode"
              id="postalCode"
              placeholder={values.countryCode === "US" ? "12345" : "A1B 2C3"}
              disabled={!isEditing}
              value={values.postalCode}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.postalCode}
              onBlur={e => {
                mixpanelTrack("Credit App Business Postal Code Entered");
                errors.postalCode && handleBlur(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="rightSide">
        <div className="flex justify-between gap-1">
          <h4 className="font-normal">Supporting Documents</h4>
          <div className="flex gap-2">
            <IconTextButton
              icon={<HelpIcon className="getHelpIcon" />}
              label={"Get Help"}
              className="getHelpButton borderBlueLight round"
              onClick={() => {
                mixpanelTrack("Credit App Show Help Page");
                setShowHelpPanel(true);
              }}
            />
            {!isPartnerView && (
              <ShareLink
                buttonLabel={"Invite Contributors"}
                title={"Invite Contributors"}
                mixpanelTrack={mixpanelTrack}
                centered={false}
                onClick={() =>
                  mixpanelTrack("Credit App Invite Contributors Clicked")
                }
                link={`${window.location.protocol}//${window.location.host}/creditApp/${values?.creditAppId}`}
                description={
                  "Share this link with a colleague who has the required information or can upload documents to help complete the application."
                }
              />
            )}
          </div>
        </div>
        <div>
          <p className="mb-2">
            To expedite your financing application, upload the required
            documentation.
          </p>
        </div>
        {isPartnerView && <div className="borderGray w-full"></div>}
        <BigUploadFiles
          id={"financialDocuments"}
          title={"Financials"}
          subtitle={
            isEditing
              ? `Upload your ${currentYear} & ${
                  currentYear - 1
                } Income Statement & Balance
    Sheet`
              : "Files Uploaded"
          }
          files={values.financialDocuments}
          addFiles={onChangeFiles}
          creditAppId={values.creditAppId}
          mixpanelTrack={mixpanelTrack}
          disabled={!isEditing}
          onRemoveFile={onRemoveFile}
        >
          {(files => {
            const {
              incomeStatement2,
              balanceSheet2,
              taxReturn2,
              incomeStatement1,
              balanceSheet1,
              taxReturn1
            } = getRelevantCharacteristics(files);
            return (
              <>
                <div className="validatedDocumentsDiv">
                  <InformationPopover
                    isOpen={isNewRequirementsPopoverOpen}
                    title={"New requirements"}
                    width={"360px"}
                    toTheLeft={true}
                  >
                    <>
                      <p>
                        Internally prepared financials must be paired with tax
                        returns
                      </p>
                      <div className="w-full">
                        <button
                          className="informationPopover-closeButton"
                          type="button"
                          onClick={() => {
                            setIsNewRequirementsPopoverOpen(false);
                            localStorage.setItem(
                              LOCAL_STORAGE_NEW_REQUIREMENT_POPOVER_CLOSED,
                              true
                            );
                          }}
                        >
                          I understand
                        </button>
                      </div>
                    </>
                  </InformationPopover>
                  <h5 className="w-full">Required Documents</h5>
                  <div className="requiredDocumentsList">
                    <span>
                      {getTaskIcon(incomeStatement2)}
                      Income Statements 2023
                    </span>
                    <span>
                      {getTaskIcon(incomeStatement1)}
                      Income Statements 2022
                    </span>
                    <span>
                      {getTaskIcon(balanceSheet2)}
                      Balance Sheet 2023
                    </span>
                    <span>
                      {getTaskIcon(balanceSheet1)}
                      Balance Sheet 2022
                    </span>
                    <span
                      className={
                        incomeStatement2 === "internal" ||
                        balanceSheet2 === "internal"
                          ? "fadein"
                          : "fadeout"
                      }
                    >
                      {getTaskIcon(taxReturn2)} Tax Returns 2023
                      <span
                        className={classNames(
                          "newRequirementTag",
                          !taxReturn2 ? "fadein" : "fadeout"
                        )}
                      >
                        NEW REQUIREMENT
                      </span>
                    </span>
                    <span
                      className={
                        incomeStatement1 === "internal" ||
                        balanceSheet1 === "internal"
                          ? "fadein"
                          : "fadeout"
                      }
                    >
                      {getTaskIcon(taxReturn1)} Tax Returns 2022
                      <span
                        className={classNames(
                          "newRequirementTag",
                          !taxReturn1 ? "fadein" : "fadeout"
                        )}
                      >
                        NEW REQUIREMENT
                      </span>
                    </span>
                  </div>
                </div>
              </>
            );
          })(values.financialDocuments ?? [])}
        </BigUploadFiles>
        {isEditing && (
          <div className="flex justify-end flex-grow items-end">
            <IconTextButton
              filled
              primary
              className="borderYellow1 py-4"
              label={"Review application"}
              onClick={e => {
                mixpanelTrack("Submit Financial Info", {
                  ...getRelevantCharacteristicsForMixpanel(
                    values.financialDocuments
                  ),
                  isComplete: wereAllRelevantDocumentsUploaded(
                    values.financialDocuments
                  )
                });
                setIsMessageSnackBarOpen(true);
                handleSubmit(e);
              }}
            />
          </div>
        )}
        {isEditing && values.isSubmitted && (
          <div className="fontDarkGray font-light flex justify-end">
            Submitted last on{" "}
            {DateTime.fromISO(values.dateSubmitted).toFormat(
              "MMMM dd, yyyy @ h:mm a"
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FinancingApplicationStep;

import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Checkbox, Tooltip } from "@enpowered/ui";
import classNames from "classnames";
import IconTextButton from "../../../common/IconTextButton";
import { ReactComponent as UnarchiveIcon } from "assets/unarchive.svg";
import { unarchiveFinancingScenarios } from "../../../services/FinancingScenarios";
import { emitter } from "utils/emitter";
import SnackBar from "common/SnackBar";
import SearchField from "../../../common/SearchField";
import { getMixpanel } from "../../../services/mixpanel";
import { getStateLabel } from "../../../lib/constants";

function ArchiveTable({ projectList = [], refetchArchive }) {
  const PROJECT_ID = "Project ID";
  const navigate = useNavigate();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [itemHovered, setItemHovered] = useState();
  const [message, setMessage] = useState({});
  const [filteredProjects, setFilteredProjects] = useState(projectList);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [searchString, setSearchString] = useState("");

  const mixpanel = getMixpanel();

  const fieldsToSearch = ["projectName"];
  useEffect(() => {
    /** @param {{ type: "info" | "warning" | "error" | "success", name: string, text: string }} msg */
    emitter.on("message", msg => setMessage(msg));
    return () => {
      emitter.off("message", msg => setMessage(msg));
    };
  }, []);

  const unarchive = financingScenarioIds =>
    unarchiveFinancingScenarios(financingScenarioIds)
      .then(() => {
        setMessage({
          text: "Project(s) restored!",
          type: "success"
        });
        setSelectedProjects([]);
        refetchArchive();
      })
      .catch(err =>
        setMessage({
          text: err?.message ?? err,
          type: "error"
        })
      );

  projectList?.sort((a, b) => b.dateAdded.localeCompare(a.dateAdded));

  useEffect(() => {
    if (searchString.length >= 3) {
      setSelectedProjects([]);
      setIsFilterActive(true);
      setFilteredProjects(
        projectList.filter(item =>
          fieldsToSearch.some(
            field =>
              (item[field]
                ?.toLowerCase()
                ?.indexOf(searchString?.toLowerCase()) ?? -1) >= 0
          )
        )
      );
    } else if (isFilterActive) {
      setIsFilterActive(false);
    }
  }, [projectList, searchString]);

  return (
    <div className="archiveTable">
      <SnackBar
        messages={message?.text ? [message.text] : []}
        type={message?.type}
      />
      <div className="tableActions mb-4">
        <SearchField
          searchString={searchString}
          setSearchString={setSearchString}
          listItemName="Archived Projects"
        />
        {selectedProjects.length > 0 && (
          <IconTextButton
            icon={<UnarchiveIcon />}
            className="borderGrayLighter round"
            label={`Restore project${selectedProjects.length > 1 ? "s" : ""}`}
            onClick={() => {
              mixpanel.track("Restore Button Clicked", {
                "Select All":
                  selectedProjects.length === projectList?.length &&
                  selectedProjects.length > 0,
                "Project IDs": selectedProjects
              });
              unarchive(selectedProjects);
            }}
          />
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>
              {(isFilterActive ? filteredProjects : projectList)?.length >
                0 && (
                <Tooltip
                  placement="top"
                  title={
                    selectedProjects.length > 0 ? "Deselect all" : "Select All"
                  }
                >
                  <Checkbox
                    id="selectAllProjects"
                    name="selectAllProjects"
                    checked={
                      selectedProjects.length === projectList?.length &&
                      selectedProjects.length > 0
                    }
                    defaultChecked={false}
                    size="small"
                    className={classNames("mx-1 border align-middle", {
                      "bg-white":
                        selectedProjects.length !== projectList?.length,
                      "bg-en-yellow-100":
                        selectedProjects.length === projectList?.length
                    })}
                    onChange={() =>
                      selectedProjects.length > 0
                        ? setSelectedProjects([])
                        : setSelectedProjects(
                            projectList?.map(p => p.financingScenarioId)
                          )
                    }
                  />
                </Tooltip>
              )}
            </th>
            <th>Project Name</th>
            <th>Project Type</th>
            <th>Financing Option</th>
            <th>Location</th>
            <th>
              <div className="w-10"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {(isFilterActive ? filteredProjects : projectList)?.map(
            (project, i) => {
              let isSelected = selectedProjects.includes(
                project.financingScenarioId
              );
              return (
                <tr
                  key={`ArchiveTable-${i}`}
                  onClick={e => {
                    if (
                      ["svg", "path"].includes(
                        e.target?.nodeName?.toLowerCase()
                      ) ||
                      e.target.className === "checkboxTd"
                    )
                      return;
                    mixpanel.track("Project Selected", {
                      [PROJECT_ID]: project.financingScenarioId,
                      Partner: project.partnerCompanyName
                    });
                    navigate(
                      `/project/${project.financingScenarioId}${location.search}`
                    );
                  }}
                  onMouseOver={() =>
                    setItemHovered(project.financingScenarioId)
                  }
                  onMouseLeave={() => setItemHovered(null)}
                  className={classNames({ rowSelected: isSelected })}
                >
                  <td className="checkboxTd">
                    <Checkbox
                      id={`checkbox-${i}`}
                      name={`checkbox-${i}`}
                      checked={isSelected}
                      defaultChecked={false}
                      size="small"
                      className={classNames("mx-1 border align-middle", {
                        "bg-white": !isSelected,
                        "bg-en-yellow-100": isSelected
                      })}
                      onChange={() => {
                        selectedProjects.some(
                          id => id === project.financingScenarioId
                        )
                          ? setSelectedProjects(
                              selectedProjects.filter(
                                p => p != project.financingScenarioId
                              )
                            )
                          : setSelectedProjects(
                              selectedProjects.concat(
                                project.financingScenarioId
                              )
                            );
                      }}
                    />
                  </td>
                  <td>{project.projectName}</td>
                  <td>{project.projectType}</td>
                  <td>Loan</td>
                  <td>
                    {[
                      getStateLabel(project.countryCode, project.state),
                      project.countryCode
                    ]
                      .filter(field => !!field)
                      .join(", ")}
                  </td>
                  <td className="actionsTd">
                    {itemHovered === project.financingScenarioId && (
                      <Tooltip placement="top" title={"Restore Project"}>
                        <button
                          onClick={() => {
                            unarchive([project.financingScenarioId]);
                            mixpanel.track("Restore Action Button Clicked", {
                              "Project ID": project.financingScenarioId
                            });
                          }}
                        >
                          <UnarchiveIcon />
                        </button>
                      </Tooltip>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ArchiveTable;

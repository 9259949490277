export const objectsAreDifferent = (object1, object2) =>
  //they are not object and are not equal
  (typeof object1 !== "object" &&
    typeof object2 !== "object" &&
    object1 !== object2) ||
  //or either of them is false but not both
  ((!object1 || !object2) && (!!object1 || !!object2)) ||
  //or both are not false and have some deeper differences
  (object1 &&
    object2 &&
    Object.keys(object1)?.length !== Object.keys(object2)?.length) ||
  (Object.keys(object1)?.some(key =>
    Array.isArray(object1[key])
      ? (Array.isArray(object2[key]) &&
          object1[key].length !== object2[key].length) ||
        object1[key].some(
          (arrayItem, idx) =>
            (typeof arrayItem !== "object" &&
              typeof object2?.[key]?.[idx] !== "object" &&
              arrayItem !== object2?.[key]?.[idx]) ||
            objectsAreDifferent(arrayItem, object2?.[key]?.[idx])
        )
      : object1[key] && typeof object1[key] === "object"
        ? Object.keys(object1[key])?.some(
            secondKey => object1[key][secondKey] !== object2?.[key]?.[secondKey]
          )
        : (!isNaN(object1[key]) || !isNaN(object2?.[key])) &&
          object1[key] !== object2?.[key]
  ) ??
    false);

export const isDefined = value =>
  typeof value !== "undefined" && value !== null;

export const pickProps = (propNames, value) =>
  Object.fromEntries(
    propNames
      .map(propName => [propName, value[propName]])
      .filter(([, propValue]) => isDefined(propValue))
  );

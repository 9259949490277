import React from "react";
import "./styles.scss";
import IconTextButton from "../../../common/IconTextButton";
import { ReactComponent as SuccessIcon } from "assets/success-illustration.svg";
import classNames from "classnames";

function CongratulationsPanel({
  partnerCompanyName = "",
  mixpanelTrack = "",
  setIsCongratulationsOpened,
  isPartnerView = false
}) {
  return (
    <div className="flex justify-center items-center py-8">
      <div className="panel flex flex-col w-full">
        <h2 className="text-center">Application Submitted!</h2>
        <SuccessIcon className="m-auto m-0 mb-3" />
        {!isPartnerView && partnerCompanyName && (
          <h4 className="px-6 text-center">
            Thank you for choosing{" "}
            <span className="font-bold">{partnerCompanyName}</span> to fuel your
            sustainable energy journey!
          </h4>
        )}
        {isPartnerView && (
          <div className="mt-2">
            <h4>{`What's`} Next</h4>
            <p>
              Our dedicated team is now hard at work reviewing your application.
              This process typically takes 24 hours, and they will send regular
              status updates via email.
            </p>
            <>
              <h4>Get Help</h4>
              <p>
                For any questions or assistance, please reach out to{" "}
                {isPartnerView ? (
                  "your partnership manager"
                ) : (
                  <a
                    className="underline"
                    href="malito:funding@enpowered.com"
                    onClick={() =>
                      mixpanelTrack("Credit App Success Support Click")
                    }
                  >
                    funding@enpowered.com
                  </a>
                )}
                .
              </p>
            </>
          </div>
        )}
        <div
          className={classNames("text-center m-auto m-0", {
            "mt-5": !isPartnerView
          })}
        >
          <p>
            {!isPartnerView && (
              <span>
                You can safely leave this page now,
                <br />
                or
              </span>
            )}
            <IconTextButton
              className="submitBtn borderGrayLighter py-4 font-light"
              label="Make Changes to the application"
              onClick={() => {
                setIsCongratulationsOpened(false);
                mixpanelTrack("Credit App Return To Application");
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default CongratulationsPanel;

/**
 * @typedef SubmissionTemplate
 * @property {string} id
 * @property {string} label
 * @property {SubmissionDocument[]} documents
 * @property {String} ownerId - the id of the organization to which this template belongs
 *
 * @typedef SubmissionDocument
 * @property {BuiltInDocument | string} id
 * @property {string} label
 * @property {string} [optionsLabel]
 * @property {string[]} [options]
 * @property {string} [defaultOption]
 * @property {boolean} [required] = false
 * @property {boolean} [builtIn] = false
 * @property {string} dateAdded = false
 *
 * IDs of built-in templates
 * @typedef {"financialStatements"
 * | "companyTaxReturns"
 * | "financialStatements-companyTaxReturns"} BuiltInDocument
 */

import { authedFetch } from "../utils/fetch";

const apiRoot = process.env.REACT_APP_API_ROOT;

export const createSubmissionTemplate = (
  ownerId,
  submissionTemplateDetails
) => {
  return authedFetch(`${apiRoot}/arlo-engine/jsonrpc-secured2`, {
    body: JSON.stringify({
      params: {
        sourceContext: "com.usearlo.app",
        action: "updateSubmissionTemplate",
        payload: {
          label: `Default submission template for ${ownerId}`,
          ownerId,
          ...(submissionTemplateDetails ? { submissionTemplateDetails } : {})
        }
      }
    })
  }).then(res => res.json());
};

import React from "react";
import Modal from "common/Modal";
import "./styles.scss";
import RightArrowIcon from "assets/right-arrow.svg";
import IconTextButton from "../../../common/IconTextButton";
import classNames from "classnames";
import { searchCompanyByBusinessName } from "../../../services/OpenCorporates";
import { Spinner } from "@enpowered/ui";
import { useQuery } from "react-query";
import { getStateLabel } from "../../../lib/constants";

function BusinessCompanySearchModal({
  modalOverlayRef,
  isOpen,
  setIsOpen,
  onConfirm,
  onEnterManually,
  queryCompanyString,
  mixpanelTrack,
  countryCode
}) {
  const [isLoadingError, setIsLoadingError] = React.useState(false);
  const {
    data: companies = [],
    isLoading,
    refetch
  } = useQuery(`companies-for-${queryCompanyString}-${isOpen}`, () =>
    isOpen && queryCompanyString
      ? searchCompanyByBusinessName(queryCompanyString, countryCode)
          .then(res => res?.filter(c => !!c))
          .then(companies => {
            isLoadingError && setIsLoadingError(false);
            mixpanelTrack("Business Name Searched", {
              "Search Text": queryCompanyString,
              "Number of Results": companies?.length
            });
            return companies;
          })
          .catch(() => {
            !isLoadingError && setIsLoadingError(true);
          })
      : []
  );
  const [companySelected, setCompanySelected] = React.useState(null);
  const tryAgain = () => refetch();

  return (
    <Modal
      blurrableContentRef={modalOverlayRef}
      centered={true}
      className={"businessCompanySearchModal bg-background-modal"}
      isOpen={isOpen}
    >
      <div className="ml-3 mt-3">
        <h3 className="">Confirm your company</h3>
        <p className="mt-2 mb-3">Review and select company from the results</p>
        <div className="businessCompanySearchModal-companiesList w-full mt-5 p-3">
          {isLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner size={30} />
            </div>
          ) : isLoadingError ? (
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="s24 font-light mt-3 text-center">
                No results, but keep searching
              </div>
              <div className="my-1 text-center">
                Your search was good but the results {"didn't"} make it back
                here
              </div>
              <div
                className="my-3 underline text-center cursor-pointer"
                onClick={() => tryAgain()}
              >
                Try again
              </div>
            </div>
          ) : !companies?.length ? (
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="s24 font-light my-3 text-center">
                No companies found
              </div>
              <div
                className="my-3 underline text-center cursor-pointer"
                onClick={() => onEnterManually()}
              >
                Enter details manually
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-start flex-col gap-2">
              {companies.map((company, idx) => (
                <div
                  onClick={() => setCompanySelected(company)}
                  key={idx}
                  className={classNames(
                    "businessCompanySearchModal-companyCard flex flex-col",
                    {
                      selected:
                        companySelected &&
                        companySelected.name === company.name &&
                        companySelected.state === company.state
                    }
                  )}
                >
                  <div className="s24 font-light">{company.name}</div>
                  <div className="">
                    {[
                      getStateLabel(company.countryCode, company.state),
                      company.countryCode,
                      company.city,
                      company.postalCode,
                      company.streetAddress
                    ]
                      .filter(field => !!field)
                      .join(", ")}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex gap-2 ml-3 my-2 cursor-pointer">
          <span
            className="underline"
            onClick={() => {
              mixpanelTrack("Enter Business Details Manually");
              onEnterManually();
            }}
          >
            Enter details manually
          </span>
          <img src={RightArrowIcon} />
        </div>
        <div className="flex gap-4 mt-6 mb -3 justify-center">
          <IconTextButton
            className="businessCompanySearchModal-button"
            filled
            label={"Cancel"}
            onClick={() => setIsOpen(false)}
          />
          <IconTextButton
            className="businessCompanySearchModal-button"
            filled
            primary
            disabled={!companySelected}
            label={"Confirm"}
            onClick={() => onConfirm(companySelected)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default BusinessCompanySearchModal;

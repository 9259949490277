import React from "react";
import FinancingScheduleView from "./FinancingScheduleView";
import "./styles.scss";
import CollateralView from "./CollateralView";

import { Snackbar } from "@mui/material";
import TitleBar from "../TitleBar";
import { VIEW_TYPES } from "../FormNavigator";

/**
 *
 * @param {object} props
 * @param {object} props.proposalInputs
 * @param {boolean} props.hasError
 * @param {Array} props.errors
 * @param {function} props.goBackClicked
 * @returns
 */

export const FinancingProposal = ({
  proposalInputs,
  calculations,
  hasError,
  errors,
  areRequiredInputsAvailable,
  isPayable,
  viewType,
  isLoadingPdfMessageVisible,
  setIsLoadingPdfMessageVisible,
  adaptAnnualValuesToGenerator,
  infoAssistOn,
  setInfoAssistOn,
  generatePdf,
  isGeneratingPdf,
  generateCsv,
  children
}) => {
  return (
    <div className="proposalView w-full h-align-middle">
      <TitleBar
        proposalInputs={proposalInputs}
        infoAssistOn={infoAssistOn}
        setInfoAssistOn={setInfoAssistOn}
      />
      {children && <>{children}</>}
      {viewType === VIEW_TYPES.FINANCING_SCHEDULE ? (
        <FinancingScheduleView
          proposalInputs={proposalInputs}
          calculations={calculations}
          areRequiredInputsAvailable={areRequiredInputsAvailable}
          isPayable={isPayable}
        />
      ) : (
        <CollateralView
          proposalInputs={proposalInputs}
          areRequiredInputsAvailable={areRequiredInputsAvailable}
          isPayable={isPayable}
          generateCsv={generateCsv}
          generatePdf={generatePdf}
          isGeneratingPdf={isGeneratingPdf}
          adaptAnnualValuesToGenerator={adaptAnnualValuesToGenerator}
        />
      )}
      <label className="errorLabel block text-en-red">
        {hasError(errors, "summary") && errors.summary}
      </label>
      <Snackbar
        className={"snackbar"}
        onClose={() => setIsLoadingPdfMessageVisible(false)}
        open={isLoadingPdfMessageVisible}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={"Getting PDF ready for download"}
      />
    </div>
  );
};

export default FinancingProposal;

export const adminManageCompaniesOperation = {
  action: "manage",
  objectPath: `/company-accounts`
};

export const isAuthorizedForOperation = (user, operation) =>
  user?.permissions &&
  isAuthorized(user.permissions, operation.action, operation.objectPath);

const isAuthorized = (permissions, forAction, forObjectPath) =>
  Object.values(permissions).some(
    p => p.action === forAction && p.objectPath === forObjectPath
  );

import React from "react";
import InsightsIcon from "assets/insights.svg";

function AdvancedValuesSection() {
  return (
    <div className="advancedSection mr-3">
      <img src={InsightsIcon} /> <div>Advanced values</div>
    </div>
  );
}

export default AdvancedValuesSection;

import { UserContext } from "auth/contexts/user";
import { useContext } from "react";
import { logout } from "../../services/auth/token";

export const useLogout = () => {
  const { clear } = useContext(UserContext);

  return () => {
    clear();
    logout();
  };
};

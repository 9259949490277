import React, { useEffect, useState } from "react";
import "./styles.scss";
import Modal from "common/Modal";
import IconTextButton from "common/IconTextButton";
import { ReactComponent as PlusIcon } from "assets/plus.svg";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { renderDollar } from "utils/stringFunctions";
import AutocompleteJsxInput from "../../common/AutoCompleteJsxInput";
import RadioGroup from "../../common/RadioGroup";
import { Checkbox } from "@enpowered/ui";
import classNames from "classnames";
import NumberInput from "../../common/NumberInput";
import * as Yup from "yup";
import SnackBar from "common/SnackBar";
import {
  calculateDepreciation,
  getDefaultTaxRateByRegion,
  isDepreciationIncentive
} from "../../utils/incentives";

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsOpen
 * @param {(event: string, properties?: any) => void} props.mixpanelTrack
 * @param {import("components/IncentivesField").Incentive[]} props.incentives
 * @param {number} props.projectCost
 * @returns {JSX.Element}
 */

function IncentivesModal({
  isOpen,
  setIsOpen,
  disabled,
  formProps,
  updateProposal,
  setErrorMessage = () => {},
  proposalInputsForCalculator
}) {
  const { values: proposal, setFieldValue } = formProps;
  const { incentives, projectCost, countryCode, state } = proposal;
  const [isNewIncentive, setIsNewIncentive] = useState(false);
  const [incentiveSelected, setIncentiveSelected] = useState(
    incentives?.[0] ?? {}
  );
  const [updatedIncentives, setUpdatedIncentives] = useState(
    (incentives ?? []).map(i => ({ ...i }))
  );
  const setIncentiveSelectedAndType = (updated, fromPrev = false) => {
    if (fromPrev) {
      setIncentiveSelected(prev => ({ ...prev, ...updated }));
    } else setIncentiveSelected(updated);
    if (updated.percentage && type !== "percentage") setType("percentage");
    else if (!updated.percentage && type !== "amount") setType("amount");
  };
  /**
   * @type {[("amount" | "percentage"), React.Dispatch<React.SetStateAction<string>>]}
   */
  const [type, setType] = useState(
    !incentiveSelected?.percentage && incentiveSelected.amount
      ? "amount"
      : "percentage"
  );
  const [errors, setErrors] = useState({});

  let totalIncentives =
    updatedIncentives.reduce(
      (ac, cur) =>
        ac +
        (cur.name === incentiveSelected.name
          ? 0
          : +cur.amount + +(cur.otherYears ?? 0)),
      0
    ) +
    (+(incentiveSelected?.amount ?? 0) + +(incentiveSelected?.otherYears ?? 0));

  useEffect(() => {
    setUpdatedIncentives((incentives ?? []).map(i => ({ ...i })));
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setIncentiveSelectedAndType(updatedIncentives?.[0] ?? {});
    }
  }, [updatedIncentives?.[0]?.amount, updatedIncentives?.[0]?.otherYears]);

  const {
    emptyOption,
    incentiveOptions,
    handleIncentiveAdded,
    handleIncentiveRemoved,
    handleNewIncentiveNameChanged
  } = useIncentiveOptions({
    ixTaxExempt: proposal.isTaxExempt,
    countryCode: proposal.countryCode,
    projectType: proposal.projectType,
    existingIncentiveNames: (updatedIncentives ?? []).map(i => i.name),
    newIncentiveName: isNewIncentive ? incentiveSelected.name : ""
  });

  const updateIncentives = incentiveUpdated => {
    if (!isNewIncentive) {
      const aux = updatedIncentives;
      aux[updatedIncentives.findIndex(i => i.name === incentiveSelected.name)] =
        incentiveUpdated ?? incentiveSelected;
      setUpdatedIncentives(aux);
    }
    return Promise.resolve();
  };

  const validateIncentive = incentive => {
    const isPercentageView = type === "percentage";
    return Yup.object({
      name: Yup.string()
        .required("name: Incentive name is required")
        .min(1, "name: Incentive name is required")
        .max(50, "name: Incentive name must be less than 50 characters")
        .test(
          "alreadyExist",
          "name: There is already an incentive with the name provided",
          name =>
            !isNewIncentive || updatedIncentives.every(i => i.name !== name)
        ),

      ...(isDepreciationIncentive(incentive.name)
        ? {}
        : {
            ...(!isPercentageView
              ? {
                  amount: Yup.number()
                    .required("amount: Incentive amount is required")
                    .moreThan(
                      0,
                      "amount: Incentive amount must be greater than 0"
                    )
                }
              : {
                  percentage: Yup.number()
                    .required("percentage: Incentive percentage is required")
                    .moreThan(
                      0,
                      "percentage: Incentive percentage must be greater than 0"
                    )
                })
          })
    })
      .validate(incentive, { abortEarly: false })
      .then(() => {
        setErrors({});
        setErrorMessage([]);
      })
      .catch(err => {
        console.info({ validationError: err });
        setErrors(
          Object.fromEntries(
            err.errors.map(e =>
              e.indexOf("must be a `number` type") >= 0
                ? [
                    isPercentageView ? "percentage" : "amount",
                    `The ${
                      isPercentageView ? "percentage" : "amount"
                    } field requires a number`
                  ]
                : e.split(": ")
            )
          )
        );
        return Promise.reject(err);
      });
  };

  const validateValues = () =>
    Object.entries(incentiveSelected ?? {}).length
      ? validateIncentive(incentiveSelected)
      : Promise.resolve();
  const validateAndUpdateValues = () =>
    validateValues().then(() => {
      if (isNewIncentive) {
        setUpdatedIncentives(
          (updatedIncentives ?? []).concat(incentiveSelected)
        );
        setIsNewIncentive(false);
        handleIncentiveAdded(incentiveSelected.name);
      }
    });

  const getTaxRateAndCalculateDepreciation = () => {
    const { federalTaxRate, stateTaxRate } = getDefaultTaxRateByRegion(
      countryCode,
      state
    );
    return calculateDepreciation(
      proposalInputsForCalculator,
      (federalTaxRate + stateTaxRate) / 100
    )
      .then(({ amount, otherYears }) =>
        setIncentiveSelected(prev => ({
          ...prev,
          federalTaxRate,
          stateTaxRate,
          amount,
          otherYears
        }))
      )
      .catch(() =>
        setIncentiveSelected(prev => ({
          ...prev,
          federalTaxRate,
          stateTaxRate,
          amount: 0,
          otherYears: 0
        }))
      );
  };

  const closeWithoutSaving = () => {
    if (isNewIncentive) {
      handleIncentiveRemoved(incentiveSelected.name);
      setIsNewIncentive(false);
    }
    updatedIncentives?.[0] &&
      setIncentiveSelectedAndType(incentives?.[0] ?? {});
    setIsOpen(false);
  };

  return (
    <Modal
      className={"incentivesModal bg-background-modal"}
      isOpen={isOpen}
      centered={false}
    >
      <SnackBar
        key={"ErrorsAndProposalPro"}
        messages={
          Object.entries(errors)?.length
            ? Object.entries(errors).map(([, message]) => message)
            : []
        }
        type={"error"}
        duration={5000}
      />
      <div className="flex justify-between py-3">
        <h3 className="text-left">Add Project Incentives</h3>
        <div className="relative w-0 h-0">
          <span className="x" onClick={closeWithoutSaving}>
            ×
          </span>
        </div>
      </div>
      <div className="mt-2">
        <p className="mb-2 font-light">
          Add any financial incentives applicable to your solar project. <br />
          Tax credits/depreciation may not be fully utilized based on the{" "}
          {`customer's`}
          tax liability, impacting cash flow.
        </p>
      </div>
      <div className="flex justify-between">
        <div className="leftSide">
          {(!!incentives.length || isNewIncentive) && (
            <IncentivesList
              incentives={updatedIncentives.concat(
                isNewIncentive ? [incentiveSelected] : []
              )}
              onSelected={selected =>
                validateAndUpdateValues()
                  .then(() => {
                    setIncentiveSelectedAndType(selected);
                  })
                  .catch(() => {})
              }
              incentiveSelectedName={incentiveSelected.name}
            />
          )}
          <div className="w-full px-3">
            <AddIncentivesButton
              disabled={
                disabled ||
                (updatedIncentives ?? [])?.length >= 4 ||
                ((updatedIncentives ?? [])?.length == 3 && isNewIncentive)
              }
              onClick={() => {
                validateAndUpdateValues()
                  .then(() => {
                    const newIncentive = {
                      name: "",
                      amount: 0,
                      useInPayments: false
                    };
                    setIsNewIncentive(true);
                    setType("percentage");
                    setIncentiveSelected(newIncentive);
                  })
                  .catch(() => {});
              }}
            />
          </div>
        </div>
        <div className="border-l bgGray2 min-h-full w-1px"></div>
        <div className="rightSide">
          {updatedIncentives?.length || isNewIncentive > 0 ? (
            <div className="w-full">
              <label>Incentive Name</label>
              {isNewIncentive ? (
                <AutocompleteJsxInput
                  className={"incentivesDropdown"}
                  inputClassName={"font-normal"}
                  id={"incentives"}
                  autoFocus
                  valid={!errors?.name}
                  value={
                    incentiveOptions.find(
                      option => option.label === incentiveSelected?.name
                    ) ?? emptyOption
                  }
                  autocompleteData={incentiveOptions}
                  onChange={e => {
                    errors?.name &&
                      validateIncentive({
                        ...incentiveSelected,
                        name: e.target.value
                      }).catch(() => {});
                    if (e.type === "change") {
                      handleNewIncentiveNameChanged(e.target.value);
                      setIncentiveSelected({
                        ...incentiveSelected,
                        name: e.target.value
                      });
                      if (isDepreciationIncentive(e.target.value))
                        return getTaxRateAndCalculateDepreciation();
                    }
                  }}
                  onSelected={selected => {
                    if (selected?.disabled) return;
                    setIncentiveSelected({
                      ...incentiveSelected,
                      name: selected.label
                    });
                    if (isDepreciationIncentive(selected.label))
                      return getTaxRateAndCalculateDepreciation();
                    else if (selected.label === "ITC")
                      setIncentiveSelectedAndType(
                        {
                          percentage: 30,
                          amount: projectCost * 0.3,
                          useInPayments: true
                        },
                        true
                      );
                  }}
                  placeholder="Type new incentive name"
                  onBlur={() => updateIncentives()}
                />
              ) : (
                <p className="incentiveName">{incentiveSelected?.name}</p>
              )}
              {!isDepreciationIncentive(incentiveSelected?.name) ? (
                <>
                  <RadioGroup
                    className="my-3 pr-2 flex flex-wrap gap-2 font-light"
                    name="type"
                    id="type"
                    values={[
                      {
                        value: "percentage",
                        label: "Percentage of Cost"
                      },
                      { value: "amount", label: "Fixed Amount" }
                    ]}
                    currentValue={type}
                    disabled={disabled}
                    direction="horizontal"
                    size="small"
                    onChange={selected => {
                      setType(selected.target.value);
                      let incentiveUpdated = {};
                      if (selected.target.value === "amount") {
                        incentiveUpdated = {
                          ...incentiveSelected,
                          amount: +(
                            ((incentiveSelected.percentage ?? 0) / 100) *
                            (projectCost ?? 0)
                          ).toFixed(2),
                          percentage: null
                        };
                      } else {
                        incentiveUpdated = {
                          ...incentiveSelected,
                          percentage:
                            ((incentiveSelected.amount ?? 0) * 100) /
                              (projectCost ?? 1000000000) >
                            100
                              ? 100
                              : +(
                                  ((incentiveSelected.amount ?? 0) * 100) /
                                  (projectCost ?? 1000000000)
                                ).toFixed(2)
                        };
                      }
                      setIncentiveSelected(incentiveUpdated);
                      updateIncentives(incentiveUpdated);
                    }}
                  />
                  {type === "amount" ? (
                    <NumberInput
                      className="mb-2"
                      name="amount"
                      id="amount"
                      label="Amount"
                      disabled={disabled}
                      prefix="$"
                      value={incentiveSelected?.amount ?? 0}
                      valid={!errors?.amount}
                      onChange={e => {
                        errors?.amount &&
                          validateIncentive({
                            ...incentiveSelected,
                            amount: e.target.value
                          }).catch(() => {});
                        setIncentiveSelected({
                          ...incentiveSelected,
                          amount: e.target.value,
                          percentage: null
                        });
                      }}
                      onBlur={() => updateIncentives()}
                    />
                  ) : (
                    <NumberInput
                      className="mb-2 s16"
                      name="percentage"
                      id="percentage"
                      label="Percentage"
                      disabled={disabled}
                      decimalPlaces={2}
                      max={100}
                      size="sm"
                      suffix="%"
                      prefix=""
                      value={incentiveSelected?.percentage ?? 0}
                      valid={!errors?.percentage}
                      onChange={e => {
                        errors?.percentage &&
                          validateIncentive({
                            ...incentiveSelected,
                            percentage: e.target.value
                          }).catch(() => {});
                        setIncentiveSelected({
                          ...incentiveSelected,
                          percentage: e.target.value,
                          amount: (projectCost ?? 0) * (e.target.value / 100)
                        });
                      }}
                      onBlur={() => updateIncentives()}
                    />
                  )}
                </>
              ) : (
                <>
                  <NumberInput
                    className="my-3"
                    name="federalTaxRate"
                    id="federalTaxRate"
                    label="Federal Tax Rate"
                    disabled={disabled}
                    decimalPlaces={2}
                    max={100}
                    prefix={""}
                    suffix={"%"}
                    size="sm"
                    value={incentiveSelected?.federalTaxRate ?? 0}
                    valid={!errors?.federalTaxRate}
                    onChange={e => {
                      const federalTaxRate = e.target.value;
                      setIncentiveSelected({
                        ...incentiveSelected,
                        federalTaxRate
                      });
                      return calculateDepreciation(
                        proposalInputsForCalculator,
                        ((+federalTaxRate ?? 0) +
                          (+incentiveSelected.stateTaxRate ?? 0)) /
                          100
                      ).then(({ amount, otherYears }) =>
                        setIncentiveSelected(prev => ({
                          ...prev,
                          amount,
                          otherYears
                        }))
                      );
                    }}
                    onBlur={() => updateIncentives()}
                  />
                  <NumberInput
                    className="mb-3"
                    name="stateTaxRate"
                    id="stateTaxRate"
                    label={
                      countryCode === "CA"
                        ? "Provincial Tax Rate"
                        : "State Tax Rate"
                    }
                    disabled={disabled}
                    decimalPlaces={2}
                    max={100}
                    prefix={""}
                    suffix={"%"}
                    size="sm"
                    value={incentiveSelected?.stateTaxRate ?? 0}
                    valid={!errors?.stateTaxRate}
                    onChange={e => {
                      const stateTaxRate = e.target.value;
                      setIncentiveSelected({
                        ...incentiveSelected,
                        stateTaxRate
                      });
                      return calculateDepreciation(
                        proposalInputsForCalculator,
                        ((+stateTaxRate ?? 0) +
                          (+incentiveSelected.federalTaxRate ?? 0)) /
                          100
                      ).then(({ amount, otherYears }) =>
                        setIncentiveSelected(prev => ({
                          ...prev,
                          amount,
                          otherYears
                        }))
                      );
                    }}
                    onBlur={() => updateIncentives()}
                  />
                </>
              )}
              <div className="flex items-center font-light gap-1">
                <Checkbox
                  id={`applyToPayment`}
                  name={`applyToPayment`}
                  checked={incentiveSelected.useInPayments}
                  defaultChecked={false}
                  size="small"
                  className={classNames("mx-1 border align-middle", {
                    "bg-white": !incentiveSelected.useInPayments,
                    "bg-en-yellow-100": incentiveSelected.useInPayments
                  })}
                  onChange={() => {
                    let incentiveUpdated = {
                      ...incentiveSelected,
                      useInPayments: !incentiveSelected.useInPayments
                    };
                    setIncentiveSelected(incentiveUpdated);
                    updateIncentives(incentiveUpdated);
                  }}
                />
                <span>
                  Use
                  {isDepreciationIncentive(incentiveSelected.name)
                    ? " first year savings in "
                    : " in "}
                  lump sum payment
                </span>
              </div>
              <div className="pb-3 pt-5 flex justify-between">
                <span className="font-light ">Incentive Amount</span>
                <span className="">
                  {renderDollar(
                    +incentiveSelected?.amount +
                      +(incentiveSelected.otherYears ?? 0)
                  )}
                </span>
              </div>
              <IconTextButton
                className="float-right mb-2 borderGrayDarker py-4 font-normal"
                label="Remove"
                onClick={() => {
                  let incentiveSelectedName = incentiveSelected.name;
                  if (isNewIncentive) {
                    setIsNewIncentive(false);
                  } else
                    setUpdatedIncentives(
                      updatedIncentives.filter(
                        i => i.name !== incentiveSelectedName
                      )
                    );
                  handleIncentiveRemoved(incentiveSelectedName);
                  setIncentiveSelectedAndType(
                    !updatedIncentives?.[0]
                      ? {}
                      : updatedIncentives[0].name === incentiveSelectedName
                        ? updatedIncentives[1] ?? {}
                        : updatedIncentives[0]
                  );
                  if (Object.entries(errors)?.length) setErrors({});
                }}
              />
              <button className="float-right"></button>
            </div>
          ) : (
            <span className="noIncentivesText text-center">
              Incentive details will show here
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/2 pr-3 pt-1">
          <p className="totalText">
            <span>Total Incentives: </span>
            <span>{renderDollar(totalIncentives)}</span>
          </p>
        </div>
        <div className="flex gap-2 items-center justify-end w-1/2 pr-3 ">
          <IconTextButton
            filled
            label={"Cancel"}
            className={"rounded-[4px]"}
            onClick={closeWithoutSaving}
          />
          <IconTextButton
            className="rounded-[4px] doneButton"
            filled
            primary
            label={"Done"}
            onClick={() =>
              validateValues()
                .then(() => {
                  let newestUpdatedIncentive = isNewIncentive
                    ? (updatedIncentives ?? []).concat(incentiveSelected)
                    : updatedIncentives;
                  updateProposal({
                    ...proposal,
                    incentives: newestUpdatedIncentive
                  });
                  setFieldValue("incentives", newestUpdatedIncentive);

                  if (isNewIncentive) {
                    setUpdatedIncentives(newestUpdatedIncentive);
                    setIsNewIncentive(false);
                    handleIncentiveAdded(incentiveSelected.name);
                  }

                  setIsOpen(false);
                })
                .catch(() => {})
            }
            icon={<CheckIcon className={"iconBlack"} width={25} height={25} />}
          />
        </div>
      </div>
    </Modal>
  );
}

function AddIncentivesButton({ onClick, disabled }) {
  return (
    <button
      type="button"
      className="addIncentivesButton"
      onClick={onClick}
      disabled={disabled}
    >
      Add Incentives
      <PlusIcon className="inline-block" width={25} height={25} />
    </button>
  );
}

function IncentivesList({ incentives, onSelected, incentiveSelectedName }) {
  return (
    <ul className="incentivesList w-full">
      {incentives?.map((incentive, key) => (
        <li
          key={`incentive${key}`}
          className={classNames("py-2 px-3 flex justify-between", {
            selected: incentive.name === incentiveSelectedName
          })}
          onClick={() => onSelected(incentive)}
        >
          <span>{incentive.name}</span>
          <span className="amount">
            {renderDollar(+incentive.amount + (incentive.otherYears ?? 0))}
          </span>
        </li>
      ))}
    </ul>
  );
}

function useIncentiveOptions({
  ixTaxExempt,
  countryCode,
  projectType,
  existingIncentiveNames,
  newIncentiveName
}) {
  const buildNewIncentiveOption = newIncentiveName => ({
    label: newIncentiveName,
    jsx: (
      <div className="flex justify-between">
        <span>{newIncentiveName}</span>
        <i className="font-light">(Add new)</i>
      </div>
    )
  });
  const emptyOption = { label: "" };

  const defaultIncentiveOptions = [
    {
      label: "DEFAULT INCENTIVES",
      jsx: <span className="s12 gray2">DEFAULT INCENTIVES</span>,
      disabled: true,
      isCategory: true
    },
    ...(ixTaxExempt
      ? []
      : [
          {
            label: countryCode === "CA" ? "CCA" : "MACRS"
          }
        ]),
    ...(projectType === "Solar"
      ? [
          {
            label: "ITC"
          }
        ]
      : [])
  ];

  const updateDefaultOptions = (updatedIncentiveName, wasRemoved) => option =>
    option.isCategory ||
    !existingIncentiveNames
      .concat(updatedIncentiveName)
      .includes(option.label) ||
    !option.label?.length
      ? option
      : wasRemoved && option.label === updatedIncentiveName
        ? {
            ...option,
            disabled: false,
            jsx: undefined
          }
        : {
            ...option,
            disabled: true,
            jsx: (
              <div className="flex justify-between">
                <span>{option.label}</span>
                <i className="alreadyAppliedText">(already applied)</i>
              </div>
            )
          };

  let incentiveOptions = (
    newIncentiveName?.length
      ? [buildNewIncentiveOption(newIncentiveName)]
      : [emptyOption]
  )
    .concat(defaultIncentiveOptions)
    .map(updateDefaultOptions());

  const handleIncentiveAdded = newIncentiveName => {
    incentiveOptions = (
      isNewIncentiveOptionOnTheList
        ? [emptyOption].concat(incentiveOptions.slice(1))
        : incentiveOptions
    ).map(updateDefaultOptions(newIncentiveName));
  };

  const isNewIncentiveOptionOnTheList = () =>
    incentiveOptions.length > defaultIncentiveOptions.length;

  const handleNewIncentiveNameChanged = newIncentiveName => {
    incentiveOptions = [buildNewIncentiveOption(newIncentiveName)].concat(
      isNewIncentiveOptionOnTheList()
        ? incentiveOptions.slice(1)
        : incentiveOptions
    );
  };

  /**
   * @param {string} incentiveNameRemoved
   */

  const handleIncentiveRemoved = incentiveNameRemoved => {
    incentiveOptions = (
      defaultIncentiveOptions.some(
        option => option.label === incentiveNameRemoved
      )
        ? incentiveOptions
        : [emptyOption].concat(incentiveOptions.slice(1))
    ).map(updateDefaultOptions(incentiveNameRemoved, true));
  };

  return {
    defaultIncentiveOptions,
    emptyOption,
    incentiveOptions,
    handleIncentiveAdded,
    handleIncentiveRemoved,
    handleNewIncentiveNameChanged
  };
}

export default IncentivesModal;

import React, { useEffect, useState } from "react";
import "./styles.scss";
import classNames from "classnames";
import { Label, TextInput, AutocompleteInput } from "@enpowered/ui";
import {
  CA_STATES,
  US_STATES,
  getUtilityEscalatorRate
} from "../../lib/constants";
import NumberInput from "../../common/NumberInput";
import LoadingModal from "common/LoadingModal";
import {
  ConfirmationModal,
  FILE_ERROR,
  FILE_ERROR_BEFORE_UPLOADED,
  FILE_UPLOADING,
  FILE_VALIDATED,
  IndividualFile
} from "../BigUploadFiles";
import {
  deleteUploadedDocument,
  getDataRoomPathForAssetDocuments,
  getDefaultRequiredDocuments,
  getSignedUploadUrl,
  removeSubmissionDetail,
  updateCreditApp,
  updateSubmissionDetails,
  uploadFile
} from "../../services";
import { DateTime } from "luxon";
import ShareIllustration from "assets/share-illustration.svg";
import BuildEstimateIllustration from "assets/build-estimate-illustration.svg";
import ArchiveIllustration from "assets/archive-illustration.svg";
import { ReactComponent as LinkIcon } from "assets/link.svg";
import { ReactComponent as CalculateIcon } from "assets/calculate.svg";

export const TAB_FINANCING_APPLICATION = "financingApplication";
export const TAB_PROJECT_DETAILS = "projectDetails";
export const tabs = {
  [TAB_FINANCING_APPLICATION]: "Customer Financial Info",
  [TAB_PROJECT_DETAILS]: "Project Details"
};

import "./styles.scss";
import { ReactComponent as UploadIcon } from "assets/upload-cloud.svg";
import { ReactComponent as ArchiveIcon } from "assets/archive.svg";
import IconTextButton from "../../common/IconTextButton";
import { sleep } from "../../utils/fetch";
import {
  ACTION_BUILD_ESTIMATE,
  ACTION_SHARE_CREDIT_APP
} from "../../ProposalForm";
import {
  isDepreciationIncentive,
  calculateDepreciation,
  getDefaultTaxRateByRegion
} from "utils/incentives";

function UploadProjectFiles({
  id,
  className,
  files,
  setFiles,
  children,
  onRemoveFile,
  valid,
  disabled
}) {
  const [fileBeingDeleted, setFileBeingDeleted] = useState(null);
  const fileInputId = `projectFiles-${id}`;
  return (
    <div>
      <ConfirmationModal
        fileBeingDeleted={fileBeingDeleted}
        setFileBeingDeleted={setFileBeingDeleted}
        onConfirm={onRemoveFile}
      />
      <div
        className={classNames(
          `bg-background-light px-[32px] flex items-center justify-between mb-3 rounded-[8px]`,
          className,
          {
            disabled,
            "border border-error": !valid
          }
        )}
      >
        <div className={classNames("py-[16px]")}>
          <p className="font-light s16">Suggested Documents</p>
          <div className="font-normal">
            <ul className="uploadProjectFiles-suggestedDocumentsList s14">
              <li>Project proposal</li>
              <li>Bill of materials</li>
              <li>Energy production report</li>
            </ul>
          </div>
        </div>

        <div>
          <label
            className={classNames(
              `uploadProjectFiles-uploadLabel cursor-pointer flex items-center gap-2 rounded-[4px]  px-[32px] py-[8px] font-normal`,
              {
                "bg-white shadow-darkZ1 hover:bg-background-light": !disabled,
                "bg-background disabled": disabled
              }
            )}
            htmlFor={fileInputId}
          >
            <UploadIcon className="uploadProjectFiles-uploadIcon inline-block" />{" "}
            <span>Upload files</span>
          </label>
          <input
            className="absolute"
            id={fileInputId}
            type="file"
            hidden
            multiple
            disabled={disabled}
            onClick={event => {
              event.target.value = null;
            }}
            onChange={async event => {
              let dupedFiles = [],
                aux = [];
              if (event.dataTransfer?.items) {
                [...event.dataTransfer.items].forEach(item => {
                  if (item.kind === "file") aux.push(item.getAsFile());
                });
              } else aux = Array.from(event.currentTarget.files ?? []);
              dupedFiles = aux.map(uploadedFile => {
                if (
                  (files ?? []).find(
                    existingFile => existingFile.label === uploadedFile.name
                  )
                ) {
                  const getFileExtensionRegexWithDot = /(?:.(?!\.))+$/g;
                  // const getFileExtensionRegex = /(?:[^.](?!\.))+$/g;
                  const modifiedFileName = `${uploadedFile.name.split(getFileExtensionRegexWithDot)[0]} (${
                    files.filter(existingFile =>
                      existingFile.label
                        .split(getFileExtensionRegexWithDot)[0] // get the filename without the extension
                        //( (?!\.) is a negative lookahead for a dot that is not followed by another dot)
                        // (?:.(?!\.))+ is a non-capturing group that matches any character that is not followed by a dot
                        .startsWith(
                          uploadedFile.name.split(
                            getFileExtensionRegexWithDot
                          )[0]
                        )
                    ).length
                  })${uploadedFile.name.match(getFileExtensionRegexWithDot)?.[0] ?? ".file"}`;
                  return new File([uploadedFile], modifiedFileName, {
                    type: uploadedFile.type,
                    lastModified: uploadedFile.lastModified
                  });
                } else {
                  return uploadedFile;
                }
              });
              setFiles(dupedFiles);
            }}
          />
        </div>
      </div>

      {files?.length ? (
        <div className="flex flex-col gap-2">
          {files.map((file, idx) => (
            <IndividualFile
              // uploadFileId={`files-${id}`}
              removeFile={({ id, label, status }) => {
                if (status === FILE_ERROR_BEFORE_UPLOADED)
                  onRemoveFile({ id, label, status });
                else setFileBeingDeleted({ id, label, status });
              }}
              key={"file-" + idx}
              uploadFileId={fileInputId}
              fileLabel={file.label}
              fileId={file.id}
              status={file.jsonData?.status}
              disabled={disabled}
            />
          ))}
        </div>
      ) : disabled ? (
        <div className="fontGray s14">No files uploaded</div>
      ) : (
        <div className="mt-5 flex items-center justify-center gap-2">
          <span className="s14">No files uploaded</span>
        </div>
      )}

      {children}
    </div>
  );
}

function ProjectDetailsFeature({
  formProps,
  handleBlur,
  hasError,
  // setMessage,
  mixpanelTrack,
  validationOnChanged,
  proposalInputsForCalculator,
  infoAssistOn,
  setFieldFocused,
  updateFinancingScenario,
  creditApp,
  setCreditAppState,
  updateCreditAppFile,
  setErrorMessageAndOpen,
  onBuildEstimateClicked,
  validateProjectDetails,
  onRestoreClicked,
  userLoggedIn
  // postFinancingScenarioUpdates
}) {
  const { values, setFieldValue, setValues } = formProps;

  const [copyLinkClicked, setCopyLinkClicked] = useState(false);
  const [isFileUploadingNow, setIsFileUploadingNow] = useState(false);

  //confirm files that were still being uploaded
  useEffect(() => {
    (creditApp.assetDocuments ?? []).forEach(async doc => {
      if (doc?.jsonData?.status === FILE_UPLOADING && !isFileUploadingNow) {
        doc.jsonData.status = FILE_ERROR_BEFORE_UPLOADED;
        setCreditAppState({ assetDocuments: creditApp.assetDocuments });
        const { jsonData, ...fileInfo } = doc;
        updateCreditAppFile(fileInfo, jsonData);
      }
    });
  }, [!!creditApp?.assetDocuments?.[0]]);

  const financingOptions = [{ value: "Loan", label: "Loan" }];
  const projectTypeOptions = [
    { value: "", label: "" },
    { value: "Lighting", label: "Lighting" },
    { value: "Solar", label: "Solar" },
    { value: "HVAC", label: "HVAC" },
    { value: "Other", label: "Other" }
  ];
  const countryCodes = [
    { value: "", label: "" },
    { label: "Canada", value: "CA" },
    { label: "United States", value: "US" }
  ];
  const provinces = [{ value: "", label: "" }].concat(
    (values.countryCode === "CA" ? CA_STATES : US_STATES) ?? []
  );

  const updateDepreciationRates = (incentives, countryCode, state) => {
    const updatedIncentives = incentives ?? [];
    const depreciationIncentive = updatedIncentives.find(i =>
      isDepreciationIncentive(i.name)
    );
    if (depreciationIncentive) {
      const { federalTaxRate, stateTaxRate } = getDefaultTaxRateByRegion(
        countryCode,
        state
      );
      depreciationIncentive.federalTaxRate = federalTaxRate;
      depreciationIncentive.stateTaxRate = stateTaxRate;
      return calculateDepreciation(
        proposalInputsForCalculator,
        ((+federalTaxRate ?? 0) + (+stateTaxRate ?? 0)) / 100
      ).then(({ amount, otherYears }) => {
        depreciationIncentive.amount = amount;
        depreciationIncentive.otherYears = otherYears;
        setFieldValue("incentives", updatedIncentives);
      });
    }
  };

  const uploadNewFile = async (propertyName, currentFiles, newFile, fileInfo) =>
    getSignedUploadUrl(values.creditAppId, fileInfo.label)
      .then(urlAndFileKey => {
        if (!urlAndFileKey.presignedUrl) {
          console.error("Failed to get Signed url");
          return Promise.reject("Failed to prepare document for upload");
        } else {
          fileInfo.dataUrl = urlAndFileKey.fileKey;
          fileInfo.jsonData.status = FILE_UPLOADING;
          setCreditAppState({ [propertyName]: currentFiles });
          updateCreditAppFile(fileInfo);
          return uploadFile(urlAndFileKey.presignedUrl, newFile);
        }
      })
      .then(response => {
        console.info("file uploaded:", {
          label: newFile.name,
          response
        });
        fileInfo.jsonData.status = FILE_VALIDATED;
        setCreditAppState({ [propertyName]: currentFiles });
        updateCreditAppFile(fileInfo);
        validationOnChanged(propertyName, currentFiles);
        mixpanelTrack("Project Details File Uploaded", {
          "File name": fileInfo.label,
          "File format": fileInfo.dataMimeType
        });
      });

  let onChangeFiles = propertyName => async files => {
    //filter removed files
    let currentFiles = creditApp[propertyName] ?? [];
    //get new files
    let newFiles = (files ?? []).reduce(
      (filesToAdd, newFile) =>
        !currentFiles.some(curFile => newFile.name === curFile.label)
          ? [...filesToAdd, newFile]
          : filesToAdd,
      []
    );
    return Promise.allSettled(
      newFiles.map(newFile => {
        console.info("file added:", newFile.name);

        const fileInfo = {
          id: `file-${newFile.name}`,
          label: newFile.name,
          type: "file",
          dataMimeType: newFile.type,
          addToDataRoom: true,
          dataRoomPath: `${getDataRoomPathForAssetDocuments(values?.partnerCompanyName, values?.projectName)}/${newFile.name}`,
          jsonData: {
            status: FILE_UPLOADING
          }
        };
        currentFiles.push(fileInfo);

        setIsFileUploadingNow(true);
        setCreditAppState({ [propertyName]: currentFiles });
        return updateCreditAppFile(fileInfo)
          .then(() =>
            uploadNewFile(propertyName, currentFiles, newFile, fileInfo)
          )
          .catch(err => {
            console.error("Error uploading asset information file", err);
            fileInfo.jsonData.status =
              fileInfo.jsonData.status === FILE_VALIDATED
                ? FILE_ERROR
                : FILE_ERROR_BEFORE_UPLOADED;
            setCreditAppState({ [propertyName]: currentFiles });
            updateCreditAppFile(fileInfo);
            setErrorMessageAndOpen(
              `Error uploading asset information file [202.2] - ${
                err?.message ?? err
              } (${DateTime.utc().toString()})`
            );
          })
          .finally(() => {
            setIsFileUploadingNow(false);
          });
      })
    );
  };
  const onRemoveFile =
    propertyName =>
    ({ id, label, status }) =>
      (status === FILE_ERROR_BEFORE_UPLOADED
        ? Promise.resolve()
        : deleteUploadedDocument(values.creditAppId, label)
      )
        .then(() => {
          mixpanelTrack(`Project Details File Removed`, {
            "File name": label
          });
          return removeSubmissionDetail(values?.creditAppId, id).then(() => {
            let updatedDocs = (creditApp[propertyName] ?? []).filter(
              f => f.label !== label
            );
            return setCreditAppState({ [propertyName]: updatedDocs });
          });
        })
        .catch(err => {
          setErrorMessageAndOpen(
            `Error on removing asset information file [203.2] - ${
              err?.message ?? err
            } (${DateTime.utc().toString()})`
          );
        });

  const [initialProjectName, setInitialProjectName] = useState(
    values.projectName
  );
  return !values?.financingScenarioId ? (
    <LoadingModal />
  ) : (
    <div
      className={classNames(
        "rounded-[4px] text-primary-darkGray bg-white s16 px-[32px] mt-[14px] pt-[24px] pb-[40px] flex justify-center items-center shadow-darkZ1"
      )}
    >
      <div className="flex block w-full h-full">
        <div className="flex flex-col gap-[16px] w-1/2 px-[8px]">
          <h4>
            {values.isArchived && (
              <span className="font-semibold">Archived - </span>
            )}
            Project Information
          </h4>
          <p className="italic">All fields are required</p>
          <div className="w-full">
            <Label>Project Name</Label>
            <TextInput
              name="projectName"
              id="projectName"
              className={classNames({
                inputField: true,
                error: hasError("projectName")
              })}
              placeholder="Type project name"
              value={values.projectName}
              valid={!hasError("projectName")}
              onChange={e => {
                setFieldValue("projectName", e.target.value);
                setCreditAppState({
                  submissionLabel: e.target.value
                });
                validationOnChanged("projectName", e.target.value);
              }}
              onFocus={() => setInitialProjectName(values.projectName)}
              disabled={values.isArchived}
              onBlur={e => {
                if (!values.projectName?.trim()) {
                  setFieldValue("projectName", initialProjectName);
                  return Promise.all([
                    updateFinancingScenario({
                      projectName: initialProjectName
                    }),
                    setCreditAppState({ submissionLabel: initialProjectName })
                  ]);
                } else return handleBlur(e);
              }}
            />
          </div>
          <div className="flex gap-[24px]">
            <div className="w-1/2 inputField">
              <label>Project Cost</label>
              <NumberInput
                name="projectCost"
                id="projectCost"
                size="noLimit"
                value={values.projectCost}
                onChange={e => {
                  setFieldValue("projectCost", e.target.value);
                  validationOnChanged("projectCost", e.target.value);
                  setCreditAppState({ financingAmount: e.target.value });
                }}
                onBlur={e => {
                  infoAssistOn && setFieldFocused("");
                  handleBlur(e);
                }}
                onFocus={() => infoAssistOn && setFieldFocused("projectCost")}
                valid={!hasError("projectCost")}
                disabled={values.isArchived}
              />
            </div>
            <div className="w-1/2 inputField">
              <Label>Annual Savings</Label>
              <NumberInput
                name="costSavings"
                size="noLimit"
                id="costSavings"
                value={values.costSavings}
                onChange={e => {
                  setFieldValue("costSavings", e.target.value);
                  validationOnChanged("costSavings", e.target.value);
                  setCreditAppState({ costSavings: e.target.value });
                }}
                onBlur={e => {
                  infoAssistOn && setFieldFocused("");
                  handleBlur(e);
                }}
                onFocus={() => infoAssistOn && setFieldFocused("costSavings")}
                valid={!hasError("costSavings")}
                disabled={values.isArchived}
              />
            </div>
          </div>
          <div className="flex gap-[24px]">
            <div className="w-1/2 selectField">
              <Label>Project Type</Label>
              <AutocompleteInput
                className={classNames({
                  "p-1 pr-8": true,
                  error: hasError("projectType")
                })}
                name="projectType"
                id="projectType"
                placeholder="Select project type"
                autocompleteData={projectTypeOptions}
                defaultValue={null}
                getOptionLabel={o => o.label ?? ""}
                value={projectTypeOptions.find(
                  t =>
                    t.value === values?.projectType ||
                    (!t.value && !values.projectType)
                )}
                maxResults={5}
                onBlur={handleBlur}
                valid={!hasError("projectType")}
                onSelected={selected => {
                  setFieldValue("projectType", selected.value);
                  setFieldValue("constructionPeriodLength", 3);
                  if (selected.value !== "Solar") {
                    setFieldValue("termLength", 5);
                    if (values.incentives?.some(i => i.name === "ITC")) {
                      const updatedIncentives = values.incentives;
                      updatedIncentives.splice(
                        values.incentives.findIndex(i => i.name === "ITC"),
                        1
                      );
                      setFieldValue("incentives", updatedIncentives);
                    }
                  } else if (values.incentives?.every(i => i.name !== "ITC")) {
                    setFieldValue("incentives", [
                      ...(values.incentives ?? []),
                      {
                        name: "ITC",
                        percentage: 30,
                        amount: (+values.projectCost ?? 0) * 0.3,
                        useInPayments: true
                      }
                    ]);
                  }
                  validationOnChanged("projectType", selected.value);
                }}
                disabled={values.isArchived}
              />
            </div>
            <div className="w-1/2 selectField">
              <Label>Financing Option</Label>
              <AutocompleteInput
                className="w-1/2 p-1 pr-8"
                name="financingOption"
                id="financingOption"
                placeholder="Select financing option"
                autocompleteData={financingOptions}
                defaultValue={null}
                getOptionLabel={o => o.label ?? ""}
                value={financingOptions[0]}
                maxResults={5}
                onBlur={handleBlur}
                disabled={values.isArchived}
              />
            </div>
          </div>
          <div className="flex gap-[24px]">
            <div className="w-1/2 selectField">
              <Label>Country</Label>
              <AutocompleteInput
                className={classNames({
                  "p-1 pr-8": true,
                  error: hasError("countryCode")
                })}
                name="countryCode"
                id="countryCode"
                placeholder="Type or select an option"
                autocompleteData={countryCodes}
                defaultValue={null}
                getOptionLabel={o => o.label ?? ""}
                value={countryCodes.find(
                  t =>
                    t.value === values?.countryCode ||
                    (!t.value && !values.countryCode)
                )}
                onBlur={handleBlur}
                onSelected={selected => {
                  const updatedIncentives = values.incentives;
                  const depreciationIncentive = updatedIncentives?.find(i =>
                    isDepreciationIncentive(i.name)
                  );
                  if (depreciationIncentive) {
                    depreciationIncentive.amount = 0;
                    depreciationIncentive.federalTaxRate = null;
                    depreciationIncentive.stateTaxRate = null;
                    depreciationIncentive.name =
                      selected.value === "CA" ? "CCA" : "MACRS";
                  }
                  setValues({
                    ...values,
                    countryCode: selected.value,
                    countryName: selected.label,
                    state: "",
                    incentives: updatedIncentives
                  });
                  setCreditAppState({
                    countryCode: selected.value,
                    state: ""
                  });
                }}
                valid={!hasError("countryCode")}
                disabled={values.isArchived}
              />
            </div>
            <div className="w-1/2 selectField">
              <Label>State/Region/Province</Label>
              <AutocompleteInput
                className={classNames({
                  "p-1 pr-8": true,
                  error: hasError("state")
                })}
                name="state"
                id="state"
                placeholder="Type or select an option"
                autocompleteData={provinces}
                defaultValue={null}
                getOptionLabel={o => o.label ?? ""}
                value={provinces.find(
                  t => t.value === values?.state || (!t.value && !values.state)
                )}
                onBlur={handleBlur}
                onSelected={async selected => {
                  setFieldValue("state", selected.value);
                  setCreditAppState({ state: selected.value });
                  setFieldValue(
                    "utilityCostEscalator",
                    getUtilityEscalatorRate(selected.value)
                  );
                  selected.value !== "" &&
                    (await updateDepreciationRates(
                      values.incentives,
                      values.countryCode,
                      selected.value
                    ));
                  validationOnChanged("state", selected.value);
                }}
                valid={!hasError("state")}
                disabled={values.isArchived}
              />
            </div>
          </div>
          <div>
            <h6 className="my-3">Upload Asset Information</h6>
            <UploadProjectFiles
              id={"assetDocuments"}
              files={creditApp.assetDocuments}
              setFiles={onChangeFiles("assetDocuments")}
              onRemoveFile={onRemoveFile("assetDocuments")}
              valid={!hasError("assetDocuments")}
              creditAppId={values.creditAppId}
              mixpanelTrack={mixpanelTrack}
              disabled={values.isArchived}
            />
          </div>
        </div>
        <div className="w-1/2 px-[32px] pt-[24px] pb-[56px] flex flex-col gap-[24px]">
          {values.isArchived ? (
            <div className="flex gap-[24px] rounded-[16px] shadow-darkZ8 p-[24px]">
              <div className="flex flex-col gap-[16px]">
                <h4>Archived Project</h4>
                <p>
                  When restored, this project will revert back to its previous
                  configuration.
                </p>
                <IconTextButton
                  className={classNames(
                    "round w-fit h-[36px] border border-secondary-darkGreen",
                    {}
                  )}
                  label={"Restore Project"}
                  onClick={onRestoreClicked}
                  icon={<ArchiveIcon />}
                />
              </div>
              <img src={ArchiveIllustration} />
            </div>
          ) : (
            <>
              <div className="flex gap-[24px] rounded-[16px] shadow-darkZ8 p-[24px]">
                <img src={ShareIllustration} />
                <div className="flex flex-col gap-[16px]">
                  <h4>Share Financing Application</h4>
                  <p>
                    Copy and share the financing application link with the
                    borrower.
                  </p>
                  <IconTextButton
                    outlined
                    className={classNames("round w-fit h-[36px]", {
                      "text-secondary-darkGreen": copyLinkClicked
                    })}
                    label={copyLinkClicked ? "Link copied!" : "Copy Link"}
                    onClick={async () =>
                      validateProjectDetails(ACTION_SHARE_CREDIT_APP).then(
                        valid => {
                          if (valid) {
                            return Promise.resolve({
                              creditAppId: values.creditAppId
                            })
                              .then(async res => {
                                if (res?.creditAppId) {
                                  const link = `${process.env.REACT_APP_ARLO_URL}/creditApp/${res?.creditAppId}`;
                                  navigator.clipboard.writeText(link);
                                  setCopyLinkClicked(true);
                                  mixpanelTrack(
                                    "Financing Application Sharing Link Copied"
                                  );
                                  return Promise.all([
                                    sleep(2500),
                                    ...(!values.firstPartnerToShareCreditApp
                                      ? [
                                          updateFinancingScenario({
                                            firstPartnerToShareCreditApp: `${userLoggedIn?.partnerRepName} <${userLoggedIn?.partnerRepEmail}>`
                                          }),
                                          values.projectCost >= 250000
                                            ? updateSubmissionDetails(
                                                values.creditAppId,
                                                getDefaultRequiredDocuments()
                                              )
                                            : updateCreditApp(null, {
                                                ...creditApp,
                                                personalGuaranteeRequired: true
                                              })
                                        ]
                                      : [Promise.resolve()])
                                  ]).then(() => {
                                    setCopyLinkClicked(false);
                                    if (!values.firstPartnerToShareCreditApp) {
                                      setFieldValue(
                                        "firstPartnerToShareCreditApp",
                                        `${userLoggedIn?.partnerRepName} <${userLoggedIn?.partnerRepEmail}>`
                                      );
                                    }
                                  });
                                }
                              })
                              .catch(() =>
                                setErrorMessageAndOpen(
                                  `Error creating credit app [200] - ${DateTime.utc().toString()}`
                                )
                              );
                          }
                        }
                      )
                    }
                    icon={copyLinkClicked ? null : <LinkIcon />}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] rounded-[16px] shadow-darkZ8 p-[24px]">
                <div className="flex flex-col gap-[16px]">
                  <h4>Build a Project Estimate</h4>
                  <p>
                    Build an estimate to help your customer understand the
                    overall cost with financing.
                  </p>
                  <IconTextButton
                    outlined
                    className="round w-fit"
                    label="Build an Estimate"
                    icon={<CalculateIcon />}
                    onClick={() => {
                      mixpanelTrack("Build Estimate Clicked");
                      return validateProjectDetails(ACTION_BUILD_ESTIMATE).then(
                        valid => valid && onBuildEstimateClicked()
                      );
                    }}
                  />
                </div>
                <img src={BuildEstimateIllustration} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailsFeature;

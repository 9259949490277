import React from "react";
import "./styles.scss";
import classNames from "classnames";
import { GoBackToProjectsList } from "../FormNavigator";
import { useNavigate } from "react-router-dom";

function FeatureNavigator({ items, onBackClicked, isArchived }) {
  const navigate = useNavigate();
  return (
    <div className="featureNavigator">
      <GoBackToProjectsList
        onBackClicked={onBackClicked}
        isArchived={isArchived}
        navigate={navigate}
      />
      <ul>
        {items.map((item, idx) => (
          <li
            onClick={item.onClick}
            id={item.label}
            className={classNames({ selected: item.isSelected })}
            key={`featureNavigatorOption-${idx}`}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FeatureNavigator;

import { AutocompleteInput, Label, TextInput } from "@enpowered/ui";
import React from "react";
import { CA_STATES, US_STATES } from "../../../../lib/constants";
import IconTextButton from "common/IconTextButton";
import UploadFiles from "common/UploadFiles";
import { DateTime } from "luxon";
import { getSignedUploadUrl, uploadFile } from "services/CreditApps";
import { FILE_ERROR, FILE_UPLOADING, FILE_VALIDATED } from "common/UploadFiles";
import classNames from "classnames";
import "./styles.scss";
import { deleteUploadedDocument } from "../../../../services/CreditApps";
import NumberInput from "common/NumberInput";
import { pickProps } from "../../../../utils/objectFunctions";
import IncentivesField from "../../../IncentivesField";
import { FILE_ERROR_BEFORE_UPLOADED } from "../../../BigUploadFiles";

function ProjectDetailsStep({
  mixpanelTrack,
  formik,
  updateCreditApp,
  setErrorMessageAndOpen,
  setIsMessageSnackBarOpen,
  proposal
}) {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    validateForm,
    setFieldValue,
    errors
  } = formik;
  const emptyOption = {};
  const countryCodes = [
    { label: "Canada", value: "CA" },
    { label: "United States", value: "US" }
  ];
  const financingOptions = [
    { label: "Loan", value: "Loan" },
    { label: "Power Purchase Agreement", value: "PPA" }
  ];
  const provinces = [{ value: "", label: "" }].concat(
    (values.creditAppProjectCountryCode === "CA" ? CA_STATES : US_STATES) ?? []
  );

  let onChangeFiles =
    propertyName =>
    async (files, remove = false) => {
      //filter removed files
      let currentFiles = values[propertyName] ?? [];
      //get new files
      let newFiles = (files ?? []).reduce(
        (filesToAdd, newFile) =>
          !currentFiles.some(curFile => newFile.name === curFile.filename)
            ? [...filesToAdd, newFile]
            : filesToAdd,
        []
      );
      return Promise.allSettled(
        remove
          ? [
              setFieldValue(propertyName, files).then(() =>
                updateCreditApp({ [propertyName]: files })
              )
            ]
          : newFiles.map(newFile => {
              console.info("file added:", newFile.name);
              let f = {
                filename: newFile.name,
                type: newFile.type,
                status: FILE_UPLOADING
              };
              currentFiles.push(f);
              return setFieldValue(propertyName, currentFiles)
                .then(() => updateCreditApp({ [propertyName]: currentFiles }))
                .then(() => getSignedUploadUrl(values.creditAppId, f.filename))
                .then(url => {
                  if (!url) {
                    console.error("Failed to get Signed url");
                    return Promise.reject(
                      "Failed to prepare document for upload"
                    );
                  } else {
                    f.status = FILE_UPLOADING;
                    setFieldValue(propertyName, currentFiles);
                    updateCreditApp({ [propertyName]: currentFiles });
                    return uploadFile(url, newFile);
                  }
                })
                .then(response => {
                  console.info("file uploaded:", {
                    filename: newFile.name,
                    response
                  });
                  f.status = FILE_VALIDATED;
                  setFieldValue(propertyName, currentFiles);
                  updateCreditApp({ [propertyName]: currentFiles });
                  mixpanelTrack("Project Details File Uploaded", {
                    "File name": f.name,
                    "File format": f.type
                  });
                })
                .catch(err => {
                  console.error("Error uploading asset information file", err);
                  f.status =
                    f.status === FILE_VALIDATED
                      ? FILE_ERROR
                      : FILE_ERROR_BEFORE_UPLOADED;
                  setFieldValue(propertyName, currentFiles);
                  updateCreditApp({ [propertyName]: currentFiles });
                  setErrorMessageAndOpen(
                    `Error uploading asset information file [202.2] - ${
                      err?.message ?? err
                    } (${DateTime.utc().toString()})`
                  );
                });
            })
      );
    };
  const onRemoveFile = propertyName => file =>
    (file?.status === FILE_ERROR_BEFORE_UPLOADED
      ? Promise.resolve()
      : deleteUploadedDocument(values.creditAppId, file.filename)
    )
      .then(() => {
        onChangeFiles(propertyName)(
          (values[propertyName] ?? []).filter(
            f => f.filename !== file.filename
          ),
          true
        );
        mixpanelTrack(`Project Details File Removed`, {
          "File format": file.type,
          "File name": file.filename
        });
      })
      .catch(err => {
        setErrorMessageAndOpen(
          `Error on removing asset information file [203.2] - ${
            err?.message ?? err
          } (${DateTime.utc().toString()})`
        );
      });

  return (
    <div className="creditApp-projectDetailsStep twoSides">
      <div className="leftSide">
        <h4 className="font-normal">Project Information</h4>
        <p>
          <i>All fields are required unless marked as {"Optional"}</i>
        </p>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Project Cost</Label>
            <NumberInput
              className={"numberInput"}
              name={"creditAppProjectCost"}
              id={"creditAppProjectCost"}
              value={values.creditAppProjectCost}
              onChange={handleChange}
              onBlur={e => {
                errors.creditAppProjectCost && handleBlur(e);
              }}
              size={null}
              valid={!errors.creditAppProjectCost}
            />
          </div>
          <div className="inputField w-1/2">
            <Label>First Year Annual Savings</Label>
            <NumberInput
              className={"numberInput"}
              name={"firstYearAnnualSavings"}
              id={"firstYearAnnualSavings"}
              placeholder="First Year Annual Savings"
              value={values.firstYearAnnualSavings}
              onChange={handleChange}
              onBlur={e => {
                errors.firstYearAnnualSavings && handleBlur(e);
              }}
              size={null}
              valid={!errors.firstYearAnnualSavings}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Financing Option</Label>
            <AutocompleteInput
              className="selectField p-1 pr-8"
              name="creditAppFinancingOption"
              id="creditAppFinancingOption"
              placeholder="Select financing option"
              autocompleteData={financingOptions}
              getOptionLabel={o => o.label}
              value={financingOptions[0]}
              maxResults={5}
              valid={!errors.creditAppFinancingOption}
              onBlur={e => {
                errors.creditAppFinancingOption && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField short maxShort w-1/2">
            <Label>Preferred Term (optional)</Label>
            <TextInput
              name="creditAppPreferredTermLength"
              id="creditAppPreferredTermLength"
              placeholder={"XX (in years)"}
              value={values.creditAppPreferredTermLength}
              onChange={handleChange}
              valid={!errors.creditAppPreferredTermLength}
              onBlur={e => {
                errors.creditAppPreferredTermLength && handleBlur(e);
              }}
            />
          </div>
        </div>
        <div className="incentivesDescriptionDiv">
          <Label>
            Project Incentives
            <br />
            <span className="s14 italic font-semibold">
              Updated within the Project Estimate
            </span>
          </Label>
          <IncentivesField
            incentives={proposal.incentives}
            onClick={() => {}}
            disabled={true}
          />
        </div>
        <div className="flex gap-4">
          <div className="w-1/2">
            <Label>Est. Construction Start</Label>
            <p>
              {DateTime.fromISO(proposal.estimatedConstructionStartDate)
                ?.isValid &&
                DateTime.fromISO(
                  proposal.estimatedConstructionStartDate
                ).toFormat("MMMM dd, yyyy")}
            </p>
          </div>
          <div className="w-1/2">
            <Label>Construction Period</Label>
            <p>
              {!proposal.constructionPeriodLength
                ? ""
                : proposal.constructionPeriodLength +
                  ` month${proposal.constructionPeriodLength !== 1 ? "s" : ""}`}
            </p>
          </div>
        </div>
        <div className="">
          <Label>
            Construction Progress Payments
            <br />
            <span className="s14 italic font-semibold">
              Updated within the Project Estimate
            </span>
          </Label>

          <div className="progressPayments">
            {Object.entries(proposal.progressPayments ?? {}).map(
              ([month, percentage], idx) => (
                <span
                  className="progressPayment"
                  key={"progressPayment-" + idx}
                >
                  {month === "final"
                    ? "Final Payment "
                    : "Month " + month + " "}{" "}
                  -{" "}
                  <span className="font-semibold">
                    {`${(+percentage).toFixed(0)} %`}
                  </span>
                </span>
              )
            )}
          </div>
        </div>
        <h4 className="font-normal">Full Service Address</h4>
        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Country</Label>
            <AutocompleteInput
              className={classNames({
                "selectField p-1 pr-8": true,
                error: errors.creditAppProjectCountryCode
              })}
              name="creditAppProjectCountryCode"
              id="creditAppProjectCountryCode"
              placeholder={`Type or select a country`}
              autocompleteData={[emptyOption].concat(countryCodes)}
              getOptionLabel={o => o.label ?? ""}
              value={
                countryCodes.find(
                  t => t.value === values?.creditAppProjectCountryCode
                ) ?? emptyOption
              }
              onBlur={handleBlur}
              onSelected={selected => {
                setFieldValue("creditAppProjectCountryCode", selected.value);
                setFieldValue("creditAppProjectCountryName", selected.label);
                setFieldValue("creditAppProjectState", "");
                errors.creditAppProjectCountryCode &&
                  validateForm({
                    ...values,
                    creditAppProjectCountryCode: selected.value
                  });
              }}
              valid={!errors.creditAppProjectCountryCode}
            />
          </div>
          <div className="inputField w-1/2">
            <Label>State/Region/Province</Label>
            <AutocompleteInput
              className={classNames({
                "selectField p-1 pr-8": true,
                error: errors.creditAppProjectState
              })}
              onBlur={handleBlur}
              name="creditAppProjectState"
              id="creditAppProjectState"
              placeholder={`Type or select a ${
                values.creditAppProjectCountryCode === "CA"
                  ? "province"
                  : "creditAppProjectState"
              }`}
              autocompleteData={[emptyOption].concat(provinces)}
              getOptionLabel={o => o.label ?? ""}
              value={
                provinces.find(
                  t => t.value === values?.creditAppProjectState
                ) ?? provinces.find(t => t.value === "")
              }
              onSelected={selected => {
                setFieldValue("creditAppProjectState", selected.value);
                errors.creditAppProjectState &&
                  validateForm({
                    ...values,
                    creditAppProjectState: selected.value
                  });
              }}
              valid={!errors.creditAppProjectState}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>Street Address</Label>
            <TextInput
              name="creditAppProjectStreetAddress"
              id="creditAppProjectStreetAddress"
              placeholder="Type the street address"
              value={values.creditAppProjectStreetAddress}
              valid={!errors.creditAppProjectStreetAddress}
              onChange={target => {
                handleChange(target);
              }}
              onBlur={e => {
                errors.creditAppProjectStreetAddress && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField short w-1/2">
            <Label>Unit Number</Label>
            <TextInput
              name="creditAppProjectUnitNumber"
              id="creditAppProjectUnitNumber"
              placeholder="Type the unit number"
              value={values.creditAppProjectUnitNumber}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.creditAppProjectUnitNumber}
              onBlur={e => errors.creditAppProjectUnitNumber && handleBlur(e)}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="inputField w-1/2">
            <Label>City / Town</Label>
            <TextInput
              name="creditAppProjectCity"
              id="creditAppProjectCity"
              placeholder="City / Town"
              value={values.creditAppProjectCity}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.creditAppProjectCity}
              onBlur={e => {
                errors.creditAppProjectCity && handleBlur(e);
              }}
            />
          </div>
          <div className="inputField short maxShort w-1/2">
            <Label>Zip / Postal Code</Label>
            <TextInput
              name="creditAppProjectPostalCode"
              id="creditAppProjectPostalCode"
              placeholder={
                values.creditAppProjectCountryCode === "US"
                  ? "12345"
                  : "A1B 2C3"
              }
              value={values.creditAppProjectPostalCode}
              onChange={target => {
                handleChange(target);
              }}
              valid={!errors.creditAppProjectPostalCode}
              onBlur={e => {
                errors.creditAppProjectPostalCode && handleBlur(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="rightSide">
        <h4 className="font-normal">Asset Information</h4>
        <div>
          <p className="mb-2">
            In order to properly assess this project for financing, please
            provide any document(s) that contain the following:
          </p>
          <ul className="list-disc ml-6">
            <li>
              Project cost breakdown (labor, services, bill of materials, etc.)
            </li>
            <li>Energy production reports</li>
            <li>Engineering system design</li>
            <li>Project construction plan</li>
          </ul>
        </div>
        <div className="borderGray w-full my-3"></div>
        <UploadFiles
          id={"assetDocuments"}
          title={"Asset Information"}
          subtitle={`Upload your documents containing the required information`}
          classNameButton={"borderNormalYellow"}
          files={values.assetDocuments}
          setFiles={onChangeFiles("assetDocuments")}
          removeFile={onRemoveFile("assetDocuments")}
          creditAppId={values.creditAppId}
          mixpanelTrack={mixpanelTrack}
        />
        <div className="flex justify-end flex-grow items-end">
          <IconTextButton
            filled
            primary
            className="borderYellow1 py-4"
            label={
              values.isProjectDetailsSubmitted
                ? "Resubmit Project Details"
                : "Submit Project Details"
            }
            onClick={e => {
              mixpanelTrack("Submit Project Details", {
                "Project Cost": values.creditAppProjectCost,
                "Annual Savings": values.firstYearAnnualSavings,
                "Financing Option": values.creditAppFinancingOption,
                "Preferred Term": values.creditAppPreferredTermLength,
                Incentives: proposal.incentives,
                "Est. Construction Start":
                  proposal.estimatedConstructionStartDate,
                "Construction Period": proposal.constructionPeriodLength,
                "Construction Progress Payments": proposal.progressPayments,
                "Service Address": Object.entries(
                  pickProps(
                    [
                      "creditAppProjectStreetAddress",
                      "creditAppProjectUnitNumber",
                      "creditAppProjectCity",
                      "creditAppProjectState",
                      "creditAppProjectPostalCode",
                      "creditAppProjectCountryName"
                    ],
                    values
                  ) ?? {}
                ).join(", ")
              });
              setIsMessageSnackBarOpen(true);
              handleSubmit(e);
            }}
          />
        </div>
        {values.isProjectDetailsSubmitted &&
          values.dateProjectDetailsSubmitted && (
            <div className="gray2 font-light flex justify-end">
              Submitted last on{" "}
              {DateTime.fromISO(values.dateProjectDetailsSubmitted).toFormat(
                "MMMM dd, yyyy @ h:mm a"
              )}
            </div>
          )}
      </div>
    </div>
  );
}

export default ProjectDetailsStep;

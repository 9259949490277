import React from "react";
import "./styles.scss";
import classNames from "classnames";
import { ReactComponent as LeftArrowIcon } from "assets/left-arrow.svg";
import IconTextButton from "../../common/IconTextButton";
import { ReactComponent as UnarchiveIcon } from "assets/unarchive.svg";
import ViewIcon from "assets/view.svg";
import NotViewIcon from "assets/not-view.svg";
import { Tooltip } from "@enpowered/ui";
import { getMixpanel } from "../../services/mixpanel";
import { START_STEP } from "..";

//view state
export const VIEW_TYPES = {
  FINANCING_SCHEDULE: "Financing Schedule",
  COLLATERAL: "Collateral"
};

export const GoBackToProjectsList = ({
  onBackClicked,
  isArchived,
  navigate = () => {},
  className
}) => {
  return (
    <Tooltip title={`Return to ${isArchived ? "Archive" : "Projects List"}`}>
      <IconTextButton
        icon={<LeftArrowIcon />}
        label={`Back to ${isArchived ? "Archive" : "Projects"}`}
        className={classNames(className, "font-normal round")}
        onClick={() => {
          typeof onBackClicked === "function" && onBackClicked();
          navigate(
            `${isArchived ? "/projects/archive" : "/projects"}${
              location.search
            }`
          );
        }}
      />
    </Tooltip>
  );
};

/**
 *
 * @param {Object<stepName: string, {stepLabel: string}>} props.steps
 * @param {string => any} props.steps
 * @param {boolean => any} props.setInfoAssistOn
 * @param {boolean} props.infoAssistOn
 * @param {string} props.currentStepName
 * @returns {JSX.Element}
 */
function FormNavigator({
  steps,
  onStepClicked,
  currentStepName,
  setStep,
  viewType,
  setViewType,
  otherActions = "",
  onRestoreClicked,
  proposal
  // setMessage
}) {
  // const [searchParams] = useSearchParams();
  // const partnerId = searchParams.get("id");
  const mixpanel = getMixpanel();
  const trackMixpanelEvent = viewType =>
    mixpanel.track("Proposal View Changed", {
      ["Changed to"]: viewType,
      PROJECT_ID: proposal.financingScenarioId,
      Partner: proposal?.partnerCompanyName
    });
  return (
    <div className="formNavigator">
      <ul>
        {Object.entries(steps).map(([stepName, step], idx) => (
          <li
            onClick={e => onStepClicked(e.target.id)}
            id={stepName}
            className={classNames({ selected: currentStepName === stepName })}
            key={`formNavigatorOption-${idx}`}
          >
            {step.stepLabel}
          </li>
        ))}
      </ul>

      <div className="mr-4 actions flex items-top gap-2">
        {proposal.isArchived ? (
          <IconTextButton
            icon={<UnarchiveIcon />}
            className="borderGrayLighter round"
            label={`Restore project`}
            onClick={onRestoreClicked}
          />
        ) : (
          <div className="flex">
            <Tooltip
              placement="top"
              title={`${
                viewType === VIEW_TYPES.FINANCING_SCHEDULE ? "Show" : "Hide"
              } Proposal preview`}
            >
              <IconTextButton
                icon={
                  <img
                    src={
                      viewType === VIEW_TYPES.FINANCING_SCHEDULE
                        ? ViewIcon
                        : NotViewIcon
                    }
                  />
                }
                className="round"
                label={`${
                  viewType === VIEW_TYPES.FINANCING_SCHEDULE
                    ? "Preview"
                    : "Hide"
                } Estimate`}
                onClick={async () => {
                  if (await steps[START_STEP].isValid()) {
                    currentStepName === START_STEP &&
                      setStep(steps[START_STEP].next);
                    setViewType(
                      viewType === VIEW_TYPES.FINANCING_SCHEDULE
                        ? VIEW_TYPES.COLLATERAL
                        : VIEW_TYPES.FINANCING_SCHEDULE
                    );
                    trackMixpanelEvent(
                      viewType === VIEW_TYPES.FINANCING_SCHEDULE
                        ? VIEW_TYPES.COLLATERAL
                        : VIEW_TYPES.FINANCING_SCHEDULE
                    );
                  }
                }}
                type="button"
                // className="iconButton"
              />
            </Tooltip>
          </div>
        )}
        {otherActions}
      </div>
    </div>
  );
}

export default FormNavigator;

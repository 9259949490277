import React, { useEffect, useState } from "react";
import TopBarNavigator from "../../../common/TopBarNavigator";
import classNames from "classnames";
// import ShareLink from "../../ShareLink";
// import { ReactComponent as SearchIcon } from "assets/search.svg";
import "./styles.scss";
import BusinessInfoStep from "../BusinessInfoStep";
import BusinessCompanySearchModal from "../BusinessCompanySearchModal";
import { Formik } from "formik";
import { DateTime } from "luxon";
import DocumentUploadStep from "../DocumentUploadStep";
import { TAB_FINANCING_APPLICATION } from "../CreditAppFeature";
import SnackBar from "../../../common/SnackBar";
import { getBusinessType, updateCreditApp } from "../../../services";
import ReviewStep from "../ReviewStep";
import * as Yup from "yup";
import SubmitConfirmationModal from "../SubmitConfirmationModal";
import CongratulationsPanel from "../CongratulationsPanel";
import { useQuery } from "react-query";
import HelpPanel from "../HelpPanel";

function CustomerCreditAppForm({
  mixpanelTrack,
  creditAppData,
  financingScenario,
  userEmail,
  onSubmissionConfirmed,
  refetchCreditApp,
  partnerCompanyName
}) {
  const [sectionSelected, setSectionSelected] = useState(0);
  const [
    isBusinessCompanySearchModalOpen,
    setIsBusinessCompanySearchModalOpen
  ] = useState(false);
  const [queryCompanyString, setQueryCompanyString] = useState("");
  const sections = ["Business Information", "Document Upload", "Review"];
  const [isSubmissionModalOpen, setIsSubmissionModalOpen] = useState(false);
  const [isCongratulationsOpened, setIsCongratulationsOpened] = useState(false);
  const [showHelpPanel, setShowHelpPanel] = useState(false);
  const [businessTypeIntermediateString, setBusinessTypeIntermediateString] =
    useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isMessageSnackBarOpen, setIsMessageSnackBarOpen] = useState(true);
  const setErrorMessageAndOpen = messages => {
    setErrorMessage(messages);
    setIsMessageSnackBarOpen(true);
  };
  const conditionalRequiredAddress = fieldName =>
    Yup.string(`${fieldName}: ${fieldName} is required`)
      .nullable()
      .test(
        "projectAddressIsDifferent",
        `${fieldName}: ${fieldName} is required`,
        (value, context) => {
          if (!context.parent.projectAddressIsDifferent) return true;
          console.log(context.parent, fieldName);
          return value && value.length > 0;
        }
      );
  const businessInformationSchema = Yup.object({
    businessLegalName: Yup.string().required(
      "businessLegalName: Business legal name is required"
    ),
    yearsInBusiness: Yup.number()
      .typeError("yearsInBusiness: Years in business must be a year number")
      .moreThan(
        -1,
        "yearsInBusiness: Years in business must be a positive number"
      )
      .required("yearsInBusiness: Years in business is required"),
    businessType: Yup.object({
      choice: Yup.string("businessType: Business type is required")
        .nullable()
        .test(
          "obtaining automatically",
          "businessType: Business type is required",
          businessType => businessType || businessTypeIntermediateString
        )
    }),
    contactName: Yup.string().required("contactName: Contact name is required"),
    contactEmail: Yup.string()
      .email("contactEmail: Contact email must be a valid email address")
      .required("contactEmail: Contact email is required"),
    contactPhoneNumber: Yup.string(),
    countryCode: Yup.string().required("countryCode: Country is required"),
    state: Yup.string().required("state: State is required"),
    streetAddress: Yup.string().required(
      "streetAddress: Street address is required"
    ),
    city: Yup.string().required("city: City is required"),
    postalCode: Yup.string().required("postalCode: Postal code is required"),
    customerProjectAddressCountryCode: conditionalRequiredAddress(
      "customerProjectAddressCountryCode"
    ),
    customerProjectAddressState: conditionalRequiredAddress(
      "customerProjectAddressState"
    ),
    customerProjectAddressCity: conditionalRequiredAddress(
      "customerProjectAddressCity"
    ),
    customerProjectAddressPostalCode: conditionalRequiredAddress(
      "customerProjectAddressPostalCode"
    ),
    customerProjectAddressStreetAddress: conditionalRequiredAddress(
      "customerProjectAddressStreetAddress"
    )
  });

  const mixpanelTrackConfirmSelectedBusiness = company =>
    mixpanelTrack("Confirm Selected Business", {
      "Incorporation Date": company?.incorporation_date,
      "Jurisdiction Code": company?.jurisdiction_code,
      "Current status": company?.current_status,
      "Company Name": company?.name,
      "Dissolution Date": company?.dissolution_date,
      "Registered Address": company?.registered_address_in_full,
      "Company Type": company?.company_type
    });

  const getWasAnyBusinessInfoAdded = creditAppObject =>
    !!creditAppObject.businessLegalName ||
    !!creditAppObject.yearsInBusiness ||
    !!creditAppObject.businessType?.choice ||
    !!creditAppObject.streetAddress ||
    !!creditAppObject.city ||
    !!creditAppObject.state ||
    !!creditAppObject.postalCode ||
    !!creditAppObject.countryCode ||
    !!creditAppObject.unitNumber ||
    !!creditAppObject.contactPhoneNumber ||
    !!creditAppObject.contactEmail ||
    !!creditAppObject.contactName ||
    !!creditAppObject.customerProjectAddressCountryCode ||
    !!creditAppObject.customerProjectAddressState ||
    !!creditAppObject.customerProjectAddressCity ||
    !!creditAppObject.customerProjectAddressPostalCode ||
    !!creditAppObject.customerProjectAddressStreetAddress ||
    !!creditAppObject.customerProjectAddressUnitNumber;

  return isCongratulationsOpened ? (
    <CongratulationsPanel
      partnerCompanyName={partnerCompanyName}
      setIsCongratulationsOpened={value => {
        refetchCreditApp();
        setIsCongratulationsOpened(value);
      }}
      mixpanelTrack={mixpanelTrack}
      isPartnerView={false}
    />
  ) : (
    <Formik
      initialValues={{
        businessLegalName: "",
        ...creditAppData,
        financialDocuments: creditAppData?.financialDocuments ?? [],
        otherDocuments: creditAppData?.otherDocuments ?? []
      }}
      enableReinitialize
      validateOnChange={false}
    >
      {formik => {
        const { values: creditApp = {}, setValues, setFieldValue } = formik;
        const [errors, setErrors] = useState({});
        const update = async (extraProperties = {}) => {
          const userEmail = localStorage.getItem("currentUserEmail");
          if (creditApp.creditAppId && userEmail)
            return updateCreditApp(userEmail, {
              ...creditApp,
              ...extraProperties,
              isCustomerView: true,
              formUpdated: TAB_FINANCING_APPLICATION
            });
          throw new Error("Credit app id or user email not found");
        };
        const handleSubmit = () => {
          setIsSubmissionModalOpen(true);
        };
        const [showBusinessInfoForm, setShowBusinessInfoForm] = useState(false);
        useEffect(() => {
          if (
            getWasAnyBusinessInfoAdded(creditAppData) &&
            !showBusinessInfoForm
          )
            setShowBusinessInfoForm(true);
        }, [creditAppData?.creditAppId]);

        useQuery(
          `get-businessType-${businessTypeIntermediateString}`,
          () =>
            businessTypeIntermediateString &&
            getBusinessType(businessTypeIntermediateString)
              .then(res => {
                if (
                  !!res?.company_type &&
                  res.company_type !== "Undetermined"
                ) {
                  setFieldValue("businessType", { choice: res.company_type });
                  !creditApp.businessTypeObtainedAutomatically &&
                    setFieldValue("businessTypeObtainedAutomatically", true);
                } else return Promise.reject();
              })
              .catch(() => {
                creditApp.businessTypeObtainedAutomatically &&
                  setFieldValue("businessTypeObtainedAutomatically", false);
                setFieldValue("businessType", {});
              })
              .finally(() => {
                update();
              })
        );

        const validateBusinessInfo = businessInfo =>
          businessInformationSchema
            .validate(businessInfo, { abortEarly: false })
            .then(() => {
              setErrors({});
              setErrorMessage("");
            })
            .catch(err => {
              console.info({ validationError: err });
              setErrors(Object.fromEntries(err.errors.map(e => e.split(": "))));
              return Promise.reject(err);
            });
        const checkAndCleanUpProjectAddressFields = () => {
          !creditApp.projectAddressIsDifferent &&
            setValues({
              ...creditApp,
              customerProjectAddressCountryCode: null,
              customerProjectAddressState: null,
              customerProjectAddressCity: null,
              customerProjectAddressPostalCode: null,
              customerProjectAddressStreetAddress: null,
              customerProjectAddressUnitNumber: null
            });
        };

        const submit = values =>
          onSubmissionConfirmed(values)
            .then(() => setIsCongratulationsOpened(true))
            .catch(err =>
              setErrorMessageAndOpen(
                `Error when submitting application [205] - ${
                  err?.message ?? err
                } (${DateTime.utc().toString()})`
              )
            );

        return (
          <form onBlur={() => update()}>
            <SnackBar
              messages={[
                ...(Object.entries(errors)?.length
                  ? ["The fields in red require a valid input"]
                  : []),
                ...(errorMessage?.length ? [errorMessage] : [])
              ]}
              setIsOpen={isOpen => {
                !isOpen && setErrorMessage("");
                setIsMessageSnackBarOpen(isOpen);
              }}
              isOpen={isMessageSnackBarOpen}
              type={"error"}
              duration={5000}
            />
            <SubmitConfirmationModal
              isOpen={isSubmissionModalOpen}
              setIsOpen={setIsSubmissionModalOpen}
              onConfirmed={() => submit(creditApp)}
              mixpanelTrack={mixpanelTrack}
              isSubmitted={creditApp.isSubmitted}
              financialDocuments={creditApp.financialDocuments}
            />
            <HelpPanel
              setIsOpen={value => {
                !value && mixpanelTrack("Credit App Close Help Page");
                setShowHelpPanel(value);
              }}
              open={showHelpPanel}
            />
            <div className="w-full flex flex-col items-center my-0 mx-auto">
              <BusinessCompanySearchModal
                setIsOpen={setIsBusinessCompanySearchModalOpen}
                isOpen={isBusinessCompanySearchModalOpen}
                queryCompanyString={queryCompanyString}
                countryCode={financingScenario?.countryCode}
                mixpanelTrack={mixpanelTrack}
                onConfirm={company => {
                  if (company) {
                    let updatedValues = {
                      ...creditApp,
                      businessLegalName: company.name ?? "",
                      countryCode: company.countryCode ?? "",
                      state: company.state ?? "",
                      streetAddress:
                        company?.registered_address?.street_address ?? "",
                      postalCode:
                        company?.registered_address?.postal_code ?? "",
                      city: company?.registered_address?.locality ?? "",
                      unitNumber: "",
                      ...(DateTime.fromISO(company?.incorporation_date ?? "")
                        .isValid
                        ? {
                            yearsInBusiness: Math.trunc(
                              Math.abs(
                                DateTime.fromISO(
                                  company?.incorporation_date
                                ).diffNow("years").years
                              )
                            ),
                            yearsInBusinessObtainedAutomatically: true
                          }
                        : {
                            yearsInBusinessObtainedAutomatically: false
                          }),
                      ...(company?.company_type
                        ? {
                            rawBusinessType: company?.company_type
                          }
                        : {
                            businessTypeObtainedAutomatically: false,
                            businessType: {}
                          }),
                      ...(company?.inactive
                        ? {
                            rawInactiveStatus: company.inactive,
                            rawRetrievedAt: company.retrieved_at
                          }
                        : {
                            rawInactiveStatus: undefined
                          }),
                      ...(company?.registry_url
                        ? {
                            rawRegistryUrl: company.registry_url
                          }
                        : {
                            rawRegistryUrl: undefined
                          })
                    };
                    setValues(updatedValues);
                    update(updatedValues);

                    setBusinessTypeIntermediateString(company?.company_type);
                    setIsBusinessCompanySearchModalOpen(false);
                    setShowBusinessInfoForm(true);
                    mixpanelTrackConfirmSelectedBusiness(company);
                  }
                }}
                onEnterManually={() => {
                  setValues({
                    ...creditApp,
                    ...(creditApp.businessTypeObtainedAutomatically
                      ? {
                          businessType: {},
                          businessTypeObtainedAutomatically: false
                        }
                      : {}),
                    ...(creditApp.yearsInBusinessObtainedAutomatically
                      ? {
                          yearsInBusiness: null,
                          yearsInBusinessObtainedAutomatically: false
                        }
                      : {})
                  });
                  setBusinessTypeIntermediateString("");
                  setIsBusinessCompanySearchModalOpen(false);
                  setShowBusinessInfoForm(true);
                }}
              />
              <TopBarNavigator
                items={sections}
                currentItem={sectionSelected}
                setCurrentItem={selected => {
                  sectionSelected === 0
                    ? validateBusinessInfo(creditApp)
                        .then(() => {
                          checkAndCleanUpProjectAddressFields();
                          setSectionSelected(selected);
                        })
                        .catch(() => {
                          setErrorMessageAndOpen();
                        })
                    : setSectionSelected(selected);
                }}
                className={"flex flex-col justify-center"}
              />
              <div
                className={classNames(
                  "creditApp-form w-full flex justify-center items-center",
                  {
                    partnerView: true
                  }
                )}
              >
                {sectionSelected === 0 ? (
                  <BusinessInfoStep
                    queryString={queryCompanyString}
                    setQueryString={setQueryCompanyString}
                    creditApp={creditApp}
                    setBusinessCompanySearchModalOpen={() =>
                      setIsBusinessCompanySearchModalOpen(true)
                    }
                    goToNextSection={() =>
                      validateBusinessInfo(creditApp)
                        .then(() => {
                          checkAndCleanUpProjectAddressFields();
                          setSectionSelected(sectionSelected + 1);
                        })
                        .catch(() => {
                          setErrorMessageAndOpen();
                        })
                    }
                    showBusinessInfoForm={showBusinessInfoForm}
                    setShowBusinessInfoForm={setShowBusinessInfoForm}
                    getWasAnyBusinessInfoAdded={getWasAnyBusinessInfoAdded}
                    formik={formik}
                    mixpanelTrack={mixpanelTrack}
                    setErrorMessageAndOpen={setErrorMessageAndOpen}
                    setErrorMessage={setErrorMessage}
                    errors={errors}
                    businessTypeIntermediateString={
                      businessTypeIntermediateString
                    }
                  />
                ) : sectionSelected === 1 ? (
                  <DocumentUploadStep
                    creditApp={creditApp}
                    goToNextSection={() =>
                      setSectionSelected(sectionSelected + 1)
                    }
                    goToPreviousSection={() =>
                      setSectionSelected(sectionSelected - 1)
                    }
                    updateCreditApp={update}
                    setErrorMessageAndOpen={setErrorMessageAndOpen}
                    setFieldValue={setFieldValue}
                    mixpanelTrack={mixpanelTrack}
                    setShowHelpPanel={setShowHelpPanel}
                  />
                ) : (
                  <ReviewStep
                    creditApp={creditApp}
                    goToPreviousSection={() =>
                      setSectionSelected(sectionSelected - 1)
                    }
                    updateCreditApp={update}
                    setErrorMessageAndOpen={setErrorMessageAndOpen}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    setErrors={setErrors}
                    handleSubmit={handleSubmit}
                    mixpanelTrack={mixpanelTrack}
                    userEmail={userEmail}
                  />
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default CustomerCreditAppForm;

import React from "react";
import classNames from "classnames";
import "./styles.scss";

import Logo from "assets/Logo_White_Bg.png";

/**
 *
 * @param {object} props
 * @returns
 */
export const CustomerFacingTopBar = ({ partnerCompany = {}, className }) => {
  return (
    <div
      className={classNames(
        "customerFacingTopBar flex justify-between items-center",
        className
      )}
    >
      <div className="mr-6">
        {partnerCompany.partnerLogoUrl ? (
          <img
            className="h-18"
            src={partnerCompany.partnerLogoUrl}
            alt="logo"
          />
        ) : (
          <span>{partnerCompany.partnerCompanyName ?? " "}</span>
        )}
      </div>
      <h3 className="text-center">Financing Application</h3>
      <div className="flex items-center">
        <span className="s12">Supported by</span>
        <img className="h-18" src={Logo} alt="logo" />
      </div>
    </div>
  );
};

CustomerFacingTopBar.defaultProps = {};

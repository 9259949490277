import React from "react";
import ReviewQuestion from "../ReviewQuestion";
import { getTaskIcon } from "../CreditAppForm/FinancingApplicationStep";
import { ReactComponent as UploadCloud } from "assets/upload-cloud.svg";
import "./styles.scss";
import useCreditAppTasks from "../../../hooks/useCreditAppTasks";

/**
 *
 * @param {[questionName: string, reasons: string[]]} question
 * @returns
 */
const OPTION_DISAGREE = "disagree";
const OPTION_DONT_HAVE = "dontHave";

export const getReviewQuestionBody =
  ({
    creditApp = {},
    setFieldValue,
    errors,
    goToDocumentUpload,
    mixpanelTrack,
    updateCreditApp
  }) =>
  question =>
    ({
      businessType: (
        <ReviewQuestion
          options={[
            { value: "Corporation", label: "Corporation" },
            {
              value: "Proprietorship",
              label: "Proprietorship"
            },
            { value: "Partnership", label: "Partnership" },
            {
              value: "Not-for-profit",
              label: "Not-for-profit"
            },
            { value: "LLC", label: "LLC" },
            { value: "Other", label: "Other" }
          ]}
          title={"Business Type"}
          paragraph={<div>A business type was not selected</div>}
          currentValue={creditApp.businessType}
          errorMessage={
            errors.businessType && "What category does the business fall under?"
          }
          setCurrentValue={val => {
            setFieldValue("businessType", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ businessType: val });
          }}
        ></ReviewQuestion>
      ),
      reviewCanProvideGuarantee: (
        <ReviewQuestion
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
            {
              label: "I disagree with this analysis",
              value: OPTION_DISAGREE,
              requireExplanation: true
            }
          ]}
          label={"Are you willing to provide a personal guarantee?"}
          title={"Personal Guarantee Required"}
          paragraph={
            <div>
              <p>
                Based on the information you provided, funders will require a
                personal guarantee for the following reasons:
              </p>
              <ul className="list-disc pl-6 pt-3">
                {question.reasons?.map((reason, idx) => (
                  <li key={`questionReason-${idx}`}>{reason}</li>
                ))}
              </ul>
            </div>
          }
          currentValue={creditApp.reviewCanProvideGuarantee}
          errorMessage={
            errors.reviewCanProvideGuarantee &&
            "Share your response for the personal guarantee"
          }
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Personal Guarantee Question Answered", {
              Answer: answer.choice,
              "Reason text": answer.explanation,
              Triggers: question.reasons
            })
          }
          setCurrentValue={val => {
            setFieldValue("reviewCanProvideGuarantee", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewCanProvideGuarantee: val });
          }}
        ></ReviewQuestion>
      ),
      reviewDebtIncreaseReason: (
        <ReviewQuestion
          options={[
            { label: "reason", value: "reason", requireExplanation: true }
          ]}
          label={"What is the reason for the debt increase?"}
          title={"Debt Increase"}
          paragraph={question.reasons?.[0] ?? ""}
          currentValue={creditApp.reviewDebtIncreaseReason}
          errorMessage={
            errors.reviewDebtIncreaseReason &&
            "Provide the reason for the debt increase"
          }
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Debt Increase Question Answered", {
              "Reason text": answer.explanation
            })
          }
          setCurrentValue={val => {
            setFieldValue("reviewDebtIncreaseReason", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewDebtIncreaseReason: val });
          }}
        />
      ),
      reviewRevenueDecreaseReason: (
        <ReviewQuestion
          options={[
            { label: "reason", value: "reason", requireExplanation: true }
          ]}
          label={"What is the reason for the revenue decrease?"}
          title={"Revenue Decrease"}
          paragraph={question.reasons?.[0] ?? ""}
          currentValue={creditApp.reviewRevenueDecreaseReason}
          errorMessage={
            errors.reviewRevenueDecreaseReason &&
            "Specify the cause of the revenue decrease"
          }
          setCurrentValue={val => {
            setFieldValue("reviewRevenueDecreaseReason", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewRevenueDecreaseReason: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Revenue Decrease Question Answered", {
              "Reason text": answer.explanation
            })
          }
        />
      ),
      reviewUnsettledObligations: (
        <ReviewQuestion
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" }
          ]}
          label={""}
          title={"Legal Risk"}
          paragraph={
            "Are there any unsettled lawsuits, judgments, disputes, or outstanding tax obligations?"
          }
          currentValue={creditApp.reviewUnsettledObligations}
          errorMessage={
            errors.reviewUnsettledObligations &&
            "State if there are any unsettled legal issues"
          }
          setCurrentValue={val => {
            setFieldValue("reviewUnsettledObligations", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewUnsettledObligations: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Legal Risk Question Answered", {
              Answer: answer.choice
            })
          }
        ></ReviewQuestion>
      ),
      reviewFilledBankruptcy: (
        <ReviewQuestion
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" }
          ]}
          label={""}
          title={"Bankruptcy"}
          paragraph={"Has the business ever filed for bankruptcy?"}
          currentValue={creditApp.reviewFilledBankruptcy}
          errorMessage={
            errors.reviewFilledBankruptcy &&
            "Confirm if the business has ever filed for bankruptcy."
          }
          setCurrentValue={val => {
            setFieldValue("reviewFilledBankruptcy", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewFilledBankruptcy: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Bankruptcy Question Answered", {
              Answer: answer.choice
            })
          }
        ></ReviewQuestion>
      ),
      reviewNoPropertyOwnershipFound: (
        <ReviewQuestion
          options={[{ label: "reason", value: "", requireExplanation: true }]}
          label={
            "Who owns the property, and what is the relationship with the owner(s)?"
          }
          title={"Property Ownership"}
          paragraph={
            <>
              <p>
                It looks like we {"couldn't"} verify the ownership of the
                property where the project is located.
              </p>
            </>
          }
          currentValue={creditApp.reviewNoPropertyOwnershipFound}
          errorMessage={
            errors.reviewNoPropertyOwnershipFound &&
            "Indicate the details of the property ownership"
          }
          setCurrentValue={val => {
            setFieldValue("reviewNoPropertyOwnershipFound", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewNoPropertyOwnershipFound: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Property Ownership Question Answered", {
              "Reason text": answer.explanation
            })
          }
        ></ReviewQuestion>
      ),
      reviewPropertyOwnership: (
        <ReviewQuestion
          options={[{ label: "reason", value: "", requireExplanation: true }]}
          label={"What is the relationship to the property owners?"}
          title={"Property Ownership"}
          paragraph={
            <>
              <p>
                It looks like there are different owner(s) of the property where
                the project is located:
              </p>
              <ul className="list-disc pl-6 pt-3">
                {question.reasons?.map((reason, idx) => (
                  <li key={`reviewPropertyOwnership-reason-${idx}`}>
                    {reason}
                  </li>
                ))}
              </ul>
            </>
          }
          currentValue={creditApp.reviewPropertyOwnership}
          errorMessage={
            errors.reviewPropertyOwnership &&
            "Indicate if all property owners approve the installation."
          }
          setCurrentValue={val => {
            setFieldValue("reviewPropertyOwnership", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewPropertyOwnership: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Property Ownership Question Answered", {
              "Reason text": answer.explanation,
              Owners: question.reasons
            })
          }
        ></ReviewQuestion>
      ),
      reviewFinancialDocuments: (
        <ReviewQuestion
          options={[
            {
              label: "I do not have all the documents",
              value: OPTION_DONT_HAVE,
              requireExplanation: true
            },
            {
              label: "I disagree with this analysis",
              value: OPTION_DISAGREE,
              requireExplanation: true
            }
          ]}
          label={"Required Documents"}
          title={"Supporting Documents Required"}
          paragraph={
            <>
              <p>
                To continue with the application, the following documents are
                needed:
              </p>
              {(() => {
                const { getRelevantCharacteristics } = useCreditAppTasks();
                const {
                  incomeStatement1,
                  incomeStatement2,
                  balanceSheet1,
                  balanceSheet2,
                  taxReturn1,
                  taxReturn2
                } = getRelevantCharacteristics(creditApp?.financialDocuments);
                return (
                  <div className="py-4 mx-auto my-0 w-full gap-2 grid grid-cols-2">
                    <span>
                      {getTaskIcon(incomeStatement2)}
                      Income Statements 2023
                    </span>
                    <span>
                      {getTaskIcon(incomeStatement1)}
                      Income Statements 2022
                    </span>
                    <span>
                      {getTaskIcon(balanceSheet2)}
                      Balance Sheet 2023
                    </span>
                    <span>
                      {getTaskIcon(balanceSheet1)}
                      Balance Sheet 2022
                    </span>
                    <span
                      className={
                        (incomeStatement2 === "internal" ||
                        balanceSheet2 === "internal"
                          ? "fadein"
                          : "fadeout") + " flex items-center gap-1"
                      }
                    >
                      {getTaskIcon(taxReturn2)} Tax Returns 2023
                    </span>
                    <span
                      className={
                        (incomeStatement1 === "internal" ||
                        balanceSheet1 === "internal"
                          ? "fadein"
                          : "fadeout") + " flex items-center gap-1"
                      }
                    >
                      {getTaskIcon(taxReturn1)} Tax Returns 2022
                    </span>
                  </div>
                );
              })()}
              <p
                className="text-text-link pt-3 px-1 cursor-pointer"
                onClick={goToDocumentUpload}
              >
                <UploadCloud className="uploadIcon inline-block" />{" "}
                <span className="underline">Upload documents</span>
              </p>
            </>
          }
          currentValue={creditApp.reviewFinancialDocuments}
          errorMessage={
            errors.reviewFinancialDocuments && "A few files are missing"
          }
          setCurrentValue={val => {
            setFieldValue("reviewFinancialDocuments", val);
          }}
          updateCreditApp={val => {
            updateCreditApp({ reviewFinancialDocuments: val });
          }}
          mixpanelTrackQuestion={answer =>
            mixpanelTrack("Missing Documents Question Answered", {
              Answer: answer.choice,
              "Reason text": answer.explanation
            })
          }
        ></ReviewQuestion>
      )
    })[question?.name];

import React from "react";
import InfoIcon from "assets/info.svg";
import BlueInfoIcon from "assets/info-blue.svg";
import UnderlinedInfoIcon from "assets/info-blue-underlined.svg";
import "./styles.scss";
import { Label, Tooltip } from "@enpowered/ui";
import classNames from "classnames";

function InfoTooltipLabel({
  tooltipMessage,
  className,
  position = "top",
  infoAssistOn,
  focused = false,
  children
}) {
  const image = (
    <img
      className="inline-block"
      src={
        !infoAssistOn ? InfoIcon : focused ? BlueInfoIcon : UnderlinedInfoIcon
      }
    />
  );
  return (
    <div className={classNames("infoTooltipLabel", className)}>
      <Label className="inline-block">{children}</Label>
      {!infoAssistOn && tooltipMessage?.length > 0 ? (
        <Tooltip className="nowrap" placement={position} title={tooltipMessage}>
          {image}
        </Tooltip>
      ) : infoAssistOn ? (
        image
      ) : (
        ""
      )}
    </div>
  );
}

export default InfoTooltipLabel;

import React from "react";
import "./styles.scss";
import classNames from "classnames";

/**
 *
 * @returns
 */

function InformationPopover({
  className,
  title,
  isOpen,
  toTheLeft,
  children,
  width,
  height
}) {
  return (
    <div
      className={classNames("informationPopover", className)}
      style={
        toTheLeft ? { right: `${/^[0-9]*/.exec(width)?.[0] ?? 0 + 8}px` } : {}
      }
    >
      <div
        className={classNames(
          "informationPopover-overlay",
          isOpen ? "fadein" : "fadeout"
        )}
        style={{ ...(width ? { width } : {}), height }}
      >
        <div className="informationPopover-content">
          <p className="informationPopover-title">{title}</p>
          {children}
        </div>
      </div>
    </div>
  );
}

export default InformationPopover;

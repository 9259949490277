import React from "react";
import "./styles.scss";
import "../../index.scss";
import CompaniesTable from "./CompaniesTable";
import { MainFrame } from "common/TopBar";

function CompaniesPage({ setPartnerCompanyId }) {
  return (
    <MainFrame>
      <div className="projectsPage">
        <div className="flex block w-full h-full py-2 ">
          <div className="w-full window">
            <div className="projectsPage-header">
              <h4>Companies</h4>
            </div>
            <CompaniesTable setPartnerCompanyId={setPartnerCompanyId} />
          </div>
        </div>
      </div>
    </MainFrame>
  );
}

export default CompaniesPage;

import React from "react";
import "./styles.scss";
import IconTextButton from "../../../common/IconTextButton";
import { ReactComponent as TaskCompleteIcon } from "assets/task-complete.svg";
import TaskIncompleteIcon from "assets/task-incomplete.png";
import classNames from "classnames";

export const ALMOST_DONE_FINANCIAL_INFO = "financialInfo";
export const ALMOST_DONE_ALL = "all";

/**
 *
 * @param {object} props
 * @param {function} props.mixpanelTrack
 * @param {object} props.setIsAlmostDonePanelOpen
 * @param {() => Array<{complete: boolean, label: string}>} props.financialInfoTasks
 * @param {() => Array<{complete: boolean, label: string}>} props.projectDetailsTasks
 * @returns
 */
function AlmostDonePanel({
  setAlmostDonePanelTypeOpen,
  getFinancialInfoTasks = () => [],
  getProjectDetailsTasks = () => [],
  getBusinessDetailsTasks = () => [],
  getSupportingDocumentationTasks = () => [],
  type,
  onSubmit
}) {
  return (
    <div
      className={`flex justify-center items-center py-8 window form-window-default-size ${
        type === ALMOST_DONE_ALL ? "twoColumns" : ""
      }`}
    >
      <div className="almostDonePanel flex flex-col w-full">
        <h2 className="text-center">Almost done!</h2>
        {type === ALMOST_DONE_ALL ? (
          <div className="mt-2">
            <p className="text-center s16">
              Before we can submit to funders, there are only a few more things
              to complete:
            </p>
            <div className="flex justify-between gap-20 mt-10">
              <div>
                <p className="s20 font-semibold">Customer Financing Info</p>
                {getFinancialInfoTasks().map((task, index) => (
                  <p
                    key={`financialtask-${index}`}
                    className={classNames({
                      "font-semibold": task.complete,
                      gray2: !task.complete
                    })}
                  >
                    {task.complete ? (
                      <TaskCompleteIcon className="mr-2 inline-block" />
                    ) : (
                      <img
                        className="inline-block mr-2"
                        src={TaskIncompleteIcon}
                      />
                    )}
                    <span>{task.label}</span>
                  </p>
                ))}
              </div>
              <div>
                <p className="s20 font-semibold">Project Details</p>
                {getProjectDetailsTasks().map((task, index) => (
                  <p
                    key={`financialtask-${index}`}
                    className={classNames({
                      "font-semibold": task.complete,
                      gray2: !task.complete
                    })}
                  >
                    {task.complete ? (
                      <TaskCompleteIcon className="inline-block mr-2" />
                    ) : (
                      <img
                        className="inline-block mr-2"
                        src={TaskIncompleteIcon}
                      />
                    )}
                    <span>{task.label}</span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-2">
            <p className="text-center s16">
              Some required files have not been uploaded. You can go back and
              upload more or submit without the files.
            </p>
            <div className="flex justify-between gap-20 mt-10">
              <div>
                <p className="s20 font-semibold">Business Details</p>
                <div className="grid grid-cols-2 gap-4">
                  {getBusinessDetailsTasks().map((task, index) => (
                    <span
                      key={`businessDetailsTask-${index}`}
                      className={classNames({
                        "font-semibold": task.complete,
                        gray2: !task.complete
                      })}
                    >
                      {task.complete ? (
                        <TaskCompleteIcon className="mr-2 inline-block" />
                      ) : (
                        <img
                          className="inline-block mr-2"
                          src={TaskIncompleteIcon}
                        />
                      )}
                      <span>{task.label}</span>
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <p className="s20 font-semibold">Supporting Documentation</p>
                <div className="grid grid-cols-2 gap-4">
                  {getSupportingDocumentationTasks().map((task, index) => (
                    <span
                      key={`supportDocsTask-${index}`}
                      className={classNames({
                        "font-semibold": task.complete,
                        gray2: !task.complete
                      })}
                    >
                      {task.complete ? (
                        <TaskCompleteIcon className="taskCompleteIcon inline-block" />
                      ) : (
                        <img
                          className="inline-block mr-2"
                          src={TaskIncompleteIcon}
                        />
                      )}
                      <span>{task.label}</span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-evenly gap-10 m-auto m-0">
          {type === ALMOST_DONE_FINANCIAL_INFO && (
            <IconTextButton
              className="submitBtn filled font-semibold"
              label="Submit anyway"
              onClick={() => {
                setAlmostDonePanelTypeOpen(null);
                typeof onSubmit === "function" && onSubmit();
              }}
            />
          )}
          <IconTextButton
            className="submitBtn primary filled font-semibold"
            label="Go back to Application"
            onClick={() => {
              setAlmostDonePanelTypeOpen(null);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AlmostDonePanel;

import React from "react";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { ProposalGen } from "./components";
import { createRoot } from "react-dom/client";

const props = {
  header: {
    dateProcessed: "March 10, 2023",
    project: "Some deal name - tpi",
    location: "some adddress o11231, 12q11q1, ON",
    description: "project description ddddd",
    partner: "a company",
    state: "Ontario",
    partnerLogoUrl:
      "https://partner-company-images--463972583806.s3.amazonaws.com/diversa_logo.png"
  },
  project: {
    projectCost: 89000,
    costSavings: 10000,
    incentivesYear: [],
    otherCostSavings: 0,
    projectType: "Solar",
    utilityCostEscalator: 0.04,
    useEscalatingPayments: true,
    percentageOfSavings: 0.95
  }
};

const ErrorBoundary = props?.ErrorBoundary || React.Fragment;

const container = document.getElementById("rootProposalView");

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <ProposalGen {...props} />
      </ErrorBoundary>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

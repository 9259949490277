import { authedFetch, noAuthFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";
import { exportProjectToHubspot } from "./PaymentProposal";

const apiRoot = process.env.REACT_APP_API_ROOT;

/**
 *
 */
export const getArchivedFinancingScenarios = async partnerCompanyId => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "getArchivedFinancingScenariosByPartnerCompanyId",
        params: {
          partnerCompanyId
        }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(data =>
        verifyForErrors(
          data,
          "There was a problem on loading projects from archive"
        )
      )
      .then(result => {
        return result?.items ?? [];
      })
  );
};

export const getFinancingScenarios = async partnerCompanyId => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "getNotArchivedFinancingScenariosByPartnerCompanyId",
        params: {
          partnerCompanyId
        }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
      .then(result => {
        return result?.items ?? [];
      })
  );
};

export const getFinancingScenarioById = async financingScenarioId => {
  return (
    noAuthFetch(`${apiRoot}/payment-proposal/jsonrpc`, {
      body: JSON.stringify({
        method: "getFinancingScenarioById",
        params: {
          financingScenarioId
        }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
  );
};

export const postFinancingScenario = async project => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "addFinancingScenario",
        params: project
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
  );
};

export const removeFinancingScenario = async financingScenarioId => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "removeFinancingScenario",
        params: { financingScenarioId }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(verifyForErrors)
      .then(result => console.log({ deleteFinancingScenarioResult: result }))
  );
};

export const archiveFinancingScenarios = async financingScenarioIds => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "archiveFinancingScenarios",
        params: { financingScenarioIds }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(data =>
        verifyForErrors(
          data,
          "There was a problem on archiving the selected projects"
        )
      )
  );
};
export const unarchiveFinancingScenarios = async financingScenarioIds => {
  return (
    authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
      body: JSON.stringify({
        method: "unarchiveFinancingScenarios",
        params: { financingScenarioIds }
      })
    })
      .then(res => res.json())
      //@ts-ignore
      .then(body => body?.data)
      .then(data =>
        verifyForErrors(
          data,
          "There was a problem on restoring the selected projects from the archive"
        )
      )
      .then(result => console.log({ unarchiveProjectsResult: result }))
  );
};

export const sendToHubspotIfFirstTime = (
  postFinancingScenarioUpdates,
  proposal,
  setFieldValue
) => {
  if (!proposal.wasAlreadyExportedOnce) {
    exportProjectToHubspot({
      ...proposal,
      termLengthInMonths: proposal.termLengthInMonths
    })
      .then(() => {
        postFinancingScenarioUpdates({
          ...proposal,
          wasAlreadyExportedOnce: true
        });
        setFieldValue("wasAlreadyExportedOnce", true);
      })
      .catch(err =>
        console.error("Error on exporting project to EnPowered", err)
      );
  }
};

import React from "react";
import "./styles.scss";
// import { useNavigate } from "react-router-dom";
// import { getMixpanel } from "services/mixpanel";
import { TextInput } from "@enpowered/ui";
import enpoweredLogo from "assets/Logo_White_Bg.png";
import * as Yup from "yup";
import bgImage from "assets/creditapp-bg.jpg";

function InitialPage({
  partnerCompanyName,
  partnerLogoUrl,
  onCompleteClicked = () => {},
  userEmail,
  setUserEmail,
  setErrors
}) {
  // const mixpanel = getMixpanel();
  const validateEmail = email =>
    Yup.object({
      email: Yup.string("Email is required")
        .email()
        .required("Email is required")
    })
      .validate({ email: email || "" })
      .then(() => setErrors({}))
      .catch(err => {
        setErrors(err.errors);
        return err;
      });

  return (
    <div
      className="creditApp-initialPage flex justify-center items-start h-screen"
      style={{ backgroundImage: `url('${bgImage}')` }}
    >
      <div className="grid w-full">
        <h2 className="text-center">Financing Application</h2>
        <div className="grid w-full">
          <div className="leftSide flex flex-col justify-center">
            <h3>Making commercial clean energy financing easy</h3>
            <div className="s16">
              <p className="mb-4">
                <span className="font-bold">{partnerCompanyName}</span> is
                inviting you to apply for financing.
              </p>
              Our secure and streamlined process has the following steps:
              <ul className="list-disc ml-6">
                <li>Fill the application form as accurately as possible</li>
                <li>Upload the required documentation</li>
                <li>{`We'll get back to you within 24 hours`}</li>
              </ul>
              <p className="mt-4">
                You can save your progress and return at any time to complete
                the application.
              </p>
            </div>
          </div>
          <div className="rightSide bg-white grid">
            <div className="icons">
              <figure className="w-full flex justify-center">
                <img src={partnerLogoUrl} className="object-contain" />
              </figure>
              <div className="flex items-center justify-center">
                <span className="font-light">Supported by</span>{" "}
                <img src={enpoweredLogo} width={160} />
              </div>
            </div>
            <div className="grid grid-rows-2 gap-4">
              <p className="text-center">
                To access your financing application, please enter your email
                address:
              </p>
              <TextInput
                id="editor-email"
                name="email"
                placeholder="Email"
                value={userEmail}
                onBlur={e => userEmail && validateEmail(e.target.value)}
                onChange={e => setUserEmail(e.target.value)}
              />
            </div>
            <div>
              <button
                onClick={() =>
                  validateEmail(userEmail).then(
                    res =>
                      !(res instanceof Error) && onCompleteClicked(userEmail)
                  )
                }
                className="startButton"
              >{`Let's get started`}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitialPage;

import React from "react";
import classNames from "classnames/dedupe";
import PropTypes from "prop-types";

/**
 *
 * @param {object} props
 * @param {any} [props.className]
 * @param {import("framer-motion").MotionStyle} [props.style]
 * @returns {JSX.Element}
 */
export const SkeletonBox = ({ className, style }) => {
  const boxClasses = classNames(["bg-en-gray-100", "animate-pulse"], className);
  return <div key="skeleton" className={boxClasses} style={style} />;
};

SkeletonBox.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.object
};

export const SkeletonParagraph = ({ rows = 4, className, style }) => {
  const rowClasses = classNames([
    "bg-en-gray-100",
    "animate-pulse",
    "h-4 mb-2",
    "w-full"
  ]);

  return (
    <div key="skeleton" className={className} style={style}>
      {Array.from({ length: rows }, (_, i) => (
        <SkeletonBox key={i} className={rowClasses} />
      ))}
    </div>
  );
};

export const Skeleton = ({
  paragraph,
  rows,
  className,
  isLoading,
  children,
  style
}) => {
  const showSkeleton = isLoading || !children;
  return (
    <div>
      {showSkeleton ? (
        paragraph ? (
          <SkeletonParagraph {...{ className, style, rows }} />
        ) : (
          <SkeletonBox {...{ className, style }} />
        )
      ) : (
        <div key="content" className={className} style={style}>
          {children}
        </div>
      )}
    </div>
  );
};

import "./index.scss";

import useAutocomplete, {
  createFilterOptions
} from "@material-ui/lab/useAutocomplete";
import { useSize } from "ahooks";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

/**
 *
 * @param {object} props
 * @param {any[]} props.autocompleteData
 * @param {(item: any) => string} [props.getOptionLabel] defaults to `item => item.label`
 * @param {number} [props.maxResults]
 * @param {(selected: any) => (Promise<any>|void)} props.onSelected
 * @param {string} props.placeholder
 * @param {any} props.className
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.multiple]
 * @param {boolean} [props.isLoading]
 * @param {any} props.defaultValue
 * @param {boolean} [props.valid]
 * @param {JSX.Element} [props.prefix]
 * @param {JSX.Element} props.suffix
 * @param {any} props.value
 * @param {string} [props.id]
 * @param {string} [props.name]
 * @param {boolean} [props.debug] this puts the component into a debug mode, so it can be used by a debugger. See https://v4.mui.com/api/autocomplete/
 * @returns {JSX.Element}
 */
const AutocompleteJsxInput = ({
  autocompleteData,
  getOptionLabel = option => option.label,
  maxResults,
  multiple,
  onSelected,
  onChange,
  placeholder,
  className,
  inputClassName,
  disabled,
  isLoading,
  defaultValue,
  valid,
  prefix,
  suffix = (
    <svg
      className="absolute w-6 right-0 top-0 mr-1 mt-2 pointer-events-none"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  ),
  value,
  id,
  name,
  debug,
  openOnFocus,
  onBlur,
  ...others
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  });
  const referenceSize = useSize(referenceElement);

  const [destination, setDestination] = useState(
    document.querySelector("#autocomplete-popper")
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!destination) {
      const newDestination = document.createElement("div");
      newDestination.id = "autocomplete-popper";
      document.querySelector("body").append(newDestination);
      setDestination(newDestination);
    }
  }, [destination]);

  const onAutocompleteValueChange = (event, value, reason) => {
    if (reason === "select-option") {
      onSelected && onSelected(value);
    } else if (reason === "remove-option") {
      onSelected && onSelected(value);
    } else if (reason === "clear") {
      if (multiple) {
        onSelected && onSelected([]);
      } else {
        onSelected && onSelected("");
      }
    }
  };

  const onAutocompleteOpen = () => setOpen(true);

  const handleKeyDown = event => {
    if (event?.key === "Enter") {
      let collection = document.getElementsByClassName(
        `autocomplete__option px ${id}`
      );
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].dataset?.focus && autocompleteData[i].disabled) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
    }
  };

  const onAutocompleteClose = e => {
    if (
      !(
        ["click", "keydown"].includes(e.type) &&
        autocompleteData[e.target.value ?? 0]?.disabled
      )
    )
      setOpen(false);
  };
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value: autocompleteValue
  } = useAutocomplete({
    options: autocompleteData,
    getOptionLabel: getOptionLabel,
    filterOptions: createFilterOptions({
      limit: maxResults ? maxResults : autocompleteData.length
    }),
    multiple,
    onChange: onAutocompleteValueChange,
    onClose: onAutocompleteClose,
    onOpen: onAutocompleteOpen,
    defaultValue: defaultValue,
    value,
    open,
    id,
    debug,
    openOnFocus
  });

  const renderOptions = () => {
    return groupedOptions.map((option, index) => {
      return (
        <li
          value={index}
          key={`${id}Option-${index}`}
          className={classNames("autocomplete__option px", { [id]: !!id })}
          {...getOptionProps({ option, index })}
          onClick={e => {
            !option.disabled && getOptionProps({ option, index }).onClick(e);
          }}
          onMouseOver={e => {
            !option.disabled &&
              getOptionProps({ option, index }).onMouseOver(e);
          }}
        >
          {option.jsx ?? getOptionLabel(option)}
        </li>
      );
    });
  };

  const renderValueTags = () => {
    return autocompleteValue.map((option, index) => {
      return (
        <AutocompleteTag
          key={`tag-${index}`}
          label={getOptionLabel(option)}
          {...getTagProps({ index })}
        />
      );
    });
  };

  const activeClasses =
    "border-en-gray-400 hover:border-en-gray-800 focus:border-en-yellow-600";
  const validClasses = "focus:border-en-green";
  const invalidClasses =
    "border-en-red focus:border-en-red hover:border-en-red";
  const disabledClasses = "border-en-gray-600 bg-en-gray-100";

  const hasValidation = () => ![null, undefined].includes(valid);
  const validationStyle = () => {
    if (hasValidation()) {
      return valid ? validClasses : invalidClasses;
    }
  };

  const textboxClasses = classNames(
    [
      "border p-2 caret-en-yellow-600 w-full",
      "focus:outline-none placeholder-en-gray-300"
    ],
    // don't apply activeStyle if has valid or disabled
    !hasValidation() && !disabled && activeClasses,
    // don't apply disabledStyle if has valid
    !hasValidation() && disabled && disabledClasses,
    validationStyle(),
    {
      "autocomplete__multiple-input": multiple,
      rounded: groupedOptions.length === 0,
      "rounded-t": groupedOptions.length > 0
    },
    multiple ? {} : inputClassName
  );

  const multipleInputWrapperClassName = classNames({
    "autocomplete__multiple-input-wrapper": true,
    "autocomplete__multiple-input-wrapper--not-empty":
      value && value.length > 0,
    "autocomplete__multiple-input-wrapper--disabled": disabled || isLoading,
    rounded: groupedOptions.length === 0,
    "rounded-t": groupedOptions.length > 0
  });

  return (
    <div
      // the "disabled" class does not have any styling by default
      className={classNames({ relative: true, disabled }, className)}
      {...getRootProps()}
      ref={setReferenceElement}
    >
      {!!suffix && suffix}
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center absolute bg-gray-900 opacity-50 rounded">
          <span>
            <i className="text-3xl fa fa-circle-notch fa-pulse text-blue-500" />
          </span>
        </div>
      )}
      {multiple ? (
        <div className={`${multipleInputWrapperClassName} ${inputClassName}`}>
          {renderValueTags()}
          <input
            className={textboxClasses}
            placeholder={placeholder && value.length === 0 ? placeholder : ""}
            {...getInputProps()}
            disabled={disabled || isLoading}
            name={name}
            {...others}
          />
        </div>
      ) : (
        <>
          {!!prefix && prefix}
          <input
            className={textboxClasses}
            placeholder={placeholder ? placeholder : ""}
            {...getInputProps()}
            onChange={e => {
              onChange && onChange(e);
              getInputProps().onChange(e);
            }}
            disabled={disabled || isLoading}
            name={name}
            onBlur={e => {
              onBlur && onBlur(e);
              getInputProps().onBlur(e);
            }}
            {...others}
            onKeyDown={handleKeyDown}
          />
        </>
      )}
      {groupedOptions.length > 0 &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            style={{ ...styles.popper, width: referenceSize?.width || "100%" }}
            {...attributes.popper}
          >
            <ul
              id={`autocomplete-${id ?? "0"}`}
              className="absolute z-20 list-none border border-t-0 autocomplete__options-wrapper rounded-b bg-white w-full"
              {...getListboxProps()}
            >
              {renderOptions()}
            </ul>
          </div>,
          destination
        )}
    </div>
  );
};

AutocompleteJsxInput.propTypes = {
  autocompleteData: PropTypes.arrayOf(PropTypes.object).isRequired,
  getOptionLabel: PropTypes.func,
  multiple: PropTypes.bool,
  maxResults: PropTypes.number,
  onSelected: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  valid: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  debug: PropTypes.bool
};

export default AutocompleteJsxInput;

const AutocompleteTag = ({ label, ...props }) => {
  return (
    <div
      className="flex items-center bg-en-yellow-200 text-gray-800 rounded box-content overflow-hidden px-3 m-1"
      {...props}
    >
      <span className="truncate">{label}</span>
    </div>
  );
};

AutocompleteTag.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func
};

import React from "react";
import Modal from "../Modal";
import "./styles.scss";
import { Spinner } from "@enpowered/ui";
import RefreshIcon from "assets/refresh.svg";

function LoadingModal({
  errorOnLoad,
  modalOverlayRef,
  onClick = () => window.location.reload()
}) {
  return (
    <Modal
      blurrableContentRef={modalOverlayRef}
      centered={true}
      className={"absolute bg-transparent loadingModal"}
      isOpen={true}
    >
      {errorOnLoad ? (
        <div className="flex flex-col items-center px-10">
          <h4 className="mt-6 font-normal">Load Error</h4>
          <p className="mt-5 mb-3 px-6">
            Sorry, there was a glitch loading the information.
          </p>
          <div className="flex my-5 justify-center">
            <button
              className="flex gap-2 font-semibold items-center px-4 s18 py-3 border border-en-yellow-400 rounded hover:bg-en-gray-100"
              onClick={onClick}
            >
              <img src={RefreshIcon} /> Refresh page
            </button>
          </div>
        </div>
      ) : (
        <div className="mt-2 mb-1">
          <Spinner size={"24px"} />
        </div>
      )}
    </Modal>
  );
}

export default LoadingModal;

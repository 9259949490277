import React from "react";
import "./styles.scss";
import classNames from "classnames";
// import { searchCompanyByBusinessName } from "../../services/OpenCorporates";

function TopBarNavigator({ items, currentItem, setCurrentItem, className }) {
  // searchCompanyByBusinessName("Bixby", "US");
  return (
    <div className={classNames("topBarNavigator", className)}>
      <ul>
        {items.map((item, idx) => (
          <li
            onClick={() => setCurrentItem(idx)}
            id={item}
            className={classNames({ selected: currentItem === idx })}
            key={`topBarNavigatorOption-${idx}`}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TopBarNavigator;

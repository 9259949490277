import { authedFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";

const apiRoot = process.env.REACT_APP_API_ROOT;

export const addCustomerOrgUser = async ({
  partnerCompanyId: customerOrganizationId,
  partnerRepName: name,
  partnerRepEmail: email
}) => {
  return authedFetch(`${apiRoot}/arlo-engine/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "addCustomerOrgUser",
      params: {
        customerOrganizationId,
        name,
        email
      }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .catch(error => {
      throw error;
    });
};

export const CA_STATES = [
  {
    value: "AB",
    label: "Alberta",
    taxRate: 8.0
  },
  {
    value: "BC",
    label: "British Columbia",
    taxRate: 12.0
  },
  {
    value: "MB",
    label: "Manitoba",
    taxRate: 12.0
  },
  {
    value: "NB",
    label: "New Brunswick",
    taxRate: 14.0
  },
  {
    value: "NL",
    label: "Newfoundland and Labrador",
    taxRate: 15.0
  },
  {
    value: "NS",
    label: "Nova Scotia",
    taxRate: 14.0
  },
  {
    value: "ON",
    label: "Ontario",
    taxRate: 11.5
  },
  {
    value: "PE",
    label: "Prince Edward Island",
    taxRate: 16.0
  },
  {
    value: "QC",
    label: "Quebec",
    taxRate: 11.5
  },
  {
    value: "SK",
    label: "Saskatchewan",
    taxRate: 12.0
  },
  {
    value: "NT",
    label: "Northwest Territories",
    taxRate: 0
  },
  {
    value: "NU",
    label: "Nunavut",
    taxRate: 0
  },
  {
    value: "YT",
    label: "Yukon",
    taxRate: 0
  }
];

export const US_STATES = [
  {
    value: "AL",
    label: "Alabama",
    taxRate: 6.5
  },
  {
    value: "AK",
    label: "Alaska",
    taxRate: 9.4
  },
  {
    value: "AZ",
    label: "Arizona",
    taxRate: 4.9
  },
  {
    value: "AR",
    label: "Arkansas",
    taxRate: 5.3
  },
  {
    value: "CA",
    label: "California",
    taxRate: 8.84
  },
  {
    value: "CO",
    label: "Colorado",
    taxRate: 4.55
  },
  {
    value: "CT",
    label: "Connecticut",
    taxRate: 7.5
  },
  {
    value: "DE",
    label: "Delaware",
    taxRate: 8.7
  },
  {
    value: "DC",
    label: "District of Columbia",
    taxRate: 8.25
  },
  {
    value: "FL",
    label: "Florida",
    taxRate: 5.5
  },
  {
    value: "GA",
    label: "Georgia",
    taxRate: 5.75
  },
  {
    value: "HI",
    label: "Hawaii",
    taxRate: 6.4
  },
  {
    value: "ID",
    label: "Idaho",
    taxRate: 5.8
  },
  {
    value: "IL",
    label: "Illinois",
    taxRate: 9.5
  },
  {
    value: "IN",
    label: "Indiana",
    taxRate: 4.9
  },
  {
    value: "IA",
    label: "Iowa",
    taxRate: 8.4
  },
  {
    value: "KS",
    label: "Kansas",
    taxRate: 7.0
  },
  {
    value: "KY",
    label: "Kentucky",
    taxRate: 5.0
  },
  {
    value: "LA",
    label: "Louisiana",
    taxRate: 7.5
  },
  {
    value: "ME",
    label: "Maine",
    taxRate: 8.93
  },
  {
    value: "MD",
    label: "Maryland",
    taxRate: 8.25
  },
  {
    value: "MA",
    label: "Massachusetts",
    taxRate: 8.0
  },
  {
    value: "MI",
    label: "Michigan",
    taxRate: 6.0
  },
  {
    value: "MN",
    label: "Minnesota",
    taxRate: 9.8
  },
  {
    value: "MS",
    label: "Mississippi",
    taxRate: 5.0
  },
  {
    value: "MO",
    label: "Missouri",
    taxRate: 4.0
  },
  {
    value: "MT",
    label: "Montana",
    taxRate: 6.75
  },
  {
    value: "NE",
    label: "Nebraska",
    taxRate: 7.25
  },
  {
    value: "NV",
    label: "Nevada",
    taxRate: 0.0
  },
  {
    value: "NH",
    label: "New Hampshire",
    taxRate: 7.5
  },
  {
    value: "NJ",
    label: "New Jersey",
    taxRate: 11.5
  },
  {
    value: "NM",
    label: "New Mexico",
    taxRate: 5.9
  },
  {
    value: "NY",
    label: "New York",
    taxRate: 7.25
  },
  {
    value: "NC",
    label: "North Carolina",
    taxRate: 2.5
  },
  {
    value: "ND",
    label: "North Dakota",
    taxRate: 4.31
  },
  {
    value: "OH",
    label: "Ohio",
    taxRate: 0.0
  },
  {
    value: "OK",
    label: "Oklahoma",
    taxRate: 4.0
  },
  {
    value: "OR",
    label: "Oregon",
    taxRate: 7.6
  },
  {
    value: "PA",
    label: "Pennsylvania",
    taxRate: 8.99
  },
  {
    value: "PR",
    label: "Puerto Rico",
    taxRate: 37.5
  },
  {
    value: "RI",
    label: "Rhode Island",
    taxRate: 7.0
  },
  {
    value: "SC",
    label: "South Carolina",
    taxRate: 5.0
  },
  {
    value: "SD",
    label: "South Dakota",
    taxRate: 0.0
  },
  {
    value: "TN",
    label: "Tennessee",
    taxRate: 6.5
  },
  {
    value: "TX",
    label: "Texas",
    taxRate: 0.0
  },
  {
    value: "UT",
    label: "Utah",
    taxRate: 4.85
  },
  {
    value: "VT",
    label: "Vermont",
    taxRate: 8.5
  },
  {
    value: "VA",
    label: "Virginia",
    taxRate: 6.0
  },
  {
    value: "WA",
    label: "Washington",
    taxRate: 0.0
  },
  {
    value: "WV",
    label: "West Virginia",
    taxRate: 6.5
  },
  {
    value: "WI",
    label: "Wisconsin",
    taxRate: 7.9
  },
  {
    value: "WY",
    label: "Wyoming",
    taxRate: 0.0
  }
];

export const CA_FEDERAL_TAX_PERCENTAGE = 15.0;
export const US_FEDERAL_TAX_PERCENTAGE = 21.0;

export const getTaxPercentage = state =>
  US_STATES.find(s => s.value === state)
    ? US_STATES.find(s => s.value === state).taxRate +
      (state === "PR" ? 0 : US_FEDERAL_TAX_PERCENTAGE)
    : CA_STATES.find(s => s.value === state)
    ? CA_STATES.find(s => s.value === state).taxRate + CA_FEDERAL_TAX_PERCENTAGE
    : 0.0;

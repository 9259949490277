import React from "react";
import ShareLink from "../../ShareLink";
// import "./styles.scss";
// import * as Yup from "yup";
import IconTextButton from "../../../common/IconTextButton";
import { ReactComponent as ShareIcon } from "assets/share.svg";
import { getReviewQuestionBody } from "../ReviewQuestions";
import { getReviewQuestions } from "../../../services";
import { useQuery } from "react-query";
import LoadingModal from "common/LoadingModal";
import useCreditAppTasks from "../../../hooks/useCreditAppTasks";
import * as Yup from "yup";
import classNames from "classnames";
import { ReactComponent as ErrorIcon } from "assets/error_outline.svg";
import "./styles.scss";

function AlmostDoneErrorSection({ errors }) {
  return (
    <div
      className={classNames(
        "flex flex-col items-start gap-[16px] p-[16px] rounded-[8px] w-full"
      )}
    >
      <div className="w-full flex items-center gap-[8px] p-[8px] bg-error-12">
        <ErrorIcon />
        <span className="s24 font-semibold">Almost Done!</span>
      </div>
      <div className="flex flex-col gap-[16px] pl-[32px]">
        <p className="s14">The following sections need a response:</p>
        <ul className="almostDoneFieldsLeft flex flex-wrap items-start gap-[16px]">
          {errors.map(err => (
            <li className="s14 ml-5" key={`reviewError-${err}`}>
              {err}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function ReviewStep({
  creditApp,
  updateCreditApp,
  goToPreviousSection,
  mixpanelTrack,
  handleSubmit,
  setFieldValue,
  userEmail,
  errors,
  setErrors
}) {
  const [isLoadingError, setIsLoadingError] = React.useState(false);
  const { data: questions = [], isLoading } = useQuery(
    `reviewQuestions-${creditApp?.creditAppId}-${creditApp?.businessTypeObtainedAutomatically}-${creditApp?.financialDocuments?.length}`,
    () =>
      creditApp?.creditAppId
        ? getReviewQuestions(creditApp?.creditAppId, userEmail)
            .then(questions => {
              isLoadingError && setIsLoadingError(false);
              return questions;
            })
            .then(questions => {
              updateCreditApp({
                requiredReviewQuestions: questions ?? []
              });
              return questions;
            })
            .catch(() => !isLoadingError && setIsLoadingError(true))
        : []
  );
  const {
    getRelevantCharacteristicsForMixpanel,
    wereAllRelevantDocumentsUploaded
  } = useCreditAppTasks();

  const reviewSchema = () => {
    const questionsMap = Object.fromEntries(
      questions?.map(question => [question.name, true] ?? [])
    );
    const getValidator = (questionName, questionLabel) =>
      questionsMap[questionName]
        ? {
            [questionName]: Yup.object({
              choice: Yup.string(`${questionName}: ${questionLabel}`).test(
                questionName,
                `${questionName}: ${questionLabel}`,
                function (value) {
                  const { explanation, requireExplanation } = this.parent;
                  if (!explanation) return !!value && !requireExplanation;
                  return true;
                }
              )
            }).required(`${questionName}: required`)
          }
        : {};
    return Yup.object({
      ...getValidator("businessType", "businessType", "Business Type"),
      ...getValidator("reviewCanProvideGuarantee", "Personal Guarantee"),
      ...getValidator("reviewPropertyOwnership", "Property Ownership"),
      ...getValidator("reviewNoPropertyOwnershipFound", "Property Ownership"),
      ...getValidator("reviewDebtIncreaseReason", "Debt Increase Reason"),
      ...getValidator("reviewRevenueDecreaseReason", "Revenue Decrease Reason"),
      ...getValidator("reviewUnsettledObligations", "Legal Risk"),
      ...getValidator("reviewFilledBankruptcy", "Bankruptcy"),
      ...getValidator(
        "reviewFinancialDocuments",
        "Supporting Documents Required"
      )
    });
  };

  const validateReview = reviewAnswers =>
    reviewSchema()
      .validate(reviewAnswers, { abortEarly: false })
      .then(() => {
        setErrors({});
      })
      .catch(err => {
        console.info({ validationError: err });
        setErrors(Object.fromEntries(err.errors.map(e => e.split(": "))));
        return Promise.reject(err);
      });
  return (
    <div className="flex flex-col w-full h-full px-8">
      <div className="flex w-full gap-2 justify-between items-center pb-[24px]">
        <h2 className="">Application Review</h2>
        <ShareLink
          buttonIcon={<ShareIcon />}
          buttonLabel={"Invite Contributors"}
          title={"Invite Contributors"}
          mixpanelTrack={mixpanelTrack}
          centered={false}
          onClick={() =>
            mixpanelTrack("Credit App Invite Contributors Clicked")
          }
          link={`${window.location.protocol}//${window.location.host}/creditApp/${creditApp?.creditAppId}`}
          description={
            "Share this link with a colleague who has the required information or can upload documents to help complete the application."
          }
        />
      </div>
      <div className="flex flex-col gap-[20px]">
        {isLoading || isLoadingError ? (
          <LoadingModal errorOnLoad={isLoadingError} />
        ) : (
          questions.map(
            getReviewQuestionBody({
              creditApp,
              setFieldValue,
              errors,
              goToDocumentUpload: goToPreviousSection,
              mixpanelTrack,
              updateCreditApp
            })
          )
        )}
      </div>
      <div className="flex flex-col gap-[16px] w-full mt-4">
        {!!Object.keys(errors ?? {})?.length && (
          <AlmostDoneErrorSection errors={Object.values(errors)} />
        )}
        <div className="w-full">
          <div className="flex w-full s18 gap-4 my-3"></div>
          <div className="w-full flex justify-end mt-10 mb-7 gap-5 pr-10">
            <IconTextButton
              label={"Back"}
              onClick={goToPreviousSection}
              outlined
            />
            <IconTextButton
              label={"Submit Application"}
              primary
              filled
              onClick={() =>
                validateReview(creditApp)
                  .then(() => {
                    mixpanelTrack("Submit Financial Info", {
                      ...getRelevantCharacteristicsForMixpanel(
                        creditApp.financialDocuments
                      ),
                      isComplete: wereAllRelevantDocumentsUploaded(
                        creditApp.financialDocuments
                      )
                    });
                    return handleSubmit();
                  })
                  .catch(() => {})
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewStep;

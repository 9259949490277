import { getIdToken } from "../services/auth/token";
import { handleHttpError } from "./handleHttpError";

export const getFetchFunction = () => {
  return fetch || ((input, init) => window.fetch(input, init));
};

export const authedFetch = (uri, params = {}) => {
  const fetch = getFetchFunction();
  return getIdToken().then(accessToken =>
    fetch(uri, {
      method: "POST",
      ...params,
      headers: {
        "Content-Type": "application/json",
        ...(params?.headers || {}),
        Authorization: `Bearer ${accessToken}`
      }
    }).then(handleHttpError)
  );
};

export const noAuthFetch = (uri, params = {}) => {
  const fetch = getFetchFunction();
  return fetch(uri, {
    method: "POST",
    ...params,
    headers: {
      "Content-Type": "application/json",
      ...(params?.headers || {})
    }
  }).then(handleHttpError);
};

export const sleep = ms => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const getEnvironmentDomainUrlSuffix = () =>
  process.env.REACT_APP_ENV_NAME === "PRODUCTION"
    ? ""
    : process.env.REACT_APP_ENV_NAME === "STAGING"
      ? "-staging"
      : process.env.REACT_APP_ENV_NAME === "DEV"
        ? "-dev"
        : `-${(process.env.REACT_APP_ENV_NAME ?? "local")?.toLowerCase()}`;

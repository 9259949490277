import { authedFetch } from "../utils/fetch";

const prefix = "com.enpowered.iam";
const METHOD = {
  createOrgProfile: `${prefix}:createOrgProfile`,
  enumerateOrgProfiles: `${prefix}:enumerateOrgProfiles`,
  updateOrgProfile: `${prefix}:updateOrgProfile`,
  enumerateUserProfiles: `${prefix}:enumerateUserProfiles`,
  updateUserProfile: `${prefix}:updateUserProfile`
};

const apiRoot = `${process.env.REACT_APP_API_ROOT}/iam/jsonrpc`;

/**
 * Create Org Profile
 * @param {OrgProfile} data
 * @returns {Promise<{orgProfileId: string}>}
 */
export const createOrgProfile = data =>
  callJsonRPC(METHOD.createOrgProfile, data);

/**
 * Update Org Profile
 * @param {OrgProfile} data
 * @returns {Promise<{orgProfileId: string}>}
 */
export const updateOrgProfile = data =>
  callJsonRPC(METHOD.updateOrgProfile, data);

/**
 * Enumerate Org Profiles
 * @param {object} data
 * @param {string} [data.orgProfileId]
 * @param {number} [data.itemsPerPage]
 * @param {string} [data.cursor]
 * @param {string} [data.orgProfileId]
 * @param {string} [data.name]
 * @param {string} [data.city]
 * @param {string} [data.state]
 * @param {string} [data.country]
 * @param {(uri: string, params: object) => Promise<any>} [fetch]
 * @returns {Promise<{items: OrgProfile[], cursor?:string}>}
 */
export const enumerateOrgProfiles = (data, fetch = authedFetch) =>
  callJsonRPC(METHOD.enumerateOrgProfiles, data, fetch);

/**
 * Enumerate User Profiles
 * @param {object} data
 * @param {string} [data.userId]
 * @param {string} [data.orgProfileId]
 * @param {number} [data.itemsPerPage]
 * @param {string} [data.permission]
 * @param {string} [data.cursor]
 * @returns {Promise<{items: UserProfile[], cursor?:string}>}
 */
export const enumerateUserProfiles = data =>
  callJsonRPC(METHOD.enumerateUserProfiles, data);

/**
 *
 * @param {object} data
 * @param {string} data.userId
 * @param {string} [data.given_name]
 * @param {string} [data.family_name]
 * @param {string} [data.phone_number]
 * @returns {Promise<{id: string, userId: string}>}
 */
export const updateUserProfile = data =>
  callJsonRPC(METHOD.updateUserProfile, data);

/**
 *
 * @param {string} method
 * @param {object} data
 * @param {any} fetch
 * @returns
 */
const callJsonRPC = (method, data, fetch = authedFetch) =>
  fetch(apiRoot, {
    body: JSON.stringify({
      jsonrcp: "2.0",
      method,
      id: method,
      params: data
    })
  })
    .then(res => res.json())
    .then(({ result }) => result?.items ?? []);

/**
 * @typedef OrgProfile
 * @property {string} [orgProfileId]
 * @property {string} name
 * @property {Address} address
 * @property {string} avatarUrl
 * @property {EnpoweredApp[]} enpoweredApps
 *
 * @typedef {object} Address
 * @property {string} streetAddress
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} postalCode
 *
 * @typedef {object} EnpoweredApp
 * @property {string} appId
 * @property {string} appName
 * @property {string} appUrl
 *
 * @typedef {object} UserProfile
 * @property {string} userId
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {string[]} authorizationScope
 */

import { DateTime } from "luxon";

export const getCADepreciationRate = projectStartYear => {
  switch (projectStartYear) {
    case 2023:
      return {
        startYearRate: 1,
        followingYearsRate: 0.5
      };
    case 2024:
      return {
        startYearRate: 0.75,
        followingYearsRate: 0.5
      };
    case 2025:
      return {
        startYearRate: 0.75,
        followingYearsRate: 0.3
      };
    case 2026:
      return {
        startYearRate: 0.55,
        followingYearsRate: 0.3
      };
    case 2027:
      return {
        startYearRate: 0.55,
        followingYearsRate: 0.3
      };
    default:
      return {
        startYearRate: 0.15,
        followingYearsRate: 0.3
      };
  }
};

export const getUSBonusDepreciationRate = projectStartYear => {
  switch (projectStartYear) {
    case 2023:
      return {
        startYearRate: 0.8
      };
    case 2024:
      return {
        startYearRate: 0.6
      };
    case 2025:
      return {
        startYearRate: 0.4
      };
    case 2026:
      return {
        startYearRate: 0.2
      };
    default:
      return {
        startYearRate: 0
      };
  }
};

/**
 * @param {Number} projectYear Must be a number from the start year. Example year 1, 2, 3
 * @returns {Object} Object.yearRate
 */
export const getUSMACRSDepreciationRate = projectYear => {
  switch (projectYear) {
    case 1:
      return {
        yearRate: 0.2
      };
    case 2:
      return {
        yearRate: 0.32
      };
    case 3:
      return {
        yearRate: 0.192
      };
    case 4:
      return {
        yearRate: 0.1152
      };
    case 5:
      return {
        yearRate: 0.1152
      };
    case 6:
      return {
        yearRate: 0.0576
      };
    default:
      return {
        yearRate: 0
      };
  }
};

const calculateCABasis = (projectCost, depreciationRate) =>
  projectCost * (1 - depreciationRate);

const calculateUSBasis = (basis, depreciationRate) =>
  basis * (1 - depreciationRate);

const calculateDepreciation = (projectCost, depreciationRate, taxRate) =>
  projectCost * depreciationRate * taxRate;

const getCostsForDepreciationSavings = (
  projectCost,
  countryCode,
  incentives
) => {
  const value =
    projectCost -
    (incentives.find(i => i.name === "ITC")?.amount ?? 0) *
      (countryCode === "CA" ? 1 : 0.5);
  return value < 0 ? 0 : value;
};
/**
 *
 * @param {object} props
 * @param {number} props.projectCost
 * @param {string} props.countryCode
 * @param {number} props.termMonths
 * @param {number} props.taxRate
 * @param {number} props.incentives
 * @param {string} [props.estimatedConstructionStartDate]
 * @param {number} [props.constructionPeriodLength]
 * @param {number} [props.yearConstructionCompleted]
 * @returns {array} yearlyDepreciationValues
 */
export const calculateEntireDepreciationSchedule = ({
  projectCost,
  countryCode,
  taxRate,
  incentives,
  estimatedConstructionStartDate,
  constructionPeriodLength,
  yearConstructionCompleted = new Date().getFullYear()
}) => {
  const yearlyDepreciationValues = [];
  const year =
    estimatedConstructionStartDate &&
    DateTime.fromISO(estimatedConstructionStartDate).isValid
      ? DateTime.fromISO(estimatedConstructionStartDate).plus({
          months: constructionPeriodLength ?? 0
        }).year
      : yearConstructionCompleted;
  let bonusDepreciation = 0;
  let basis = getCostsForDepreciationSavings(
    projectCost,
    countryCode,
    incentives
  );
  if (countryCode && countryCode === "CA") {
    const depreciationRate = getCADepreciationRate(year);
    for (let i = 0; i < 25; i++) {
      if (i === 0) {
        yearlyDepreciationValues.push(
          calculateDepreciation(basis, depreciationRate.startYearRate, taxRate)
        );
        basis = calculateCABasis(basis, depreciationRate.startYearRate);
      } else {
        yearlyDepreciationValues.push(
          calculateDepreciation(
            basis,
            depreciationRate.followingYearsRate,
            taxRate
          )
        );
        basis = calculateCABasis(basis, depreciationRate.followingYearsRate);
      }
    }
  } else if (countryCode && countryCode === "US") {
    const bonusDepreciationRate = getUSBonusDepreciationRate(year);
    for (let i = 0; i < 10; i++) {
      const depreciationRate = getUSMACRSDepreciationRate(i + 1);
      if (i === 0) {
        bonusDepreciation = calculateDepreciation(
          basis,
          bonusDepreciationRate.startYearRate,
          taxRate
        );
        basis = calculateUSBasis(basis, bonusDepreciationRate.startYearRate);
      }
      yearlyDepreciationValues.push(
        (i === 0 ? bonusDepreciation : 0) +
          calculateDepreciation(basis, depreciationRate.yearRate, taxRate)
      );
    }
  }
  return yearlyDepreciationValues;
};

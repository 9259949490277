export const formatAmountForScreen = (amountIn, decimalPlaces) => {
  const amount = (+(+amountIn).toFixed(decimalPlaces)).toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces ?? 0
  });
  return amountIn < 0 ? `-$${amount.slice(1)}` : `$${amount}`;
};
export const renderDollar = (value, decimalPlaces = 0) =>
  value === 0 || !value ? "-" : formatAmountForScreen(value, decimalPlaces);

export const renderPercentage = (value, decimalPlaces = 0) =>
  value === 0 || !value
    ? "-"
    : `${(+(+value).toFixed(decimalPlaces)).toLocaleString("en-US", {
        minimumFractionDigits: decimalPlaces ?? 0
      })}%`;

import React from "react";
import "./styles.scss";
import image404 from "../../assets/404.png";
import enpoweredBlack from "../../assets/Logo_White_Bg.png";

function PageNotFound() {
  return (
    <section className="flex flex-col gap-[48px] items-center mt-[80px]">
      <h1>Oh No!</h1>
      <img src={image404} alt="404" />
      <p className="s24 text-center font-light">
        That page is unplugged or {"doesn't"} exist
      </p>
      <div className="flex flex-col gap-[30px] items-center">
        <p className="text-center">
          Contact our support team if you need assistance <br />
          <a
            className="underlined text-text-link"
            href="mailto:support@enpowered.com"
          >
            support@enpowered.com
          </a>
        </p>
        <img src={enpoweredBlack} width={"140px"} alt="Enpowered Logo" />
      </div>
    </section>
  );
}

export default PageNotFound;

import { authedFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";
const apiRoot = process.env.REACT_APP_API_ROOT;

export const getDealOwnerbyPartnerCompanyId = async partnerCompanyId => {
  return authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "getDealOwnerByPartnerCompanyId",
      params: { partnerCompanyId }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors);
};

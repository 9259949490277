import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { renderDollar, renderPercentage } from "../utils/format";

import arrowSvg from "../assets/White_Arrow.svg";
import "./styles.scss";
import { getAssetLifetime } from "../utils/calculations";

const HighlightedValuesLine = ({ calculations, projectVariables }) => {
  const assetLifeTime = getAssetLifetime(projectVariables.projectType);
  return (
    <div className="highlightedValuesLine">
      <ReadOnlyFieldBlock
        label="Interest Rate"
        value={renderPercentage((projectVariables.interestRate ?? 0) * 100, 2)}
      />
      <ReadOnlyFieldBlock
        label="Utility Escalator"
        value={renderPercentage(
          (projectVariables.utilityCostEscalator ?? 0) * 100,
          2
        )}
      />
      <ReadOnlyFieldBlock
        hidden={projectVariables.OEMCost <= 0}
        label="Annual O&M Cost"
        value={renderDollar(projectVariables.OEMCost)}
      />
      <ReadOnlyFieldBlock
        hidden={projectVariables.projectType !== "Solar"}
        label="System Degradation Rate"
        value={renderPercentage(
          projectVariables.systemDegradationRate * 100,
          2
        )}
      />
      <ReadOnlyFieldBlock
        hidden={calculations.monthsUntilBreakEven <= 1}
        label={`Payback Period`}
        value={`${Math.trunc(calculations.monthsUntilBreakEven / 12)} yr${
          calculations.monthsUntilBreakEven % 12 > 0
            ? ` ${calculations.monthsUntilBreakEven % 12} mo`
            : ""
        }`}
      />
      <ReadOnlyFieldBlock
        hidden={!calculations.ROIOverAssetLifetime}
        label={`ROI of Down Payment (${
          assetLifeTime ? assetLifeTime + " yrs" : "asset lifetime"
        })`}
        value={`${calculations.ROIOverAssetLifetime?.toFixed(2)}%`}
      />
    </div>
  );
};
/**
 *
 * @param {object} params
 * @param {string} params.label
 * @param {string} [params.className]
 * @param {string} params.value
 * @param {boolean} [params.hidden]
 */
const ReadOnlyFieldBlock = ({ className, label, value, hidden }) => {
  return (
    !hidden && (
      <div
        className={"my-4 ml-6 mr-20 inline-block fieldBlock " + className ?? ""}
      >
        <label>{label}</label>
        <div className="value font-bold">{value}</div>
      </div>
    )
  );
};

/**
 *
 * @param {object} params
 * @param {object} params.calculations
 * @param {object} params.projectVariables
 * @param {string} [params.className]
 * @returns {JSX.Element}
 */

export const SuggestedPaymentSchedule = ({
  calculations,
  projectVariables,
  className
}) => {
  const {
    annualPayments,
    annualSavings,
    annualOtherSavings,
    cumulativeCashflow,
    netCashflow
  } = calculations.schedule;
  const n = calculations.termYears + 2;
  const monthsFromLastTermYear = calculations.termMonths % 12;
  const headers = [
    "Year",
    `Annual Savings*`,
    `Annual Payments`,
    "Net Cashflow",
    "Cumulative Savings"
  ];
  const showArrow = n > 7;
  return (
    <div
      className={classNames("suggestedPaymentSchedule py-1 mb-6", className)}
    >
      <div className="title pb-2">Annualized Payment Schedule</div>
      <HighlightedValuesLine
        projectVariables={projectVariables}
        calculations={calculations}
      />
      <table className="annualPaymentTable mx-auto text-md">
        <thead className="text-center">
          <tr>
            {headers.map((header, idx) => (
              <th
                className={classNames({
                  "py-2": monthsFromLastTermYear === 0
                })}
                key={idx}
              >
                {showArrow && idx === 5 && (
                  <img width={25} height={20} src={arrowSvg} alt="" />
                )}
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {netCashflow.map(
            (currentNetCashflow, idx) =>
              idx <
                Math.min(
                  30,
                  Math.max(
                    calculations.termYears + 2,
                    +(calculations.monthsUntilBreakEven / 12).toFixed(0) + 1,
                    getAssetLifetime(projectVariables?.projectType)
                  )
                ) && (
                <tr key={`annualPaymentLine-${idx + 1}`}>
                  <td className="font-semibold">{idx + 1}</td>
                  <td>
                    {renderDollar(
                      annualSavings[idx] + annualOtherSavings[idx],
                      2
                    )}
                  </td>
                  {annualPayments[idx] < 0 && annualPayments[idx + 1] >= 0 ? (
                    <td className="flex justify-beween">
                      <span className="mt-1">
                        {renderDollar(annualPayments[idx], 2)}
                      </span>
                      <div className="greenTag">Payments Concluded</div>
                    </td>
                  ) : (
                    <td className="text-left">
                      {renderDollar(annualPayments[idx], 2)}
                    </td>
                  )}
                  <td
                    className={currentNetCashflow < 0 ? "redCell" : "greenCell"}
                  >
                    {renderDollar(currentNetCashflow, 2)}
                  </td>
                  <td
                    className={
                      cumulativeCashflow[idx] < 0 ? "redCell" : "greenCell"
                    }
                  >
                    {renderDollar(cumulativeCashflow[idx], 2)}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
      <div className="table-disclaimer text-left text-xs italic mx-auto">
        <div className="pt-4 pb-2 s12">
          <span className="font-semibold">Notes:</span> Estimates may change
          based on credit approval and tax liability. They do not represent
          final financing terms.
          <br />* Annual savings are estimated and could fluctuate throughout
          the project duration.
        </div>
      </div>
    </div>
  );
};

SuggestedPaymentSchedule.propTypes = {
  calculations: PropTypes.object,
  className: PropTypes.string
};

export default SuggestedPaymentSchedule;

import "./index.scss";
import "./font.scss";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Header } from "./HeaderFooter";
import Calculator from "./Calculator";
import { ProjectDetails } from "./ProjectDetails";
import { SuggestedPaymentSchedule } from "./SuggestedPaymentSchedule";
import YourOpportunityTo from "./YourOpportunityTo";
import StartNow from "./StartNow";

export * from "./Calculator";
export * from "./ProjectDetails";
export * from "./utils";

/**
 *
 * @param {*} props.className
 * @returns
 */
export const LineSeparator = ({ className }) => (
  <div className={classNames("lineSeparator mb-2 mt-4", className)}></div>
);

export const PageSeparator = ({ className }) => (
  <div className={classNames("pageSeparator", className)}> </div>
);

export const ProposalGen = ({ variables, className, isPreview = false }) => {
  if (!variables) return <></>;
  let calculations = {};
  try {
    calculations = Calculator(variables.project);
  } catch (e) {
    return (
      <div
        className={classNames({
          "proposal-gen tk-source-sans-pro border-solid p-3": true,
          [className]: true,
          pageBorder: isPreview
        })}
      >
        {e?.message ?? ""}
      </div>
    );
  }
  return (
    <div
      className={classNames({
        "proposal-gen tk-source-sans-pro border-solid": true,
        pageBorder: isPreview,
        [className]: true
      })}
    >
      <Header variables={variables} />
      <StartNow variables={variables} />
      <ProjectDetails variables={variables} calculations={calculations} />
      <YourOpportunityTo
        variables={variables}
        calculations={calculations}
        className={"mb-14"}
      />
      {isPreview && <PageSeparator className="mt-8" />}
      <Header variables={variables} />
      <div className="px-8">
        <SuggestedPaymentSchedule
          calculations={calculations}
          projectVariables={variables.project}
        />
      </div>
    </div>
  );
};

ProposalGen.propTypes = {
  variables: PropTypes.object,
  className: PropTypes.string,
  isPreview: PropTypes.bool
};

export default ProposalGen;

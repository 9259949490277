import React from "react";
import "./styles.scss";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { renderDollar } from "utils/stringFunctions";
import classNames from "classnames";

/**
 * @typedef {CCA | BasicIncentive} Incentive
 *
 * @typedef {object} BasicIncentive
 * @property {string} name
 * @property {number} [percentage]
 * @property {number} amount
 * @property {boolean} useInPayments
 *
 * @typedef {object} CCA
 * @property {number} federalTaxRate
 * @property {number} stateTaxRate
 * @property {number} amount
 * @property {number} otherYears
 * @property {boolean} useInPayments
 *
 * @param {object} props
 * @param {Incentive[]} props.incentives
 * @returns {React.ReactElement}
 */
function IncentivesField({ incentives, onClick, disabled }) {
  return (
    <div
      className={classNames("incentivesField", { disabled })}
      onClick={onClick}
    >
      <div className="incentiveList">
        {incentives?.length ? (
          incentives.map((incentive, idx) => (
            <span key={"incentive-" + idx}>
              {incentive.name} -{" "}
              <span className="font-semibold">
                {renderDollar(
                  +incentive.amount + (incentive.otherYears ?? 0),
                  2,
                  true
                )}
              </span>
            </span>
          ))
        ) : (
          <span className="noIncentives">None - $ 0.00</span>
        )}
      </div>
      <div className="flex items-center gap-2 mx-3">
        <EditIcon className="inline-block" />
        Edit
      </div>
    </div>
  );
}

export default IncentivesField;

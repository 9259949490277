import { Calculator } from "@enpowered/payment-proposal-view";
import { authedFetch } from "../utils/fetch";
import { verifyForErrors } from "../utils/handleHttpError";
import { DateTime } from "luxon";
import { getAssetLifetime } from "@enpowered/payment-proposal-view";

const apiRoot = process.env.REACT_APP_API_ROOT;

export const generateProposalPdf = async proposal => {
  return authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "generateProposalPdf",
      params: {
        inputs: proposal
      }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .then(({ downloadUrl }) => fetch(downloadUrl))
    .then(res => res.blob())
    .then(fileBlob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(fileBlob);
      link.download = `${
        proposal.project.projectName ? `${proposal.project.projectName}-` : ""
      }${DateTime.local().toISO()}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
export const uploadFile = (url, file) =>
  fetch(url, {
    method: "PUT",
    body: file
  });

/**
 *
 * @param {object} props
 * @param {string} props.partnerCompanyName,
 * @param {string} props.partnerLogoFilename
 * @param {string} props.dealOwnerId
 * @param {File} props.file
 */
export const AddPartnerCompanyWithDealOwner = async ({
  partnerCompanyName,
  partnerLogoFilename,
  file,
  dealOwnerId
}) => {
  return authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "addPartnerCompany",
      params: { partnerCompanyName, partnerLogoFilename }
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .then(({ partnerCompanyId, partnerLogoUploadUrl }) =>
      Promise.all([
        authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            method: "addDealOwner",
            params: { dealOwnerId, partnerCompanyId }
          })
        }),
        uploadFile(partnerLogoUploadUrl, file)
      ]).then(
        ([dealOwnerRes, partnerLogoUploadResponse]) =>
          console.log({ dealOwnerRes, partnerLogoUploadResponse }) ||
          dealOwnerRes
            .json()
            .then(body => body?.data)
            .then(verifyForErrors)
            .then(addDealOwnerResponse => ({
              partnerCompanyId,
              addDealOwnerResponse,
              partnerLogoUploadResponse
            }))
      )
    )
    .catch(error => {
      throw error;
    });
};

export const transformProgressPayments = progressPayments =>
  Object.entries(progressPayments).reduce(
    (ac, [month, percentage]) =>
      month === "final"
        ? ac
        : [
            ...ac,
            {
              month: parseInt(month),
              percentage
            }
          ],
    []
  );

export const generateProposalCsv = async (
  proposal,
  proposalNumbers,
  mimeType = "text/csv"
) => {
  const calculatedNumbers = Calculator(proposalNumbers);
  const element = document.createElement("a");
  const csvContent = generateCsvContent(proposal, calculatedNumbers);
  element.setAttribute(
    "href",
    `data:${mimeType};charset=utf-8,` + encodeURIComponent(csvContent)
  );
  element.setAttribute(
    "download",
    `${
      proposal.projectName ? `${proposal.projectName}-` : ""
    }${DateTime.local().toISO()}.csv`
  );

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const generateCsvContent = (proposal, calculatedNumbers) => {
  const { projectCost, incentives, downPayment, projectType } = proposal;
  const {
    schedule,
    monthlySchedule,
    totalDepreciationSavings,
    monthsUntilBreakEven,
    ROIOverAssetLifetime,
    constructionPeriodInterest
  } = calculatedNumbers;
  const assetLifeTime = getAssetLifetime(projectType);
  return `"Financial Proposal"

"Project Cost","Project Term"${
    incentives?.length ? `,"Project Incentives"` : ""
  }${
    calculatedNumbers.cashFlowAmount > 0 ? `,"Total Savings during Term"` : ""
  }${calculatedNumbers.monthsUntilBreakEven > 1 ? `,Payback Period` : ""}${
    totalDepreciationSavings > 0 ? `,"Commercial Depreciation Savings"` : ""
  }${
    ROIOverAssetLifetime
      ? `,"ROI of Down Payment (${
          assetLifeTime ? assetLifeTime + " yrs" : "asset life time"
        })"`
      : ""
  }
"${projectCost}","${calculatedNumbers.termMonths}"${
    incentives?.length
      ? `,"${incentives
          .reduce((ac, i) => ac + i.amount + (i.otherYears ?? 0), 0)
          .toFixed(2)}"`
      : ""
  }${
    calculatedNumbers.cashFlowAmount > 0
      ? `,"${calculatedNumbers.cashFlowAmount.toFixed(2)}"`
      : ""
  }${
    calculatedNumbers.monthsUntilBreakEven > 1
      ? `,"${Math.trunc(monthsUntilBreakEven / 12)} yr${
          monthsUntilBreakEven % 12 > 0
            ? ` ${monthsUntilBreakEven % 12} mo"`
            : ""
        }`
      : ""
  }${
    totalDepreciationSavings > 0
      ? `,"${totalDepreciationSavings.toFixed(2)}"`
      : ""
  }${ROIOverAssetLifetime ? `,"${ROIOverAssetLifetime.toFixed(2)}%"` : ""}

"First Year Financial Estimates"

"Monthly Payments","Monthly Savings"${
    calculatedNumbers.monthlyNetCosts > 0 ? `,"Net Savings"` : ""
  }${downPayment.length ? `,"Down Payment"` : ""}${
    constructionPeriodInterest > 0 ? `,"Accrued Int. during Construction"` : ""
  }
"${calculatedNumbers.monthlyPayment.toFixed(
    2
  )}","${calculatedNumbers.monthlySavings.toFixed(2)}"${
    calculatedNumbers.monthlyNetCosts > 0
      ? `,"${calculatedNumbers.monthlyNetCosts.toFixed(2)}"`
      : ""
  }${downPayment.length ? `,"${(+downPayment).toFixed(2)}"` : ""}${
    constructionPeriodInterest > 0
      ? `,"${constructionPeriodInterest.toFixed(2)}"`
      : ""
  }

"Annualized Payment Schedule"

"Year","Annual Savings","Annual Payments","Net Savings","Cumulative Savings"
${schedule?.netCashflow
  .map((netCashflowYear, idx) => {
    let row = "";
    schedule?.annualPayments[idx] >= 0 &&
    !(schedule?.annualPayments[idx - 1] >= 0)
      ? (row += `"${idx} Payments Concluded",`)
      : (row += `"${idx}",`);
    row += `"${(
      schedule?.annualSavings[idx] + schedule?.annualOtherSavings[idx]
    ).toFixed(2)}",`;
    row += `"${schedule?.annualPayments[idx].toFixed(2)}",`;
    row += `"${netCashflowYear.toFixed(2)}",`;
    row += `"${schedule?.cumulativeCashflow[idx].toFixed(2)}"`;
    return row;
  })
  .join("\n")}

"Monthly Payment Schedule"

"Month","Opening Balance","Payment Made","Interest Owed","Principal Paid","Additional Lump Sum Payments","Closing Balance","Savings","Net Savings","Cumulative Savings"
${monthlySchedule
  .map(
    ({
      month,
      currentBalance,
      monthlyPayments,
      interestPaidMonthly,
      principalPaid,
      additionalPayment,
      remainingBalance,
      cumulativeSavings,
      savings,
      netSavings
    }) => {
      let row = "";

      row += `"${month}",`;
      row += `"${currentBalance?.toFixed(2)}",`;
      row += `"${monthlyPayments?.toFixed(2)}",`;
      row += `"${interestPaidMonthly?.toFixed(2)}",`;
      row += `"${principalPaid?.toFixed(2)}",`;
      row += `"${additionalPayment?.toFixed(2)}",`;
      row += `"${remainingBalance?.toFixed(2)}",`;
      row += `"${savings.toFixed(2)}",`;
      row += `"${netSavings.toFixed(2)}",`;
      row += `"${cumulativeSavings.toFixed(2)}",`;

      return row;
    }
  )
  .join("\n")}
`;
};

export const exportProjectToHubspot = proposal =>
  authedFetch(`${apiRoot}/payment-proposal/jsonrpc-secured`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      method: "exportProjectToHubspot",
      params: proposal
    })
  })
    .then(res => res.json())
    .then(body => body?.data)
    .then(verifyForErrors)
    .then(data => console.log(`hs export completed`, data));
